import React from 'react';
import './Feedback.css';
import FeedbackRightBar from '../RightBars/FeedbackRightBar/FeedbackRightBar';
import SideBar from '../../SideBar';
function Feedback(props) {
    return (
        <div className='mainFeedbackContainer'>
            <SideBar />
            <FeedbackRightBar />
        </div>
    )
}
export default Feedback;