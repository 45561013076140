import axios from "axios";
import { BaseURL } from "../constants/BaseURL";
import {
  CLEAR_ERRORS,
  EMPLOYEE_FAIL,
  EMPLOYEE_REQUEST,
  EMPLOYEE_SUCCESS,
} from "../constants/EmployeeConstants";

export const GetEmployeesAction = () => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_REQUEST });
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${BaseURL}/employee/`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });
    if (data && data.data) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: {
          employeeData: data.data,
        },
      });
    } else {
      throw new Error("Invalid data structure from API");
    }
    // dispatch({
    //   type: EMPLOYEE_SUCCESS,
    //   payload: {
    //     employeeData: data?.data, // Make sure to extract the data field
    //   },
    // });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FAIL,
      payload: error.response.data || "Something went wrong",
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
