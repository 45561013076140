import axios from "axios";
import { BaseURL } from "../constants/BaseURL";
import {
  CLEAR_ERRORS,
  LEAVE_FAIL,
  LEAVE_REQUEST,
  LEAVE_SUCCESS,
} from "../constants/LeaveRequestConstants";

export const GetLeaveRequestAction = () => async (dispatch) => {
  try {
    dispatch({ type: LEAVE_REQUEST });
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };

    const response = await axios.get(`${BaseURL}/manager/leave-request`, config);
    const responseData = response.data;

    dispatch({
      type: LEAVE_SUCCESS,
      payload: responseData.data,
    });
  } catch (error) {
    dispatch({
      type: LEAVE_FAIL,
      payload: error.response.data,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
