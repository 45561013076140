// reducer.js

import { FETCH_USER_PROFILE_FAILURE, FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS } from "../constants/userProfileConstants";

  
  const initialState = {
    user: null,
    loading: false,
    error: null,
  };
  
  const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_PROFILE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_USER_PROFILE_SUCCESS:
        return {
          ...state,
          user: action.payload.user,
        //   id: action.payload.id,
          loading: false,
          error: null,
        };
      case FETCH_USER_PROFILE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userProfileReducer;
  