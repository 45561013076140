// invoiceActions.js

import axios from 'axios';
import { BaseURL } from '../../constants/BaseURL';

// Action Types
export const GENERATE_INVOICE_REQUEST = 'GENERATE_INVOICE_REQUEST';
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_FAILURE = 'GENERATE_INVOICE_FAILURE';

// Action Creators
export const generateInvoiceRequest = () => ({
  type: GENERATE_INVOICE_REQUEST,
});

export const generateInvoiceSuccess = (invoice) => ({
  type: GENERATE_INVOICE_SUCCESS,
  payload: { invoice },
});

export const generateInvoiceFailure = (error) => ({
  type: GENERATE_INVOICE_FAILURE,
  payload: { error },
});

// Async Action Creator
export const generateInvoice = (employeeId) => async (dispatch) => {
  dispatch(generateInvoiceRequest());

  try {
    const token = localStorage.getItem('token')
    const response = await axios.post(
      `${BaseURL}/manager/generate-invoice/${employeeId}`,
      {
        // additional request data if needed
      },
      {
        headers: {
          'x-auth-token': token, // replace with your actual auth token
        },
      }
    );

    dispatch(generateInvoiceSuccess(response.data.invoice));
  } catch (error) {
    dispatch(generateInvoiceFailure(error.msg));
  }
};
