import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile } from '../../../../../redux/actions/getUserProfileAction';
import { socket } from '../../../../../socket';
import ChatRightBar from '../../RightBars/ChatBars/ChatRightBar';
// import Input from '../../RightBars/ChatBars/Input';

export function ConnectionManager({ isChatOpen }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfile.user);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    function connect() {
      socket.connect();
      socket.on('connect', () => {
        if (user && user._id) {
          socket.emit('new-user-add', user._id);
          console.log(`${user._id} is connected to the socket`);
        }
      });
    }

    function disconnect() {
      socket.disconnect();
      console.log('Disconnected from socket');
    }

    if (isChatOpen) {
      connect();
    } else {
      disconnect();
    }

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, [isChatOpen, user]);

  return (
    <div>
      {/* <p>{connectionMessage}</p> */}
      <ChatRightBar/>
      {/* <Input/> */}
    </div>
  );
}
