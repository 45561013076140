import React, { useState } from "react";
import "./SideBar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { MdEventAvailable } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { MdChat } from "react-icons/md";
import { GiOrganigram } from "react-icons/gi";
import { MdReport } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdReviews } from "react-icons/md";
import { BiSolidLogOutCircle } from "react-icons/bi";
import { IoMdRefreshCircle } from "react-icons/io";

import divineLogo from "../../assets/divine-logo.png";

import LeaveRequest from "../../assets/Icons/LeaveRequest.png";

function SideBar(props) {
  const { onToggleChat } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/LoginPage");
  };

  const role = localStorage.getItem("role");

  const [activeItem, setActiveItem] = useState("");
  // Update active item based on current pathname
  React.useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showContent, setShowContent] = useState(true);

  return (
    <div
      className={`mainSideBarContainer ${isSmallScreen ? "smallScreen" : ""}`}
    >
      <img src={divineLogo} alt="divineLogo" className="Sidebarlogo" />
      <span>MENU</span>
      <ul className="ulContainer">
        <li className="liContainer">
          <NavLink to={"/home"}>
            <MdDashboard className="SideBarIcon" />
            <p className={activeItem === "/home" ? "activeLink" : ""}>
              Dashboard
            </p>
          </NavLink>
        </li>

        {role && role === "Owner" && (
          <li className="liContainer">
            <NavLink to={"/Manager"}>
              <GrUserManager className="SideBarIcon" />
              <p className={activeItem === "/Manager" ? "activeLink" : ""}>
                Manager
              </p>
            </NavLink>
          </li>
        )}

        <li className="liContainer">
          <NavLink to={"/Employee"}>
            <FaPeopleGroup className="SideBarIcon" />
            <p className={activeItem === "/Employee" ? "activeLink" : ""}>
              Employee
            </p>
          </NavLink>
        </li>

        <li className="liContainer">
          <NavLink to={"/Client"}>
            <FaUserTie className="SideBarIcon" />
            <p className={activeItem === "/Client" ? "activeLink" : ""}>
              Client
            </p>
          </NavLink>
        </li>

        <li className="liContainer">
          <NavLink to={"/Job"}>
            <MdWork className="SideBarIcon" />
            <p
              className={activeItem === "/Job" ? "activeLink" : ""}
              // onClick={() => HandleItemActive("job")}
            >
              Job
            </p>
          </NavLink>
        </li>

        {role && role === "Manager" && (
          <li className="liContainer">
            <NavLink to={"/Availability"}>
              <MdEventAvailable className="SideBarIcon" />
              <p className={activeItem === "/Availability" ? "activeLink" : ""}>
                Availability
              </p>
            </NavLink>
          </li>
        )}

        <li className="liContainer">
          <NavLink to={"/Chat"} onClick={onToggleChat}>
            <MdChat className="SideBarIcon" />
            <p className={activeItem === "/Chat" ? "activeLink" : ""}>Chat</p>
          </NavLink>
        </li>

        {role && role === "Manager" && (
          <li className="liContainer">
            <NavLink to={"/LeaveRequest"}>
              <IoMdRefreshCircle className="SideBarIcon" />
              <p className="textItem">Leave Request</p>
            </NavLink>
          </li>
        )}
        <li className="liContainer">
          <NavLink to={"/Roster"}>
            <GiOrganigram className="SideBarIcon" />
            <p className={activeItem === "/Roster" ? "activeLink" : ""}>
              Roster
            </p>
          </NavLink>
        </li>
        <li className="liContainer">
          <NavLink to={"/IncidentReport"}>
            <MdReport className="SideBarIcon" />
            <p className={activeItem === "/IncidentReport" ? "activeLink" : ""}>
              Incident Report
            </p>
          </NavLink>
        </li>
        <li className="liContainer">
          <NavLink to={"/Invoice"}>
            <FaFileInvoiceDollar className="SideBarIcon" />
            <p className={activeItem === "/Invoice" ? "activeLink" : ""}>
              Payslips
            </p>
          </NavLink>
        </li>
        <li className="liContainer">
          <NavLink to={"/Feedback"}>
            <MdReviews className="SideBarIcon" />
            <p className={activeItem === "/Feedback" ? "activeLink" : ""}>
              Feedback
            </p>
          </NavLink>
        </li>

        <li className="liContainer">
          <NavLink
            className="linkStyle"
            onClick={handleLogout}
            to={"/LoginPage"}
          >
            <BiSolidLogOutCircle className="SideBarIcon" />
            <p className="textItem">Logout</p>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SideBar;
