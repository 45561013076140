import axios from 'axios';
import { BaseURL } from '../constants/BaseURL';

// Action Types
export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const UPDATE_INVOICE_STATUS_REQUEST = 'UPDATE_INVOICE_STATUS_REQUEST';
export const UPDATE_INVOICE_STATUS_SUCCESS = 'UPDATE_INVOICE_STATUS_SUCCESS';
export const UPDATE_INVOICE_STATUS_FAILURE = 'UPDATE_INVOICE_STATUS_FAILURE';

// Action Creators
export const fetchInvoicesRequest = () => ({
  type: FETCH_INVOICES_REQUEST,
});

export const fetchInvoicesSuccess = (invoices) => ({
  type: FETCH_INVOICES_SUCCESS,
  payload: { invoices },
});

export const fetchInvoicesFailure = (error) => ({
  type: FETCH_INVOICES_FAILURE,
  payload: { error },
});

// Async Action Creator for fetching invoices
export const fetchInvoices = () => async (dispatch) => {
  dispatch(fetchInvoicesRequest());

  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${BaseURL}/manager/invoice`, {
      headers: {
        'x-auth-token': token,
      },
    });

    dispatch(fetchInvoicesSuccess(response.data.invoices));
  } catch (error) {
    dispatch(fetchInvoicesFailure(error.message));
  }
};

// Action Creators for updating invoice status
export const updateInvoiceStatusRequest = () => ({
  type: UPDATE_INVOICE_STATUS_REQUEST,
});

export const updateInvoiceStatusSuccess = (updatedInvoice) => ({
  type: UPDATE_INVOICE_STATUS_SUCCESS,
  payload: { updatedInvoice },
});

export const updateInvoiceStatusFailure = (error) => ({
  type: UPDATE_INVOICE_STATUS_FAILURE,
  payload: { error },
});

export const updateInvoiceStatus = (invoiceId, newStatus) => {
  return dispatch => {
    dispatch({ type: UPDATE_INVOICE_STATUS_REQUEST });

    // Include the token in the request headers
    const token = localStorage.getItem('token');
    axios.put(`${BaseURL}/manager/invoices/${invoiceId}`, { action: newStatus }, {
      headers: {
        'x-auth-token': token,
      },
    })
      .then(response => {
        dispatch({
          type: UPDATE_INVOICE_STATUS_SUCCESS,
          payload: response.data  // Assuming the response contains updated invoice data
        });
        
      })
      .catch(error => {
        dispatch({
          type: UPDATE_INVOICE_STATUS_FAILURE,
          payload: error.message  // Pass error message for failure
        });
      });
  };
};