import axios from "axios";
import {
  FETCH_COMPANY_DETAILS,
  FETCH_COMPANY_DETAILS_FAILURE,
  FETCH_COMPANY_DETAILS_SUCCESS,
} from "../constants/companyConstants";
import { BaseURL } from "../constants/BaseURL";

export const fetchCompanyDetails = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_COMPANY_DETAILS });
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${BaseURL}/compnay/overall-length`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    dispatch({
      type: FETCH_COMPANY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // Handle the error, for example, dispatch an error action
    dispatch({
      type: FETCH_COMPANY_DETAILS_FAILURE,
      payload: error.message, // You can customize the payload with the error details
    });
  }
};
