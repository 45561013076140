import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Nodata from "../../../../../assets/Nodata.png";

function formatTime(timestamp) {
  const options = { hour: "numeric", minute: "numeric" };
  return new Date(timestamp).toLocaleTimeString([], options);
}

function Messages({ messages: propMessages }) {
  const serverMessages = useSelector((state) => state.messages.messages) || [];
  const user = useSelector((state) => state.userProfile.user);

  const userId = user ? user._id : null;

  const allMessages = [
    ...(Array.isArray(serverMessages) ? serverMessages : []),
    ...(Array.isArray(propMessages) ? propMessages : []),
  ];

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [allMessages]);
  console.log("🚀 ~ Messages ~ allMessages:", allMessages);

  return (
    <div
      className="messages"
      ref={messagesContainerRef}
      style={{ overflowY: "auto", maxHeight: "520px" }}
    >
      {allMessages.length > 0 ? (
        allMessages.map((message) => (
          <div
            key={message.id || message.timestamp}
            className={`message-container ${
              message.senderId === userId
                ? "messageItem-right"
                : "messageItem-left"
            }`}
          >
            <p>{message.content}</p>
            <span style={{ color: "gray" }}>
              {formatTime(message.createdAt)}
            </span>
          </div>
        ))
      ) : (
        <div className="NodataAvaible">
          <img className="NodataAvaible-img" src={Nodata} />
          No messages data available
        </div>
      )}
    </div>
  );
}

export default Messages;
