import { ACCEPT_LEAVE_FAIL, ACCEPT_LEAVE_REQUEST, ACCEPT_LEAVE_SUCCESS, CLEAR_ERRORS, DELETE_LEAVE_SUCCESS, LEAVE_FAIL, LEAVE_REQUEST, LEAVE_SUCCESS } from "../constants/LeaveRequestConstants";

const initialState = {
  loading: false,
  leaveRequests: [], // Rename the property to leaveRequests
  errors: null,
};

export const leaveRequestReducer = (state = initialState, action) => { // Remove the extra object around initialState
  switch (action.type) {
    case LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveRequests: action.payload, // Update the property to leaveRequests
      };
    case LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
      case DELETE_LEAVE_SUCCESS: // Handle the delete action
      // Filter out the deleted company from the state
      const updatedRequest = state.leaveRequests.filter(
          (request) => request._id !== action.payload.deletedRequestId
      );
      return {
          ...state,
          loading: false,
          leaveRequests: updatedRequest,
      };
      case ACCEPT_LEAVE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ACCEPT_LEAVE_SUCCESS:
        // Filter out the accepted request from the state
        const updatedLeaveRequestsAfterAccept = state.leaveRequests.filter(
          (request) => request._id !== action.payload.acceptedRequestId
        );
        return {
          ...state,
          loading: false,
          leaveRequests: updatedLeaveRequestsAfterAccept,
        };
      case ACCEPT_LEAVE_FAIL:
        return {
          ...state,
          loading: false,
          errors: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          loading: false,
          errors: null,
        };
      default:
        return state;
    }
  };