export const MANAGER_REQUEST = "MANAGER_REQUEST";
export const MANAGER_SUCCESS = "MANAGER_SUCCESS";
export const MANAGER_FAIL = "MANAGER_FAIL";

export const ADD_MANAGER_SUCCESS = "ADD_MANAGER_SUCCESS"
export const ADD_MANAGER_ERROR = "ADD_MANAGER_ERROR"

export const DELETE_MANAGER_REQUEST = "DELETE_MANAGER_REQUEST"
export const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS"
export const DELETE_MANAGER_FAIL = "DELETE_MANAGER_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS";






