import React, { useEffect, useState } from "react";
import "./HomeRightBar.css";
import NavBar from "../../NavBar";
import addnewIcon from "../../../../assets/Icons/addnewIcon.png";
// import doneIcon from "../../../../assets/Icons/doneIcon.png";

import { connect, useDispatch, useSelector } from "react-redux";
// import createJobReducer from '../../../../redux/reducers/createJobReducers';
// import { createJob } from '../../../../redux/actions/createJobAcrion';
import { createJob } from "../../../../redux/actions/createActions/createJobAcrion";
import { fetchCompanyDetails } from "../../../../redux/actions/companyAction";
import {
  fet,
  fetchManagerDetails,
} from "../../../../redux/actions/managerDetailAction";
import { GetEmployeesAction } from "../../../../redux/actions/GetEmployeesAction";
import { Link } from "react-router-dom";
// import { GetClientsAction } from "../../../../redux/actions/clientActions";
import { toast } from "react-toastify";
import { GetManagerAction } from "../../../../redux/actions/GetManagerAction";
import { GetClientsInfoAction } from "../../../../redux/actions/clientInfoAction";
import { useNavigate } from "react-router";

function TimePicker({ setStartTime, setEndTime }) {
  return (
    <div>
      <label>Start Time:&nbsp;&nbsp;</label>
      <input
        type="time"
        name="startTime"
        onChange={(e) => setStartTime(e.target.value)}
      />
      <br />
      <br />
      <label>End Time:&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <input
        type="time"
        name="endTime"
        onChange={(e) => setEndTime(e.target.value)}
      />
      <br />
      <br />
    </div>
  );
}

function HomeRightBar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");

  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleString(undefined, {
    month: "long",
  })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

  const formattedTime = `${currentDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  })}`;

  const clients = useSelector((state) => state.client.clients);
  const loading = useSelector((state) => state.client.loading);
  console.log("🚀 ~ HomeRightBar ~ clients:", clients);
  const employees = useSelector((state) => state.employee.employees);
  console.log("🚀 ~ HomeRightBar ~ employe:", employees);

  useEffect(() => {
    dispatch(GetClientsInfoAction());
  }, [dispatch]);

  const [options, setOptions] = useState([""]);
  const [newOptionText, setNewOptionText] = useState("");
  const [isAssign, setIsAssign] = useState(false);
  const onSelect = (event) => {};
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const handleEmployeeSelection = (e) => {
    setSelectedEmployee(e.target.value);
    setJobFormData((prevData) => ({
      ...prevData,
      employeeId: e.target.value,
    }));
  };
  const [selectedClient, setSelectedClient] = useState("");
  const handleClientSelection = (e) => {
    setSelectedClient(e.target.value);
    setJobFormData((prevData) => ({
      ...prevData,
      clientID: e.target.value,
    }));
  };

  const [jobFormData, setJobFormData] = useState({
    title: "",
    clientID: "",
    employeeId: "",
    description: "",
    allowances: "",
    task: [""],
    date: "",
    startTime: "",
    endTime: "",
    shift: "",
    address: "",
  });

  const addNewOption = () => {
    if (newOptionText) {
      setOptions([...options, newOptionText]);
      setNewOptionText("");
    }
  };
  const [CreateJobPopup, setCreateJobPopup] = useState(false);
  const [donePopup, setDonePopup] = useState(false);
  const handleCreateJobClick = () => {
    setCreateJobPopup(true);
  };
  const closeCreateJobPopup = () => {
    setCreateJobPopup(false);
  };
  const closeDonePopup = () => {
    setDonePopup(false);
  };

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let errors = {};
    // Validate title
    if (!jobFormData.title || jobFormData.title.length === 0) {
      errors.title = "Title is required";
    } else {
      errors.title = "";
    }
    if (!jobFormData.address || jobFormData.address.length === 0) {
      errors.address = "Address is required";
    } else {
      errors.address = "";
    }
    if (!jobFormData.clientID || jobFormData.clientID.length === 0) {
      errors.clientID = "clientId is required";
    } else {
      errors.clientID = "";
    }
    if (!jobFormData.allowances || jobFormData.allowances.length === 0) {
      errors.allowances = "Allowances is required";
    } else {
      errors.allowances = "";
    }
    if (!jobFormData.description || jobFormData.description.length === 0) {
      errors.description = "Description is required";
    } else {
      errors.description = "";
    }
    if (!jobFormData.task || jobFormData.task.length === 0) {
      errors.task = "Task is required";
    } else {
      errors.task = "";
    }
    if (!jobFormData.date || jobFormData.date.length === 0) {
      errors.date = "Date is required";
    } else {
      errors.date = "";
    }
    if (!jobFormData.startTime || !jobFormData.endTime) {
      errors.startTime = "Start Time and End Time are required";
    } else if (
      new Date(jobFormData.endTime) <= new Date(jobFormData.startTime)
    ) {
      errors.startTime = "Start Time should be before End Time";
    } else {
      errors.startTime = "";
    }
    if (!jobFormData.shift || jobFormData.shift.length === 0) {
      errors.shift = "Shift is required";
    } else {
      errors.shift = "";
    }

    if (
      Object.values(jobFormData).some(
        (value) => !value || (Array.isArray(value) && value.length === 0)
      )
    ) {
      errors.emptyFields = "All fields are required";
    } else {
      errors.emptyFields = "";
    }
    setErrors(errors);

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error.length > 0);
    return !hasErrors;
  };
  const handleRegisterClick = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      console.log(jobFormData);
      return;
    } else {
      closeCreateJobPopup();
    }

    const allowancesValue = parseFloat(jobFormData.allowances);

    // Convert task array elements to an array of objects
    const tasksArray = jobFormData.task.map((task) => ({ title: task }));
    // Prepare the job data in the required format
    const jobData = {
      clientID: jobFormData.clientID,
      employeeId: jobFormData.employeeId,
      title: jobFormData.title,
      description: jobFormData.description,
      allowances: allowancesValue,
      task: tasksArray,
      date: selectedDate,
      startTime: selectedDate,
      endTime: selectedDate,
      shift: jobFormData.shift,
      address: jobFormData.address,
    };

    // Log the generated jobData for verification (optional)
    console.log("Generated jobData:", jobData);
    await dispatch(createJob(jobData, navigate));
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "assignTask") {
      if (value === "Now") {
        setIsAssign(true);
      } else {
        setIsAssign(false);
      }
    }

    if (name === "date") {
      // Handle date input separately and format it to the required format
      const formattedDate = new Date(value);
      const year = formattedDate.getFullYear();
      const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
      const day = formattedDate.getDate().toString().padStart(2, "0");
      const formattedDateString = `${year}-${month}-${day}T00:00:00Z`;

      setJobFormData((prevData) => ({
        ...prevData,
        date: formattedDateString,
      }));
    } else if (name === "startTime" || name === "endTime") {
      // Handle start time and end time separately
      setJobFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // For other inputs, handle date, start time, and end time
      setJobFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const addNewTask = (e) => {
    e.preventDefault();
    setJobFormData((prevData) => ({
      ...prevData,
      task: [...(prevData.task || []), ""], // Ensure task is an array
    }));
  };

  // Handle changing the task field
  const handleTaskChange = (e, index) => {
    const { value } = e.target;
    const updatedTasks = [...jobFormData.task];
    updatedTasks[index] = value;
    setJobFormData({
      ...jobFormData,
      task: updatedTasks,
    });
  };

  const employe = useSelector((state) => state.managerDetail.ManagerDetails);

  //

  // Map the names of employees
  useEffect(() => {
    dispatch(GetEmployeesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetManagerAction());
  }, [dispatch]);

  // const dispatch = useDispatch();
  const { loading: companyLoading, companyDetails } = useSelector(
    (state) => state.companyDetail
  );

  useEffect(() => {
    dispatch(fetchCompanyDetails());
  }, [dispatch]);

  const mangerDetail = useSelector((state) => state.managerDetail);
  // console.log("🚀 ~ HomeRightBar ~ mangerDetail:", mangerDetail);
  useEffect(() => {
    dispatch(fetchManagerDetails());
  }, [dispatch]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    setJobFormData((prev) => ({ ...prev, date: newDate }));
  };

  return (
    <div className="mainHomeRightBarContainer">
      <NavBar />
      <div>
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">
              Dashboard
              {/* {CreateJobPopup === false ? 'false' : 'true'} */}
            </h1>
          </div>
          <div>
            {role && role === "Manager" && (
              <button className="createButton" onClick={handleCreateJobClick}>
                <img src={addnewIcon} alt="Add New" className="addIcon" />
                Create Job
              </button>
            )}
            <div>
              {CreateJobPopup && (
                <div className="clientmain">
                  <div className="popupClientForm ">
                    <div className="popup-header">
                      <h2>Create Job</h2>
                      <h2
                        onClick={closeCreateJobPopup}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "red",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        X
                      </h2>
                    </div>
                    <form id="clientForm" className="mainForm">
                      <h3>Personal</h3>
                      <br />
                      <section className="clientFormContainer">
                        <div className="clientCol-1">
                          <label>
                            Job Title
                            <br />
                            <input
                              placeholder="Enter Your Job"
                              type="text"
                              className="clientTextBox"
                              name="title"
                              value={jobFormData.title}
                              onChange={handleFormChange}
                              required
                            />
                            {errors.title && (
                              <span className="error-message">
                                {errors.title}
                              </span>
                            )}
                          </label>
                          <br />
                          <br />
                          <label>
                            Address <br />
                            <input
                              placeholder="Enter Your Address"
                              type="text"
                              className="clientTextBox"
                              name="address"
                              value={jobFormData.address}
                              onChange={handleFormChange}
                              required
                            />
                            {errors.address && (
                              <span className="error-message">
                                {errors.address}
                              </span>
                            )}
                          </label>
                          <br />
                          <br />
                          <label>
                            Date <br />
                            <input
                              type="date"
                              className="clientTextBox"
                              name="formatedDate"
                              value={selectedDate}
                              onChange={handleDateChange}
                              required
                            />
                            {/* {errors.title && <span className="error-message">{errors.title}</span>} */}
                          </label>
                          <br />
                          <br />
                          <TimePicker
                            setStartTime={(startTime) =>
                              setJobFormData((prevData) => ({
                                ...prevData,
                                startTime: Date.now,
                              }))
                            }
                            setEndTime={(endTime) =>
                              setJobFormData((prevData) => ({
                                ...prevData,
                                endTime: Date.now,
                              }))
                            }
                          />
                          <label>
                            {" "}
                            Select shift <br />
                            <input
                              type="radio"
                              name="shift"
                              value="day"
                              checked={jobFormData.shift === "day"}
                              onChange={handleFormChange}
                              required
                            />{" "}
                            Day
                          </label>{" "}
                          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;
                          <label>
                            <input
                              type="radio"
                              name="shift"
                              value="night"
                              checked={jobFormData.shift === "night"}
                              onChange={handleFormChange}
                              required
                            />{" "}
                            Night
                          </label>
                          <br />
                          <br />
                          <label>
                            Any Allowance (Optional) <br />
                            <input
                              placeholder="$"
                              type="number"
                              className="clientTextBox"
                              name="allowances"
                              value={jobFormData.allowances}
                              onChange={handleFormChange}
                            />
                          </label>
                          <br />
                          <br />
                          <label>
                            Select Client : <br />
                            {loading ? (
                              <p>Loading clients...</p>
                            ) : (
                              <select
                                className="clientDropDown"
                                name="selectedClient"
                                value={jobFormData.selectedClient}
                                onChange={handleClientSelection}
                                required
                              >
                                <option value="">Select client</option>
                                {clients && clients.length > 0 ? (
                                  clients.map((client, index) => (
                                    <option key={index} value={client._id}>
                                      {client.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">No clients found</option>
                                )}
                              </select>
                            )}
                          </label>
                        </div>
                        <div className="clientCol-2">
                          <label for="description">
                            Job Description <br />
                            <textarea
                              placeholder="Enter Your Description"
                              className="textarea1"
                              name="description"
                              value={jobFormData.description}
                              onChange={handleFormChange}
                              required
                            >
                              {errors.description && (
                                <span className="error-message">
                                  {errors.description}
                                </span>
                              )}
                            </textarea>
                          </label>
                          <br />
                          <br />
                          <label>
                            Task
                            <div className="addtask">
                              {jobFormData.task.map((task, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  className="addTextBox"
                                  name="task"
                                  value={task}
                                  onChange={(e) => handleTaskChange(e, index)}
                                  placeholder="Enter a new task"
                                  required
                                />
                              ))}
                              {errors.task && (
                                <span className="error-message">
                                  {errors.task}
                                </span>
                              )}
                              <button
                                className="addTaskbtn"
                                onClick={addNewTask}
                              >
                                Add
                              </button>
                            </div>
                          </label>
                          <br />
                          {/* <label>
                            Assign Task : <br />
                            <select
                              className="clientDropDown"
                              name="assignTask"
                              value={jobFormData.task}
                              onChange={handleFormChange}
                              required
                            >
                              {errors.task && (
                                <span className="error-message">
                                  {errors.task}
                                </span>
                              )}
                              <option>select</option>
                              <option value="Now">Now</option>
                              <option value="Later">Later</option>
                            </select>
                          </label>
                          <br /> */}
                          <br />
                          {/* {isAssign && ( */}
                          <div>
                            <label>
                              Select Employee:
                              <select
                                className="clientDropDown"
                                name="selectedEmployee"
                                value={jobFormData.selectedEmployee}
                                onChange={(e) =>
                                  handleEmployeeSelection(
                                    e,
                                    jobFormData.selectedEmployee
                                  )
                                }
                                required
                              >
                                <option value="">Select employee</option>
                                {employees && employees.length > 0 ? (
                                  employees.map((employee, index) => (
                                    <option key={index} value={employee._id}>
                                      {employee.name}
                                    </option>
                                  ))
                                ) : (
                                  <p>No employees found</p>
                                )}
                              </select>
                            </label>
                          </div>
                          {/* // )} */}
                          <div>
                            {errors.emptyFields && (
                              <span className="error-message">
                                {errors.emptyFields}
                              </span>
                            )}
                            <button
                              type="submit"
                              className="managerRegister-btn"
                              onClick={handleRegisterClick}
                            >
                              Register
                            </button>
                          </div>
                        </div>
                      </section>
                      <br />
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="homeItemContainer1">
          <div className="row1">
            {role && role === "Manager" && (
              <>
                <div className="ItemContainer1">
                  <Link to={"/Job"}>
                    <p className="managerCounter">
                      {/* {companyDetails?.job} || {mangerDetail?.job}  */}
                      {mangerDetail.ManagerDetails?.job || 0}
                    </p>
                    <p className="dashboardInfo">Total Jobs</p>
                  </Link>
                </div>
                <div className="ItemContainer1">
                  <Link to={"/Employee"}>
                    <p className="managerCounter">
                      {mangerDetail.ManagerDetails?.employee || 0}
                    </p>
                    <p className="dashboardInfo">Total Employees</p>
                  </Link>
                </div>
                <div className="ItemContainer1">
                  <Link to={"/Client"}>
                    <p className="managerCounter">
                      {mangerDetail.ManagerDetails?.client || 0}
                    </p>
                    <p className="dashboardInfo">Total Clients</p>
                  </Link>
                </div>
              </>
            )}
          </div>

          <div className="row1">
            {role && role === "Owner" && (
              <>
                <div className="ItemContainer1">
                  <Link to={"/Job"}>
                    <p className="managerCounter">
                      {companyLoading ? "Loading..." : companyDetails?.job}
                    </p>
                    <p className="dashboardInfo">Total Jobs</p>
                  </Link>
                </div>
                <div className="ItemContainer1">
                  <Link to={"/Employee"}>
                    <p className="managerCounter">
                      {companyLoading ? "Loading..." : companyDetails?.employee}
                    </p>
                    <p className="dashboardInfo">Total Employees</p>
                  </Link>
                </div>
                <div className="ItemContainer1">
                  <Link to={"/Client"}>
                    <p className="managerCounter">
                      {companyLoading ? "Loading..." : companyDetails?.client}
                    </p>
                    <p className="dashboardInfo">Total Clients</p>
                  </Link>
                </div>
                <div className="ItemContainer1">
                  <Link to={"/Manager"}>
                    <p className="managerCounter">
                      {companyLoading ? "Loading..." : companyDetails?.manager}
                    </p>
                    <p className="dashboardInfo">Total Manager</p>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
// export default HomeRightBar;
const mapStateToProps = (state) => ({
  authToken: state.auth.token,
});
export default connect(mapStateToProps, {
  createJob,
  fetchCompanyDetails,
})(HomeRightBar);
