import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";
import { toast } from "react-toastify";

export const setAvailabilityAction = (data, navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      ` ${BaseURL}/employee/set-availability`,
      data,
      {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response?.status === 201) {
      toast.success("Availability set successfully!", { theme: "colored" });
      navigate("/home");
    }
    dispatch({
      type: "SET_AVAILABILITY_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "SET_AVAILABILITY_FAILURE",
      payload: error.response?.data,
    });
  }
};
