import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";

import { socket } from "../../../socket";
import { fetchMessages } from "./getMessageActions";

// actions.js
export const FETCH_CHAT_SUCCESS = "FETCH_CHAT_SUCCESS";
export const FETCH_CHAT_FAILURE = "FETCH_CHAT_FAILURE";
export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

// Define the sendMessage function
export const sendMessage = (message, senderId, receiverId, chatId) => {
  // ... (sendMessage logic)
};

export const fetchChatAndSendMessage = (
  senderId,
  receivername,
  receiverId,
  jobId,
  message
) => {
  return async (dispatch) => {
    try {
      // Fetch chat data
      const requestData = {
        senderId: senderId,
        receivername: receivername,
        receiverId: receiverId,
        jobId: jobId,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${BaseURL}/chat/findAndCreateChat`,
        headers: {
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      const response = await axios.request(config);
      const chatData = response.data.data;

      dispatch({
        type: FETCH_CHAT_SUCCESS,
        payload: { chatData },
      });

      // Send message using the previously defined sendMessage function
      const { _id: chatId } = chatData;
      dispatch(fetchMessages(chatId));
    } catch (error) {
      dispatch({
        type: FETCH_CHAT_FAILURE,
        payload: { error: error.message },
      });
    }
  };
};

// // Import necessary dependencies and constants

// export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
// export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
// export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

// export const sendMessageRequest = () => ({
//   type: SEND_MESSAGE_REQUEST,
// });

// export const sendMessageSuccess = (message) => ({
//   type: SEND_MESSAGE_SUCCESS,
//   payload: message,
// });

// export const sendMessageFailure = (error) => ({
//   type: SEND_MESSAGE_FAILURE,
//   payload: error,
// });

// export const sendMessage = (senderId, receiverId, jobId, content, chatId) => {
//   return (dispatch) => {
//     dispatch(sendMessageRequest());

//     // Adjust this part based on your Socket.IO implementation
//     socket.emit('send-message', {
//       senderId,
//       receiverId,
//       jobId,
//       content,
//       chatId,
//     });

//     // The rest of the logic will depend on how your server handles the message
//     // You may choose to wait for an acknowledgment from the server or handle it differently

//     // For demonstration purposes, let's assume a successful message
//     dispatch(sendMessageSuccess({ senderId, receiverId, content,chatId,jobId }));
//   };
// };
