import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { AuthReducer } from "./reducers/authReducers";
import { employeeReducer } from "./reducers/employeeReducers";
import { managerReducer } from "./reducers/managerReducers";
import { availableJobReducer } from "./reducers/availableJobReducers";
import { clientReducer } from "./reducers/clientReducer";
import { leaveRequestReducer } from "./reducers/leaveRequestReducers";
import { incidentReducer } from "./reducers/incidentReducers";
import { jobReducer } from "./reducers/jobReducers";
import createJobReducer from "./reducers/createJobReducers";
import companyReducer from "./reducers/companyReducers";
import { feedbackReducer } from "./reducers/feedbackReducers";
import { requestReducer } from "./reducers/requestReducer";
import updateAvailabilityReducer from "./reducers/updateAvailabilityReducer";
import jobActionReducer from "./reducers/jobActionReducer";
import userProfileReducer from "./reducers/getUserProfileReducers";
import chatReducer from "./reducers/chatReducers/createChatReducers";
import createChatReducer from "./reducers/chatReducers/createChatReducers";
import chatsReducer from "./reducers/chatReducers/chatReducer";
import messagesReducer from "./reducers/chatReducers/getMesageReducer";
import { invoiceReducer } from "./reducers/generateInvoiceReducer";
import { getInvoiceReducer } from "./reducers/getInvoiceReducer";
import getManagerRosterReducer from "./reducers/rosterReducers/getManagerRosterReducer";
import { setAvailabilityAction } from "./actions/availabilityActions/setAvailabilityAction";
import { ManagerDetailReducer } from "./reducers/ManagerDetailReducer";
import { cancelJobReducer } from "./reducers/cancelJobReducer";

const reducer = combineReducers({
  auth: AuthReducer,
  companyDetail: companyReducer,
  managerDetail: ManagerDetailReducer,
  employee: employeeReducer,
  manager: managerReducer,
  availableJob: availableJobReducer,
  job: jobReducer,
  canceljobs: cancelJobReducer,
  createJob: createJobReducer,
  client: clientReducer,
  leaveRequest: leaveRequestReducer,
  incident: incidentReducer,
  feedback: feedbackReducer,
  request: requestReducer,
  updateAvailability: updateAvailabilityReducer,
  jobAction: jobActionReducer,
  userProfile: userProfileReducer,
  createChat: createChatReducer,
  chat: chatsReducer,
  messages: messagesReducer,
  invoice: getInvoiceReducer,
  roster: getManagerRosterReducer,
  setAvailability: setAvailabilityAction,
});

let initialState = {};
const Store = configureStore({
  reducer,
  initialState,
});

export default Store;
