import React from "react";
import searchIcon from "../../../../../assets/Icons/searchIcon.png";

function ChatSearch(props) {
  return (
    <div className="chatSearch">
      <div className="searchFrom">
        <input type="text" placeholder="Search User" />
      </div>
      {/* <div className='userChat'>
                <img src='https://images.unsplash.com/photo-1618641986557-1ecd230959aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1587&q=80' />
                <div className='userChatInfo'>
                    <span>Aziz Khan</span>
                    <p>Thanks for asking...</p>
                </div>
            </div> */}
    </div>
  );
}
export default ChatSearch;
