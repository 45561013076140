import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'

const PrivateComponent = ({ children }) => {
    const role = localStorage.getItem('role');
    if (role === "Owner" || role === "Manager") {
        return <Outlet />;
    } else {
        // Redirect to a login page or any other route for unauthorized users
        return <Navigate to="/LoginPage" />;
    }
}

export default PrivateComponent;
