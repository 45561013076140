import React from 'react';
import SideBar from '../../SideBar';
import RosterRightBar from '../RightBars/RosterRightBar';

function Roster(props) {
    return(
        <div style={{display:'flex'}}>
            <SideBar/>
            <RosterRightBar/>
        </div>
    )
}
export default Roster;