import { APPROVE_AVAILABILITY_REQUEST_SUCCESS, GET_REQUESTED_AVAILABILITIES_SUCCESS, REJECT_AVAILABILITY_REQUEST_SUCCESS } from "../constants/AvailabilityConstants";

// Initial state
const initialState = {
    loading: false,
    requested: [],
  };
  
  // Reducer function
  const updateAvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
      case APPROVE_AVAILABILITY_REQUEST_SUCCESS:
      case REJECT_AVAILABILITY_REQUEST_SUCCESS:
        // Update the state after approval or rejection
        const updatedRequested = state.requested.filter(
          (request) => request._id !== action.payload.requestId
        );
        return {
          ...state,
          requested: updatedRequested,
        };
  
      case GET_REQUESTED_AVAILABILITIES_SUCCESS:
        // Update the state with the fetched requested availabilities
        return {
          ...state,
          requested: action.payload.requested,
        };
  
      // Handle other actions if needed
  
      default:
        return state;
    }
  };
  
  export default updateAvailabilityReducer;