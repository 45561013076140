import { AVAILABLEJOB_FAIL, AVAILABLEJOB_REQUEST, AVAILABLEJOB_SUCCESS, CLEAR_ERRORS } from "../constants/AvailableJobConstants";

const initialState = {
    loading: false,
    jobs: [],
    errors: null,
};

export const availableJobReducer = (state = initialState, action) => {
    switch (action.type) {
        case AVAILABLEJOB_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case AVAILABLEJOB_SUCCESS:
            return {
                ...state,
                loading: false,
                jobs: action.payload, // Directly set the jobs data
            };

        case AVAILABLEJOB_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                message: null,
                status: null,
                errors: null,
            };

        default:
            return state;
    }
};
