import { FETCH_INVOICES_FAILURE, FETCH_INVOICES_REQUEST, FETCH_INVOICES_SUCCESS, UPDATE_INVOICE_STATUS_SUCCESS } from "../actions/getInvoiceActions";

  const initialState = {
    loading: false,
    invoices: [],
    error: null,
  };
  
  export const getInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_INVOICES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_INVOICES_SUCCESS:
        return {
          ...state,
          loading: false,
          invoices: action.payload.invoices,
          error: null,
        };
      case FETCH_INVOICES_FAILURE:
        return {
          ...state,
          loading: false,
          invoices: [],
          error: action.payload.error,
        };
        case UPDATE_INVOICE_STATUS_SUCCESS:
          // Update the specific invoice status in invoices array
          const updatedInvoices = state.invoices.map(invoice => {
            if (invoice._id === action.payload._id) {
              return action.payload;
            }
            return invoice;
          });
          return {
            ...state,
            loading: false,
            invoices: updatedInvoices,
            error: null
          };
        default:
          return state;
      }
  };
  