// // Action creators

// import { BaseURL } from "../../constants/BaseURL";
// import { CREATE_JOB_FAILURE, CREATE_JOB_REQUEST, CREATE_JOB_SUCCESS } from "../../constants/jobConstants";

// export const createJobRequest = () => ({
//     type: CREATE_JOB_REQUEST,
// });

// export const createJobSuccess = (jobData) => ({
//     type: CREATE_JOB_SUCCESS,
//     payload: jobData,
// });

// export const createJobFailure = (error) => ({
//     type: CREATE_JOB_FAILURE,
//     payload: error,
// });

// // Asynchronous action creator to make the API request
// export const createJob = (jobData, authToken, navigate) => (dispatch) => {
//     dispatch(createJobRequest());
//     console.log(jobData);
//     const token = localStorage.getItem('token');
//     // Construct your API request here using fetch
//     fetch(`${BaseURL}/manager/create-job`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             "x-auth-token":token
//         },
//         body: JSON.stringify(jobData),
//     })
//     .then((response) =>{

//     console.log(response);
//         response.json()})
//     .then((data) => {
//         // Handle the successful response
//         dispatch(createJobSuccess(data));
//     })
//     .catch((error) => {
//         // Handle errors
//         dispatch(createJobFailure(error));
//     });
// };

import { BaseURL } from "../../constants/BaseURL";
import {
  CREATE_JOB_FAILURE,
  CREATE_JOB_REQUEST,
  CREATE_JOB_SUCCESS,
} from "../../constants/jobConstants";
import { toast } from "react-toastify";

// Action creators
export const createJobRequest = () => ({
  type: CREATE_JOB_REQUEST,
});

export const createJobSuccess = (jobData) => ({
  type: CREATE_JOB_SUCCESS,
  payload: jobData,
});

export const createJobFailure = (error) => ({
  type: CREATE_JOB_FAILURE,
  payload: error,
});

// Asynchronous action creator to make the API request
export const createJob = (jobData, navigate) => {
  return async (dispatch) => {
    dispatch(createJobRequest());

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${BaseURL}/manager/create-job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        body: JSON.stringify(jobData),
      });

      if (response.status === 201) {
        const data = await response.json(); // Parse the JSON data from the response
        dispatch(createJobSuccess(data));
        toast.success("Job created successfully!");
        navigate("/home"); // Navigate to the /home route after success
      }
    } catch (error) {
      dispatch(createJobFailure(error.message));
      toast.error("Failed to create job. Please try again.");
    }
  };
};
