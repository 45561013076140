import React from 'react'
import LeaveRequestRightBar from '../RightBars/LeaveRequestRightBar'
import SideBar from '../../SideBar';
import './LeaveRequest.css'

function LeaveRequest() {
    return (
        <div className='mainLeaveRequestContainer'>
            <SideBar />
            <LeaveRequestRightBar />
        </div>
    )
}
export default LeaveRequest;
