import React, { useState, useEffect } from "react";
import NavBar from "../../NavBar";
import addnewIcon from "../../../../assets/Icons/addnewIcon.png";
import "./Roster.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchManagerRoster,
  fetchRoster,
} from "../../../../redux/actions/rosterActions/getManagerRosterActions";
import { GetEmployeesAction } from "../../../../redux/actions/GetEmployeesAction";
import { GetClientsAction } from "../../../../redux/actions/clientActions";
import { addRoster } from "../../../../redux/actions/rosterActions/createRosterAction";
import { GetManClientAction } from "../../../../redux/actions/GetmManClientAction";
import { GetManagerAction } from "../../../../redux/actions/GetManagerAction";
import { fetchCompanyRoster } from "../../../../redux/actions/rosterActions/GetCompanyRoasterAction";
import SpinerLoading from "../../../Loading";

function RosterRightBar() {
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState("");
  const [showRosterForm, setShowRosterForm] = useState(false);
  const [showShiftForm, setShowShiftForm] = useState(true);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [shifts, setShifts] = useState([]);
  // const [allShifts, setAllShifts] = useState([]);
  // const [isNewShiftAdded, setIsNewShiftAdded] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [task, setTask] = useState("");

  const roster = useSelector((state) => state.roster.roster?.roaster);
  const managerRoster = useSelector((state) => state.roster.roster);
  console.log("🚀 ~ RosterRightBar ~ managerRoster:", managerRoster);
  console.log("🚀 ~ RosterRightBar ~ roster:", roster);
  const loading = useSelector((state) => state.roster.loading);
  const error = useSelector((state) => state.roster.error);
  const employees = useSelector((state) => state.employee.employees);
  const clients = useSelector((state) => state.client.clients);

  useEffect(() => {
    dispatch(GetEmployeesAction());
    dispatch(GetClientsAction());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetManClientAction());
    dispatch(GetManagerAction());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedDate) {
      const currentDate = new Date().toISOString().split("T")[0];
      setSelectedDate(currentDate);
    }
    dispatch(fetchCompanyRoster(selectedDate));
    dispatch(fetchManagerRoster(selectedDate));
  }, [dispatch, selectedDate]);

  const handleEmployeeSelection = (e) => {
    setSelectedEmployee(e.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleCreateRosterClick = () => {
    setShowRosterForm(true);
  };

  const handleAddShiftClick = () => {
    const newShift = {
      startTime,
      endTime,
      taskDetail: task,
      clientId: selectedClient,
      clientName:
        clients.find((client) => client._id === selectedClient)?.name || "N/A",
      employeeId: selectedEmployee,
    };
    setShifts([...shifts, newShift]);
    setStartTime("");
    setEndTime("");
    setSelectedClient("");
    setTask("");
  };

  const handleConfirmClick = () => {
    const employeeShifts = {};

    // Group shifts by employee
    shifts.forEach((shift) => {
      if (!employeeShifts[shift.employeeId]) {
        employeeShifts[shift.employeeId] = {
          employeeId: shift.employeeId,
          employeeName: employees.find(
            (employee) => employee._id === shift.employeeId
          ).name,
          shifts: [],
        };
      }
      employeeShifts[shift.employeeId].shifts.push(shift);
    });

    const employeesData = Object.values(employeeShifts);

    dispatch(addRoster({ date: selectedDate, employees: employeesData }));
    setShowRosterForm(false);
    setShifts([]);
  };

  const handleClientSelection = (e) => {
    setSelectedClient(e.target.value);
    // setIsNewShiftAdded(true);
  };

  return (
    <div className="mainManagerRightBar">
      <NavBar />
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Roster</h1>
          </div>
          {role && role === "Manager" && (
            <div>
              <button
                className="createButton"
                onClick={handleCreateRosterClick}
              >
                <img src={addnewIcon} alt="Add New" className="addIcon" />
                Create Roaster
              </button>
            </div>
          )}
        </div>
        <div className="Roastermain">
          <div className="availabilityFlex">
            <p style={{ marginTop: "15px", fontWeight: "700" }}>Choose Date</p>
            <input
              type="date"
              style={{ height: "60px", border: "none" }}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          {showRosterForm && (
            <div className="roasterForm">
              <div>
                <div>
                  <h2>Select Employee:</h2>
                  <div className="createRoaster-Div">
                    <select
                      className="managerDropDown"
                      name="selectedEmployee"
                      onChange={handleEmployeeSelection}
                      required
                    >
                      {employees && employees?.length > 0 ? (
                        employees?.map((employee, index) => (
                          <option key={index} value={employee._id}>
                            {employee.name}
                          </option>
                        ))
                      ) : (
                        <p>No employees found</p>
                      )}
                    </select>

                    <div className="roasterButtons">
                      <button
                        style={{ marginLeft: "50px" }}
                        className="createButton"
                        onClick={handleAddShiftClick}
                        // disabled={!isNewShiftAdded}
                        disabled={
                          !selectedEmployee ||
                          !startTime ||
                          !endTime ||
                          !selectedClient ||
                          !task
                        }
                      >
                        Add Shift
                      </button>
                      <button
                        className="createButton"
                        style={{ marginLeft: "50px" }}
                        onClick={handleConfirmClick}
                        // disabled={!isNewShiftAdded}
                        disabled={shifts.length === 0} // Enable Create Roster button only when there are shifts to be added
                      >
                        Create Roster
                        {/* Confirm */}
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              {showShiftForm && (
                <div
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    alignItems: "center",
                    padding: "15px",
                    overflow: "hidden",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                      }}
                    >
                      <div>
                        <h4>Start Time:</h4>
                        <div>
                          <input
                            // type="time"
                            type="datetime-local" // Use datetime-local type for the input
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <h4>End Time:</h4>
                        <div>
                          <input
                            // type="time"
                            type="datetime-local" // Use datetime-local type for the input
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <label>
                      Select Client : <br />
                      <select
                        className="managerDropDown"
                        name="selectedClient"
                        onChange={handleClientSelection}
                        required
                      >
                        <option value="">Select client</option>
                        {clients && clients.length > 0 ? (
                          clients?.map((client, index) => (
                            <option key={index} value={client._id}>
                              {client.name}
                            </option>
                          ))
                        ) : (
                          <option value="">No clients found</option>
                        )}
                      </select>
                    </label>
                    <div>
                      <label>
                        <input
                          className="inputTask"
                          type="text"
                          value={task}
                          onChange={(e) => setTask(e.target.value)} // Use 'e' instead of 'evt'
                          placeholder="Enter Task"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {shifts?.map((shift, index) => (
                <div key={index} style={{ padding: "15px" }}>
                  <p>Start Time: {shift.startTime}</p>
                  <p>End Time: {shift.endTime}</p>
                  <p>Client Name: {shift.clientName}</p>
                  <p>Task: {shift.taskDetail}</p>
                </div>
              ))}
            </div>
          )}
          {!showRosterForm && (
            <div className="roasterResult">
              {loading && <SpinerLoading />}
              {error && <p>Error: {error}</p>}

              {role && role === "Manager" && (
                <div className="rosterContainer">
                  {managerRoster &&
                  managerRoster.employees &&
                  managerRoster.employees.length > 0 ? (
                    <div className="rosterContent">
                      <h2>Roster for {selectedDate}</h2>
                      {managerRoster.employees.map((employee) => (
                        <div className="singleData" key={employee._id}>
                          <h3>{employee.employeeName}</h3>
                          {employee.shifts && employee.shifts.length > 0 ? (
                            employee.shifts.map((shift) => (
                              <div key={shift._id}>
                                <p>
                                  Start Time:{" "}
                                  {new Date(
                                    shift.startTime
                                  ).toLocaleTimeString()}
                                </p>
                                <p>
                                  End Time:{" "}
                                  {new Date(shift.endTime).toLocaleTimeString()}
                                </p>
                                <p>Task Detail: {shift.taskDetail}</p>
                                <p>Client Name: {shift.clientName}</p>
                              </div>
                            ))
                          ) : (
                            <p>No shifts available</p>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>
                      Sorry, no roster found for the
                      {selectedDate ? selectedDate : "selected"} date. Please
                      choose a date for which a roster has already been created,
                      or press the create button to create a new one.
                    </p>
                  )}
                </div>
              )}
              {role && role === "Owner" && (
                <>
                  {roster && roster.length > 0 ? (
                    <div className="rosterContent">
                      <h2>Roster for {selectedDate}</h2>
                      {roster.map((rosterItem) => (
                        <div key={rosterItem._id}>
                          <h3>
                            Date:{" "}
                            {new Date(rosterItem.date).toLocaleDateString()}
                          </h3>
                          {rosterItem.employees.length > 0 ? (
                            rosterItem.employees.map((employee) => (
                              <div className="singleData" key={employee._id}>
                                <h3>{employee.employeeName}</h3>
                                {employee.shifts.length > 0 ? (
                                  employee.shifts.map((shift) => (
                                    <div key={shift._id}>
                                      <p>
                                        Start Time:{" "}
                                        {new Date(
                                          shift.startTime
                                        ).toLocaleTimeString()}
                                      </p>
                                      <p>
                                        End Time:{" "}
                                        {new Date(
                                          shift.endTime
                                        ).toLocaleTimeString()}
                                      </p>
                                      <p>Task Detail: {shift.taskDetail}</p>
                                      <p>Client Name: {shift.clientName}</p>
                                    </div>
                                  ))
                                ) : (
                                  <p>No shifts available</p>
                                )}
                              </div>
                            ))
                          ) : (
                            <p>No employees available</p>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>
                      Sorry, no ros was found for the{" "}
                      {selectedDate ? selectedDate : "selected"} date. Please
                      choose a date for which a roster has already been created,
                      or press the create button to create a new one.
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RosterRightBar;
