// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchUserProfile } from "../../../../../redux/actions/getUserProfileAction";
// import Messages from "../ChatBars/Messages";
// import { fetchMessages } from "../../../../../redux/actions/chatActions/getMessageActions";
// import { fetchChats } from "../../../../../redux/actions/chatActions/chatActions";
// import { socket } from "../../../../../socket";
// import "../../Chat/Chat.css";
// import "./ChatBars.css";
// import { RxAvatar } from "react-icons/rx";
// import Chat from "./Chat";
// function Chats(props) {
//   const dispatch = useDispatch();
//   const [selectedChat, setSelectedChat] = useState(null);

//   //fectch user:
//   const user = useSelector((state) => state.userProfile.user);
//   const userId = user ? user._id : null;
//   useEffect(() => {
//     dispatch(fetchUserProfile());
//   }, [dispatch]);
//   //fetch chats:
//   const chats = useSelector((state) => state.chat.chats);
//   console.log("🚀 ~ Chats ~ chats:", chats);

//   useEffect(() => {
//     if (userId) {
//       dispatch(fetchChats(userId));
//     }
//   }, [dispatch, userId]);

//   const handleChatClick = (chat) => {
//     console.log("🚀 ~ handleChatClick ~ chat:", chat);
//     setSelectedChat(chat);
//     dispatch(fetchMessages(chat.chatId));
//   };

//   return (
//     <>
//       <div>
//         <button>Chats </button>
//         <button>Groups </button>
//       </div>

//       <div className="chats">
//         <div className="chatsList">
//           {chats.map((chat) => (
//             <div
//               key={chat.chatId}
//               className={`userChats ${selectedChat === chat ? "active2" : ""}`}
//               onClick={() => handleChatClick(chat)}
//             >
//               {chat.isGroupChat ? (
//                 <>
//                   {chat.imageUrl ? (
//                     <img src={chat.imageUrl} alt="Group Avatar" />
//                   ) : (
//                     <RxAvatar
//                       style={{
//                         borderRadius: "500px",
//                         background: "#bdc3d5",
//                         color: "#ffffff",
//                         width: "45px",
//                         height: "45px",
//                       }}
//                     />
//                   )}
//                   <div className="userChatsInfo">
//                     <span>{chat.groupName}</span>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   {chat.imageUrl ? (
//                     <img src={chat.imageUrl} alt="User Avatar" />
//                   ) : (
//                     <RxAvatar
//                       style={{
//                         borderRadius: "500px",
//                         background: "#bdc3d5",
//                         color: "#ffffff",
//                         width: "45px",
//                         height: "45px",
//                       }}
//                     />
//                   )}

//                   <div className="userChatsInfo">
//                     <span>{chat.name}</span>
//                   </div>
//                 </>
//               )}
//             </div>
//           ))}
//         </div>
//         <Chat selectedChat={selectedChat} />
//       </div>
//     </>
//   );
// }

// export default Chats;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../../../redux/actions/getUserProfileAction";
import Messages from "../ChatBars/Messages";
import { fetchMessages } from "../../../../../redux/actions/chatActions/getMessageActions";
import { fetchChats } from "../../../../../redux/actions/chatActions/chatActions";
import { socket } from "../../../../../socket";
import "../../Chat/Chat.css";
import "./ChatBars.css";
import { RxAvatar } from "react-icons/rx";
import Chat from "./Chat";
import ChatSearch from "./ChatSearch";
import { MdGroups } from "react-icons/md";
import { CiBoxList } from "react-icons/ci";

import GroupChat from "./GroupChat"; // Assuming you have a separate GroupChat component

function Chats(props) {
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatType, setChatType] = useState("single"); // State to track the selected chat type

  // Fetch user:
  const user = useSelector((state) => state.userProfile.user);
  const userId = user ? user._id : null;

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  // Fetch chats:
  const chats = useSelector((state) => state.chat.chats);

  useEffect(() => {
    if (userId) {
      dispatch(fetchChats(userId));
    }
  }, [dispatch, userId]);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    dispatch(fetchMessages(chat.chatId));
  };

  // Filter chats based on selected chat type (single or group)
  const filteredChats = chats.filter((chat) =>
    chatType === "single" ? !chat.isGroupChat : chat.isGroupChat
  );

  return (
    <>
      <div>
        <div className="chats">
          <div className="chatsList">
            <ChatSearch />
            <div className="chatTypeButtons">
              <button
                className={chatType === "single" ? "active" : ""}
                onClick={() => setChatType("single")}
              >
                <p> Chats</p>

                <CiBoxList className="chat-icon" />
              </button>
              <button
                className={chatType === "group" ? "active" : ""}
                onClick={() => setChatType("group")}
              >
                <p>Groups</p>

                <MdGroups className="chat-icon" />
              </button>
            </div>
            {filteredChats.map((chat) => (
              <div
                key={chat.chatId}
                className={`userChats ${
                  selectedChat === chat ? "active2" : ""
                }`}
                onClick={() => handleChatClick(chat)}
              >
                {chat.isGroupChat ? (
                  <>
                    {chat.imageUrl ? (
                      <img src={chat.imageUrl} alt="Group Avatar" />
                    ) : (
                      <RxAvatar
                        style={{
                          borderRadius: "500px",
                          background: "#bdc3d5",
                          color: "#ffffff",
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    )}
                    <div className="userChatsInfo">
                      <span>{chat.groupName}</span>
                    </div>
                  </>
                ) : (
                  <>
                    {chat.imageUrl ? (
                      <img src={chat.imageUrl} alt="User Avatar" />
                    ) : (
                      <RxAvatar
                        style={{
                          borderRadius: "500px",
                          background: "#bdc3d5",
                          color: "#ffffff",
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    )}

                    <div className="userChatsInfo">
                      <span>{chat.name}</span>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          {chatType === "single" ? (
            <Chat selectedChat={selectedChat} />
          ) : (
            // <h1>group</h1>
            <GroupChat selectedChat={selectedChat} />
          )}
        </div>
      </div>
    </>
  );
}

export default Chats;
