import React, { useEffect, useState } from "react";
import "./AssignedJob.css";

import NavBar from "../../../NavBar";
import { DataGrid } from "@mui/x-data-grid";
import CompletedJob from "./CompletedJob";
import AvailableJob from "./AvailableJob";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../../../../redux/actions/jobActions";

import { GiPathDistance } from "react-icons/gi";
import assignedJobIcon from "../../../../../assets/Icons/assignedJobIcon.png";
import availableJobIcon from "../../../../../assets/Icons/availableJob.png";
import completedjobIcon from "../../../../../assets/Icons/completedJob.png";
import shiftIcon from "../../../../../assets/Icons/shiftIcon.png";
import deleteIcon from "../../../../../assets/Icons/deleteIcon.png";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  approveJobRequest,
  rejectJobRequest,
} from "../../../../../redux/actions/jobApprovalAction";
import CanceledJob from "./CancelJob";
import { fetchChatAndSendMessage } from "../../../../../redux/actions/chatActions/createChatActions";
import Chat from "../ChatBars/Chat.js";
import SperateChat from "../ChatBars/SeprateChat.js";
import { GetClientsInfoAction } from "../../../../../redux/actions/clientInfoAction.js";
import { GetEmployeesAction } from "../../../../../redux/actions/GetEmployeesAction.js";
import { createJob } from "../../../../../redux/actions/createActions/createJobAcrion.js";
import { useNavigate } from "react-router";
import addnewIcon from "../../../../../assets/Icons/addnewIcon.png";
import { GetManagerAction } from "../../../../../redux/actions/GetManagerAction.js";

function formatDateTime(dateTimeString, formatType) {
  const dateTime = new Date(dateTimeString);

  if (formatType === "time") {
    return dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  }
  if (formatType === "date") {
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }
  return "";
}
function TimePicker({ setStartTime, setEndTime }) {
  return (
    <div>
      <label>Start Time:&nbsp;&nbsp;</label>
      <input
        type="time"
        name="startTime"
        onChange={(e) => setStartTime(e.target.value)}
      />
      <br />
      <br />
      <label>End Time:&nbsp;&nbsp;&nbsp;&nbsp;</label>
      <input
        type="time"
        name="endTime"
        onChange={(e) => setEndTime(e.target.value)}
      />
      <br />
      <br />
    </div>
  );
}

function AssignedJob(props) {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const { loading, jobs, status } = useSelector((state) => state.job);
  const createChat = useSelector((state) => state.createChat);
  console.log("🚀 ~ craetechat:", createChat);
  // console.log("🚀 ~ jobs:", jobs);

  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleString(undefined, {
    month: "long",
  })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

  const formattedTime = `${currentDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  })}`;

  const clients = useSelector((state) => state.client.clients);
  const Loading = useSelector((state) => state.client.loading);
  const employees = useSelector((state) => state.employee.employees);
  console.log("🚀 ~ employees:", employees);

  const [option, setOption] = useState([""]);
  const [newOptionText, setNewOptionText] = useState("");
  const [isAssign, setIsAssign] = useState(false);
  const onSelect = (event) => {};
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const handleEmployeeSelection = (e) => {
    setSelectedEmployee(e.target.value);
    setJobFormData((prevData) => ({
      ...prevData,
      employeeId: e.target.value,
    }));
  };
  const [selectedClient, setSelectedClient] = useState("");
  const handleClientSelection = (e) => {
    setSelectedClient(e.target.value);
    setJobFormData((prevData) => ({
      ...prevData,
      clientID: e.target.value,
    }));
  };
  const [CreateJobPopup, setCreateJobPopup] = useState(false);

  useEffect(() => {
    dispatch(GetClientsInfoAction());
  }, [dispatch]);

  const handleCreateJobClick = () => {
    setCreateJobPopup(true);
  };
  const closeCreateJobPopup = () => {
    setCreateJobPopup(false);
  };
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let errors = {};
    // Validate title
    if (!jobFormData.title || jobFormData.title.length === 0) {
      errors.title = "Title is required";
    } else {
      errors.title = "";
    }
    if (!jobFormData.address || jobFormData.address.length === 0) {
      errors.address = "Address is required";
    } else {
      errors.address = "";
    }
    if (!jobFormData.clientID || jobFormData.clientID.length === 0) {
      errors.clientID = "clientId is required";
    } else {
      errors.clientID = "";
    }
    if (!jobFormData.allowances || jobFormData.allowances.length === 0) {
      errors.allowances = "Allowances is required";
    } else {
      errors.allowances = "";
    }
    if (!jobFormData.description || jobFormData.description.length === 0) {
      errors.description = "Description is required";
    } else {
      errors.description = "";
    }
    if (!jobFormData.task || jobFormData.task.length === 0) {
      errors.task = "Task is required";
    } else {
      errors.task = "";
    }
    if (!jobFormData.date || jobFormData.date.length === 0) {
      errors.date = "Date is required";
    } else {
      errors.date = "";
    }
    if (!jobFormData.startTime || !jobFormData.endTime) {
      errors.startTime = "Start Time and End Time are required";
    } else if (
      new Date(jobFormData.endTime) <= new Date(jobFormData.startTime)
    ) {
      errors.startTime = "Start Time should be before End Time";
    } else {
      errors.startTime = "";
    }
    if (!jobFormData.shift || jobFormData.shift.length === 0) {
      errors.shift = "Shift is required";
    } else {
      errors.shift = "";
    }

    if (
      Object.values(jobFormData).some(
        (value) => !value || (Array.isArray(value) && value.length === 0)
      )
    ) {
      errors.emptyFields = "All fields are required";
    } else {
      errors.emptyFields = "";
    }
    setErrors(errors);

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error.length > 0);
    return !hasErrors;
  };
  const [jobFormData, setJobFormData] = useState({
    title: "",
    clientID: "",
    employeeId: "",
    description: "",
    allowances: "",
    task: [""],
    date: "",
    startTime: "",
    endTime: "",
    shift: "",
    address: "",
  });
  const handleRegisterClick = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      console.log(jobFormData);
      return;
    } else {
      closeCreateJobPopup();
    }

    const allowancesValue = parseFloat(jobFormData.allowances);

    // Convert task array elements to an array of objects
    const tasksArray = jobFormData.task.map((task) => ({ title: task }));
    // Prepare the job data in the required format
    const jobData = {
      clientID: jobFormData.clientID,
      employeeId: jobFormData.employeeId,
      title: jobFormData.title,
      description: jobFormData.description,
      allowances: allowancesValue,
      task: tasksArray,
      date: selectedDate,
      startTime: selectedDate,
      endTime: selectedDate,
      shift: jobFormData.shift,
      address: jobFormData.address,
    };

    // Log the generated jobData for verification (optional)
    console.log("Generated jobData:", jobData);
    await dispatch(createJob(jobData, navigate));
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "assignTask") {
      if (value === "Now") {
        setIsAssign(true);
      } else {
        setIsAssign(false);
      }
    }

    if (name === "date") {
      // Handle date input separately and format it to the required format
      const formattedDate = new Date(value);
      const year = formattedDate.getFullYear();
      const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
      const day = formattedDate.getDate().toString().padStart(2, "0");
      const formattedDateString = `${year}-${month}-${day}T00:00:00Z`;

      setJobFormData((prevData) => ({
        ...prevData,
        date: formattedDateString,
      }));
    } else if (name === "startTime" || name === "endTime") {
      // Handle start time and end time separately
      setJobFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // For other inputs, handle date, start time, and end time
      setJobFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const addNewTask = (e) => {
    e.preventDefault();
    setJobFormData((prevData) => ({
      ...prevData,
      task: [...(prevData.task || []), ""], // Ensure task is an array
    }));
  };

  // Handle changing the task field
  const handleTaskChange = (e, index) => {
    const { value } = e.target;
    const updatedTasks = [...jobFormData.task];
    updatedTasks[index] = value;
    setJobFormData({
      ...jobFormData,
      task: updatedTasks,
    });
  };
  useEffect(() => {
    dispatch(GetEmployeesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetManagerAction());
  }, [dispatch]);
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    setJobFormData((prev) => ({ ...prev, date: newDate }));
  };
  const [jobState, setJobState] = useState("AssignedJob");
  const [title, setTitle] = useState("Assigned Job");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredCompletedJobs, setFilteredCompletedJobs] = useState([]);
  const [reloadComponent, setReloadComponent] = useState(false); // Add state for reload

  const filterPendingJobs = () => {
    console.log("Filtering pending jobs...");
    const pendingJobs = jobs.filter(
      (job) => job.status === "completed" && job.approvalStatus == "pending"
    );
    setFilteredCompletedJobs(pendingJobs);
    console.log(pendingJobs);
    setTitle("Completed Job Approval Pending");
    setDropdownOpen(false); // update
  };

  //check dropdown
  const [pendingJobs, setPendingJobs] = useState([]);
  const [approvedJobs, setApprovedJobs] = useState([]);
  const [unapprovedJobs, setUnapprovedJobs] = useState([]);

  const [assignedJobs, setAssignedJobs] = useState([]);

  useEffect(() => {
    if (jobs) {
      setAssignedJobs(
        jobs.filter(
          (job) =>
            job.status === "pending" &&
            (!job.employee || job.employee.length > 0)
        )
      );
    }
  }, [jobs]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedJobForApproval, setSelectedJobForApproval] = useState(null);
  const [job, setJob] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);

  const handleRowClick = (params) => {
    setSelectedRow();
    console.log(params.id);
    setJob(jobs?.find((job1) => job1._id === params.id));
    setSelectedJobForApproval(jobs?.find((job1) => job1._id === params.id));
  };

  const handleAssignRowClick = (params) => {
    setSelectedRow(params.row);
  };
  const shiftStartTime = new Date(job?.startTime);
  const shiftEndTime = new Date(job?.endTime);
  const timeDifference = shiftEndTime - shiftStartTime;
  const shiftHours = timeDifference / (1000 * 60 * 60);

  const filterApprovedJobs = () => {
    const approvedJobs = jobs.filter(
      (job) => job.status === "completed" && job.approvalStatus === "approved"
    );
    setFilteredCompletedJobs(approvedJobs);
    console.log(approvedJobs);
    setTitle("Completed Job Approved");
    setDropdownOpen(false); //update
  };

  const filterUnapprovedJobs = () => {
    if (jobs) {
      const unapprovedJobs = jobs.filter(
        (job) =>
          job.status === "completed" && job.approvalStatus === "unapproved"
      );
      setFilteredCompletedJobs(unapprovedJobs);
      console.log(unapprovedJobs);
      setTitle("Completed Job Unapproved");
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

  const handleCompletedJobClick = () => {
    setJobState("CompletedJob");
    // setTitle('Completed Job');
    setDropdownOpen(!dropdownOpen);
    dispatch(fetchJobs());
  };

  // Options for the dropdown
  const options = [
    { id: "pending", label: "Pending" },
    { id: "approved", label: "Approved" },
    { id: "unapproved", label: "Unapproved" },
  ];

  // Event handler for dropdown change
  const handleDropdownChange = (event) => {
    let selectedValue = event.target.value;
    switch (selectedValue) {
      case "pending":
        filterPendingJobs();
        break;
      case "approved":
        filterApprovedJobs();
        break;
      case "unapproved":
        filterUnapprovedJobs();
        break;
      default:
        console.log("default");
        break;
    }
  };

  const handleApprove = async () => {
    if (selectedRow !== null) {
      if (
        window.confirm(
          "Are you sure you want to approve this availability request?"
        )
      ) {
        try {
          await dispatch(approveJobRequest(selectedRow));
          toast.success("Availability request approved successfully");
          setReloadComponent((prev) => !prev); // Toggle reloadComponent state
        } catch (error) {
          toast.error("Error approving availability request");
          console.error("Error approving availability request:", error);
        }
      }
    }
  };
  const handleUnapprove = async () => {
    if (selectedRow !== null) {
      if (
        window.confirm(
          "Are you sure you want to reject this availability request?"
        )
      ) {
        try {
          await dispatch(rejectJobRequest(selectedRow));
          toast.success("Availability request rejected successfully");
          setReloadComponent((prev) => !prev); // Toggle reloadComponent state
        } catch (error) {
          toast.error("Error rejecting availability request");
          console.error("Error rejecting availability request:", error);
        }
      }
    }
  };

  const [showChat, setShowChat] = useState(false);
  const handleChatCreate = async () => {
    if (selectedRow) {
      const receiverId = selectedRow.employee[0]?._id;
      const receivername = selectedRow.employee[0]?.name;
      const senderId = selectedRow.manager[0]?._id;
      const jobId = selectedRow._id;

      await dispatch(
        fetchChatAndSendMessage(senderId, receivername, receiverId, jobId)
      );
      setShowChat(true);
    } else {
      console.log("No row selected");
    }
  };

  useEffect(() => {
    if (createChat.chatData) {
      setSelectedChat(createChat.chatData);
    }
  }, [createChat.chatData]);

  const columns = [
    { field: "title", headerName: "Job Title", width: 200 },
    {
      field: "client", // Remove [0] to allow for multiple clients
      headerName: "Client",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.client && params.row.client.length > 0) {
          return params.row.client[0].name;
        }
        return "";
      },
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.manager && params.row.manager.length > 0) {
          return params.row.manager[0].name;
        }
        return "";
      },
    },
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.employee && params.row.employee.length > 0) {
          return params.row.employee[0].name;
        }
        return "";
      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 150,
      valueFormatter: (params) => formatDateTime(params.value, "time"),
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 150,
      valueFormatter: (params) => formatDateTime(params.value, "time"),
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      valueFormatter: (params) => formatDateTime(params.value, "date"),
    },
  ];
  const handleCloseChat = () => {
    setSelectedRow(null); // Clear the selected row
    setShowChat(false); // Hide the chat component
  };

  return (
    <div className="mainAvailableJobRightBarContainer">
      <NavBar />
      <div className="itemContainer">
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Job Details </h1>
            <br />
            <div>
              {role && role === "Manager" && (
                <button className="createButton" onClick={handleCreateJobClick}>
                  <img src={addnewIcon} alt="Add New" className="addIcon" />
                  Create Job
                </button>
              )}
              <div>
                {CreateJobPopup && (
                  <div className="clientmain">
                    <div className="popupClientForm ">
                      <div className="popup-header">
                        <h2>Create Job</h2>
                        <h2
                          onClick={closeCreateJobPopup}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "red",
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          X
                        </h2>
                      </div>
                      <form id="clientForm" className="mainForm">
                        <h3>Personal</h3>
                        <br />
                        <section className="clientFormContainer">
                          <div className="clientCol-1">
                            <label>
                              Job Title
                              <br />
                              <input
                                placeholder="Enter Your Job"
                                type="text"
                                className="clientTextBox"
                                name="title"
                                value={jobFormData.title}
                                onChange={handleFormChange}
                                required
                              />
                              {errors.title && (
                                <span className="error-message">
                                  {errors.title}
                                </span>
                              )}
                            </label>
                            <br />
                            <br />
                            <label>
                              Address <br />
                              <input
                                placeholder="Enter Your Address"
                                type="text"
                                className="clientTextBox"
                                name="address"
                                value={jobFormData.address}
                                onChange={handleFormChange}
                                required
                              />
                              {errors.address && (
                                <span className="error-message">
                                  {errors.address}
                                </span>
                              )}
                            </label>
                            <br />
                            <br />
                            <label>
                              Date <br />
                              <input
                                type="date"
                                className="clientTextBox"
                                name="formatedDate"
                                value={selectedDate}
                                onChange={handleDateChange}
                                required
                              />
                              {/* {errors.title && <span className="error-message">{errors.title}</span>} */}
                            </label>
                            <br />
                            <br />
                            <TimePicker
                              setStartTime={(startTime) =>
                                setJobFormData((prevData) => ({
                                  ...prevData,
                                  startTime: Date.now,
                                }))
                              }
                              setEndTime={(endTime) =>
                                setJobFormData((prevData) => ({
                                  ...prevData,
                                  endTime: Date.now,
                                }))
                              }
                            />
                            <label>
                              {" "}
                              Select shift <br />
                              <input
                                type="radio"
                                name="shift"
                                value="day"
                                checked={jobFormData.shift === "day"}
                                onChange={handleFormChange}
                                required
                              />{" "}
                              Day
                            </label>{" "}
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;
                            <label>
                              <input
                                type="radio"
                                name="shift"
                                value="night"
                                checked={jobFormData.shift === "night"}
                                onChange={handleFormChange}
                                required
                              />{" "}
                              Night
                            </label>
                            <br />
                            <br />
                            <label>
                              Any Allowance (Optional) <br />
                              <input
                                placeholder="$ "
                                type="number"
                                className="clientTextBox"
                                name="allowances"
                                value={jobFormData.allowances}
                                onChange={handleFormChange}
                              />
                            </label>
                            <br />
                            <br />
                            <label>
                              Select Client : <br />
                              {Loading ? (
                                <p>Loading clients...</p>
                              ) : (
                                <select
                                  className="clientDropDown"
                                  name="selectedClient"
                                  value={jobFormData.selectedClient}
                                  onChange={handleClientSelection}
                                  required
                                >
                                  <option value="">Select client</option>
                                  {clients && clients.length > 0 ? (
                                    clients.map((client, index) => (
                                      <option key={index} value={client._id}>
                                        {client.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">No clients found</option>
                                  )}
                                </select>
                              )}
                            </label>
                          </div>
                          <div className="clientCol-2">
                            <label for="description">
                              Job Description <br />
                              <textarea
                                placeholder="Enter Your Description"
                                className="textarea1"
                                name="description"
                                value={jobFormData.description}
                                onChange={handleFormChange}
                                required
                              >
                                {errors.description && (
                                  <span className="error-message">
                                    {errors.description}
                                  </span>
                                )}
                              </textarea>
                            </label>
                            <br />
                            <br />
                            <label>
                              Task
                              <div className="addtask">
                                {jobFormData.task.map((task, index) => (
                                  <input
                                    key={index}
                                    type="text"
                                    className="addTextBox"
                                    name="task"
                                    value={task}
                                    onChange={(e) => handleTaskChange(e, index)}
                                    placeholder="Enter a new task"
                                    required
                                  />
                                ))}
                                {errors.task && (
                                  <span className="error-message">
                                    {errors.task}
                                  </span>
                                )}
                                <button
                                  className="addTaskbtn"
                                  onClick={addNewTask}
                                >
                                  Add
                                </button>
                              </div>
                            </label>
                            <br />
                            {/* <label>
                            Assign Task : <br />
                            <select
                              className="clientDropDown"
                              name="assignTask"
                              value={jobFormData.task}
                              onChange={handleFormChange}
                              required
                            >
                              {errors.task && (
                                <span className="error-message">
                                  {errors.task}
                                </span>
                              )}
                              <option>select</option>
                              <option value="Now">Now</option>
                              <option value="Later">Later</option>
                            </select>
                          </label>
                          <br /> */}
                            <br />
                            {/* {isAssign && ( */}
                            <div>
                              <label>
                                Select Employee:
                                <select
                                  className="clientDropDown"
                                  name="selectedEmployee"
                                  value={jobFormData.selectedEmployee}
                                  onChange={(e) =>
                                    handleEmployeeSelection(
                                      e,
                                      jobFormData.selectedEmployee
                                    )
                                  }
                                  required
                                >
                                  <option value="">Any One</option>
                                  {employees && employees.length > 0 ? (
                                    employees.map((employee, index) => (
                                      <option key={index} value={employee._id}>
                                        {employee.name}
                                      </option>
                                    ))
                                  ) : (
                                    <p>No employees found</p>
                                  )}
                                </select>
                              </label>
                            </div>
                            {/* // )} */}
                            <div>
                              {errors.emptyFields && (
                                <span className="error-message">
                                  {errors.emptyFields}
                                </span>
                              )}
                              <button
                                type="submit"
                                className="managerRegister-btn"
                                onClick={handleRegisterClick}
                              >
                                Register
                              </button>
                            </div>
                          </div>
                        </section>
                        <br />
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <br />
            <h1 className="dashtext">{title}</h1>
            <div className="jobsFlex">
              <p
                className={`
                  ${title === "Assigned Job" ? " active" : ""} assignedText`}
                onClick={() => {
                  setJobState("AssignedJob");
                  setTitle("Assigned Job");
                }}
              >
                <img src={assignedJobIcon} alt="" className="jobstatusIcon" />{" "}
                Assigned Jobs
              </p>
              <p
                className={`
               ${title === "Available Job" ? " active" : ""} assignedText`}
                // className="availableText"
                onClick={() => {
                  {
                    /* <p className={styles.availableText} onClick={() => { */
                  }
                  setJobState("AvailableJob");
                  setTitle("Available Job");
                }}
              >
                <img src={availableJobIcon} alt="" className="jobstatusIcon" />{" "}
                Available Jobs
              </p>

              <p
                className={`
                  ${title === "Completed Job" ? " active" : ""} assignedText`}
                // className="completedText"
                onClick={() => {
                  handleCompletedJobClick();
                  setTitle("Completed Job");
                  setJobState("CompletedJob");
                }}
              >
                <img src={completedjobIcon} alt="" className="jobstatusIcon" />
                Completed Jobs
              </p>
              {role && role === "Manager" && (
                <div className="canceljobdiv">
                  <select
                    value=""
                    className="my-custom-bootstrap-dropdown"
                    onChange={handleDropdownChange}
                  >
                    <option></option>
                    {options.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <p
                    className={`
                  ${title === "Cancel Jobs" ? " active" : ""} assignedText`}
                    onClick={() => {
                      handleCompletedJobClick();
                      setTitle("Cancel Jobs");
                      setJobState("CancelJobs");
                    }}
                  >
                    <img
                      src={completedjobIcon}
                      alt=""
                      className="jobstatusIcon"
                    />
                    Cancel Jobs
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="jobItemContainer">
          {/* <div className={styles.jobItemContainer}> */}
          <div className="" style={{ height: "56vh", width: "100%" }}>
            {jobState === "AssignedJob" ? (
              <DataGrid
                style={{ marginTop: "50px", width: "80vw" }}
                rows={assignedJobs}
                columns={columns}
                getRowId={(row) => row._id}
                // onRowClick={handleAssignRowClick}
                onRowClick={(params) => {
                  handleRowClick(params);
                  handleAssignRowClick(params);
                }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 18 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            ) : null}
            {jobState === "AvailableJob" ? <AvailableJob /> : null}

            {/* perfectly working */}
            {jobState === "CompletedJob" ? (
              <div>
                {filteredCompletedJobs.length > 0 ? (
                  <DataGrid
                    rows={filteredCompletedJobs}
                    columns={columns}
                    getRowId={(row) => row._id}
                    onRowClick={handleRowClick}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 18 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                  />
                ) : (
                  <div>
                    {jobState === "CompletedJob" ? (
                      <CompletedJob />
                    ) : (
                      <p> No Completed jobs to display</p>
                    )}
                  </div>
                )}
              </div>
            ) : null}
            <div>{jobState === "CancelJobs" ? <CanceledJob /> : null}</div>
          </div>
        </div>
        {selectedRow !== null && (
          <div className="popupManagerDetail">
            <div className="popup-header">
              <h2>Job Detail ( {job?.title})</h2>
              <h2
                onClick={handleCloseChat}
                style={{
                  cursor: "pointer",
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                X
              </h2>
            </div>
            <section>
              <div className="info-col-1">
                <div className="jobcontainer-1">
                  <h4 style={{ alignItems: "center", textAlign: "center" }}>
                    Employee Details
                  </h4>
                  <ul>
                    {job?.employee?.map((emp) => (
                      <div>
                        <li className="profile">
                          <img
                            src={emp?.imageUrl ?? "N/A"}
                            alt="manager image"
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: "50%",
                            }}
                          />
                          <span className="detail-span">{emp?.name}</span>
                        </li>
                        <li>
                          <h5>Category:</h5>
                          <span className="detail-span">
                            {emp?.category ?? "N/A"}
                          </span>
                        </li>
                        <li>
                          <h5>Email:</h5>
                          <span className="detail-span">
                            {emp?.email ?? "N/A"}
                          </span>
                        </li>
                        <li>
                          <h5>Contact:</h5>
                          <span className="detail-span">
                            {emp?.phone ?? "N/A"}
                          </span>
                        </li>
                        <li>
                          <h5>Address:</h5>
                          <span className="detail-span">
                            {emp?.address ?? "N/A"}
                          </span>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className="jobcontainer-2">
                  <h4 style={{ alignItems: "center", textAlign: "center" }}>
                    Manager Details
                  </h4>
                  <ul>
                    {job?.manager?.map((manager) => (
                      <div>
                        <li className="profile">
                          {/* <h5>Name:</h5> */}
                          <img
                            src={manager?.imageUrl ?? "N/A"}
                            alt="manager image"
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: "50%",
                            }}
                          />
                          <span
                            className="detail-span"
                            style={{ marginLeft: 0 }}
                          >
                            {manager?.name}
                          </span>
                        </li>
                        {/* <li >
                            <h5>Category:</h5>
                            <span className="detail-span">{manager?.category ?? "N/A"}</span>
                          </li> */}
                        <li>
                          <h5>Email:</h5>
                          <span className="detail-span">
                            {manager?.email ?? "N/A"}
                          </span>
                        </li>
                        <li>
                          <h5>Contact:</h5>
                          <span className="detail-span">
                            {manager?.phone ?? "N/A"}
                          </span>
                        </li>
                        <li>
                          <h5>Address:</h5>
                          <span className="detail-span">
                            {manager?.address ?? "N/A"}
                          </span>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className="jobcontainer-2">
                  <h4 style={{ alignItems: "center", textAlign: "center" }}>
                    Client Details
                  </h4>
                  <ul>
                    {job?.client?.map((client) => (
                      <div>
                        <li className="profile">
                          <img
                            src={client?.imageUrl ?? "N/A"}
                            alt="manager image"
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: "50%",
                            }}
                          />
                          <span className="detail-span">{client?.name}</span>
                        </li>
                        {/* <li >
                            <h5>Category:</h5>
                            <span className="detail-span">{client?.category ?? "N/A"}</span>
                          </li> */}
                        <li>
                          <h5>Email:</h5>
                          <span className="detail-span">
                            {client?.email ?? "N/A"}
                          </span>
                        </li>
                        <li>
                          <h5>Contact:</h5>
                          <span className="detail-span">
                            {client?.phone ?? "N/A"}
                          </span>
                        </li>
                        <li>
                          <h5>Address:</h5>
                          <span className="detail-span">
                            {client?.address ?? "N/A"}
                          </span>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="jobinfo-col-2">
                <h1 style={{ marginTop: 0 }}>Description</h1>
                <div className="jobdescription">
                  <h2>Disability Support Worker</h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      Allownces: ${job?.extraAmount}
                    </p>
                    <p
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src="https://as2.ftcdn.net/v2/jpg/01/25/75/67/1000_F_125756794_egU6KkEDJXj05BeKNBkvBMl8Vbs8yomS.jpg"
                        style={{ height: "30px", width: "30px" }}
                      />
                      {job?.shift}
                    </p>
                    <p>Shift Hours: {shiftHours.toFixed(2)}</p>
                  </div>
                  <br />
                  <p>{job?.description}</p>
                  <br />
                  <div>
                    <h3>Tasks</h3>

                    {job?.task.map((task, index) => (
                      /* <ul key={index}> {task.title}</ul> ....useful */
                      <p>{`${index + 1}.  ${task.title}`}</p>
                    ))}
                  </div>
                  <br />
                  <div>
                    <button
                      className="availability-btn"
                      style={{
                        backgroundColor: "greenyellow",
                        cursor: "pointer",
                      }}
                      onClick={handleChatCreate}
                    >
                      Chat
                    </button>
                  </div>
                  {showChat && selectedChat && (
                    <SperateChat selectedChat={selectedChat} />
                  )}
                </div>
                <br />
                {/* {selectedRow && selectedRow.status === 'completed'( */}
                {selectedJobForApproval &&
                  selectedJobForApproval.status === "completed" && (
                    <div>
                      <div className="jobdnotes">
                        <h4>Job Notes</h4>
                        <p></p>
                      </div>
                      <div className="jobdnotes">
                        <h4 style={{ margin: "7px" }}>
                          Claim Extra Hours or Distance Traveled
                        </h4>
                        <p>Extra Amount: {job?.extraAmount}</p>
                        <p>Extra Hours: {job?.extraDistance}</p>
                      </div>
                      <br />
                    </div>
                  )}
                {selectedJobForApproval &&
                  selectedJobForApproval.status === "completed" &&
                  selectedJobForApproval.approvalStatus === "pending" && (
                    <div>
                      <div className="remarks">
                        <h4 style={{ margin: "7px" }}>Manager Remarks</h4>
                        <textarea style={{ width: "530px" }}></textarea>
                        <button
                          className="addTaskbtn"
                          style={{ width: "150px", alignItems: "end" }}
                        >
                          Add Remarks
                        </button>
                      </div>
                      <div>
                        <button
                          className="managerRegister-btn"
                          onClick={handleApprove}
                        >
                          Approve
                        </button>
                        <button
                          className="availability-btn"
                          style={{ backgroundColor: "red" }}
                          onClick={handleUnapprove}
                        >
                          Unapprove
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}
export default AssignedJob;
