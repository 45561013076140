// import axios from 'axios';
// import { BaseURL } from '../../constants/BaseURL';

import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";

// export const FETCH_CHAT_CONTENT_REQUEST = 'FETCH_CHAT_CONTENT_REQUEST';
// export const FETCH_CHAT_CONTENT_SUCCESS = 'FETCH_CHAT_CONTENT_SUCCESS';
// export const FETCH_CHAT_CONTENT_FAILURE = 'FETCH_CHAT_CONTENT_FAILURE';

// const fetchChatContentRequest = () => ({
//   type: FETCH_CHAT_CONTENT_REQUEST,
// });

// const fetchChatContentSuccess = (content) => ({
//   type: FETCH_CHAT_CONTENT_SUCCESS,
//   payload: content,
// });

// const fetchChatContentFailure = (error) => ({
//   type: FETCH_CHAT_CONTENT_FAILURE,
//   payload: error,
// });

// export const fetchChatContent = (chatId) => {
//   return (dispatch) => {
//     dispatch(fetchChatContentRequest());

//     const url = `${BaseURL}/message/${chatId}`;

//     axios.get(url)
//       .then((response) => {
//         dispatch(fetchChatContentSuccess(response.data.data));
//       })
//       .catch((error) => {
//         dispatch(fetchChatContentFailure(error.message));
//       });
//   };
// };

// actions.js

// Action Types
// Action Types
export const FETCH_MESSAGES_REQUEST = "FETCH_MESSAGES_REQUEST";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILURE = "FETCH_MESSAGES_FAILURE";

// Action Creators
export const fetchMessagesRequest = () => ({
  type: FETCH_MESSAGES_REQUEST,
});

export const fetchMessagesSuccess = (messages) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: messages,
});

export const fetchMessagesFailure = (error) => ({
  type: FETCH_MESSAGES_FAILURE,
  payload: error,
});

// Async Action Creator using Redux Thunk
export const fetchMessages = (chatId) => {
  return (dispatch) => {
    dispatch(fetchMessagesRequest());

    axios
      .get(`${BaseURL}/message/${chatId}`)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        dispatch(fetchMessagesSuccess(response.data.data));
        return response.data.data;
      })
      .catch((error) => {
        dispatch(fetchMessagesFailure(error.message));
        throw error;
      });
  };
};
