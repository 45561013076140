import { BaseURL } from "../constants/BaseURL";
import {
  CLEAR_INCIDENT_ERRORS,
  INCIDENT_FAIL,
  INCIDENT_REQUEST,
  INCIDENT_SUCCESS,
} from "../constants/incidentConstants";

export const fetchIncidentData = () => async (dispatch) => {
  dispatch({ type: INCIDENT_REQUEST });
  try {
    // Make the API request using the fetch function or Axios
    const token = localStorage.getItem("token");

    const response = await fetch(`${BaseURL}/manager/incident-history`, {
      headers: {
        "x-auth-token": token, // Replace with your actual auth token
      },
    });
    console.log("🚀 ~ fetchIncidentData ~ response:", response);
    const data = await response.json();
    dispatch({ type: INCIDENT_SUCCESS, payload: data.incident });
  } catch (error) {
    dispatch({ type: INCIDENT_FAIL, payload: error.message });
  }
};
export const clearIncidentErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_INCIDENT_ERRORS });
};
