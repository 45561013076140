import axios from "axios";
import {
  CLEAR_ERRORS,
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
} from "../constants/feedbackConstants";
import { BaseURL } from "../constants/BaseURL";

// Action creator for submitting feedback
// export const submitFeedback = (feedbackData) => {
//   return (dispatch) => {
//     dispatch({ type: SUBMIT_FEEDBACK_REQUEST });

//     const requestOptions = {
//       method: 'POST',
//       headers: new Headers({
//         'Content-Type': 'application/json',
//       }),
//       body: JSON.stringify(feedbackData),
//       redirect: 'follow',
//     };

//     fetch("http://3.25.109.243/api/v1/employee/feed-Back", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         dispatch({
//           type: SUBMIT_FEEDBACK_SUCCESS,
//           payload: result.feedback, // Assuming feedback is returned in the response
//         });
//       })
//       .catch(error => {
//         dispatch({
//           type: SUBMIT_FEEDBACK_FAILURE,
//           error: error,
//         });
//       });
//   };
// };

// export const fetchFeedback = async (dispatch) => {
//   try {
//     dispatch({ type: SUBMIT_FEEDBACK_REQUEST });
//     const { data } = await axios.post(`${BaseURL}/employee/feed-Back`, {
//       headers: {
//         "Content-Type": "application/json",
//       }
//     });
//     dispatch({
//       type: SUBMIT_FEEDBACK_SUCCESS,
//       payload: data.feedback // Store the token in the Redux state
//     });

//   } catch (error) {
//     console.log(error.response.data);
//     dispatch({
//       type: SUBMIT_FEEDBACK_FAILURE,
//        error: error,
//     })
//   }
// }
// export const clearErrors = () => async (dispatch) => {
//   dispatch({ type: CLEAR_ERRORS })
// }

// import axios from "axios";
// import { BaseURL } from "../constants/BaseURL";

export const fetchFeedback = () => async (dispatch) => {
  try {
    console.log("text");
    dispatch({ type: SUBMIT_FEEDBACK_REQUEST });
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${BaseURL}/manager/all-feedback`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });
    console.log("🚀 ~ fetchFeedback ~ data:", data);
    dispatch({
      type: SUBMIT_FEEDBACK_SUCCESS,
      // payload: {
      //     feedbackData: data.feedback // Make sure to extract the data field
      // }
      payload: data,
    });
    console.log(data);
  } catch (error) {
    dispatch({
      type: SUBMIT_FEEDBACK_FAILURE,
      payload: error.response.feedback,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
