// // import { SUBMIT_FEEDBACK_FAILURE, SUBMIT_FEEDBACK_REQUEST, SUBMIT_FEEDBACK_SUCCESS } from "../constants/feedbackConstants";

// import { CLEAR_ERRORS, SUBMIT_FEEDBACK_FAILURE, SUBMIT_FEEDBACK_REQUEST, SUBMIT_FEEDBACK_SUCCESS } from "../constants/feedbackConstants";
// import { DELETE_MANAGER_SUCCESS } from "../constants/managerConstants";

// // Define the initial state for the feedback
// const initialState = {
//     feedback: null, // Initialize as null
//     loading: false,
//     error: null,
//   };

//   // Define the feedback reducer
//   const feedbackReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case SUBMIT_FEEDBACK_REQUEST:
//         return {
//           ...state,
//           loading: true,
//           error: null,
//         };
//       case SUBMIT_FEEDBACK_SUCCESS:
//         return {
//           ...state,
//         //   feedback: action.payload, // Set the feedback data
//         // feedback: action.payload.feedbackData,
//         feedback: action.payload.feedbackData,
//           loading: false,
//           error: null,
//         };
//       case SUBMIT_FEEDBACK_FAILURE:
//         return {
//           ...state,
//           loading: false,
//           error: action.error,
//         };
//       default:
//         return state;
//     }
//   };

//   export default feedbackReducer;

import {
  CLEAR_ERRORS,
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
} from "../constants/feedbackConstants";
// import { CLEAR_ERRORS, JOB_FAIL, JOB_REQUEST, JOB_SUCCESS } from "../constants/jobConstants";

export const feedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_FEEDBACK_REQUEST:
      return {
        loading: true,
      };

    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        feedbackData: action.payload.feedback,
        status: action.payload.status,
      };
    case SUBMIT_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        // status: action.payload.status,
        errors: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };

    default:
      return state;
  }
};

// export const feedbackReducer = (state = {}, action) => {
//     // console.log(action.type)
//     switch (action.type) {
//         case SUBMIT_FEEDBACK_REQUEST:
//             return {
//                 loading: true,
//             };

//         case SUBMIT_FEEDBACK_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 feedbackData: action.payload,
//             };
//         case SUBMIT_FEEDBACK_FAILURE:
//             return {
//                 loading: false,
//                 errors: action.payload,
//             };

//         case DELETE_MANAGER_SUCCESS: // Handle the delete action
//             // Filter out the deleted company from the state
//             const updatedManagers = state.managers.filter(
//                 (manager) => manager._id !== action.payload.deletedManagerId
//             );

//             return {
//                 ...state,
//                 loading: false,
//                 managers: updatedManagers,
//             };

//         case CLEAR_ERRORS:
//             return {
//                 ...state,
//                 loading: false,
//                 message: null,
//                 status: null,
//                 errors: null,
//             };

//         default:
//             return state;
//     }
// }
