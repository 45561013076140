import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInvoices,
  updateInvoiceStatus,
} from "../../../../../redux/actions/getInvoiceActions";
import { DataGrid } from "@mui/x-data-grid";
import profileImage from "../../../../../assets/Icons/pngegg.png";
import "./invoice.css";
import divineIcon from "../../../../../assets/divine-logo.png";
import SpinerLoading from "../../../../Loading";
// import { updateInvoiceStatus } from '../../../../../redux/actions/updateActions/invoiceUpdateAction';

function Invoices() {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const dispatch = useDispatch();
  const { loading, invoices, error } = useSelector((state) => state.invoice);
  console.log(invoices);

  useEffect(() => {
    dispatch(fetchInvoices());
  }, [dispatch]);

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const invoice = invoices?.[0]; // Assuming invoices is the array containing this object

  const handleRowClick = (params) => {
    // Find the selected invoice based on the clicked row
    const selectedInvoiceData = invoices.find(
      (invoice) => invoice._id === params.id
    );
    setSelectedInvoice(selectedInvoiceData);
    // Open the popup
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    // Close the popup
    setIsPopupOpen(false);
  };

  // const handleStatusClick = (status, invoiceId) => {
  //   if (status === 'unpaid') {
  //     dispatch(updateInvoiceStatus(invoiceId, 'paid')); // Dispatch action to update status
  //   }
  // };
  const handleStatusClick = (status, invoiceId) => {
    if (status === "unpaid") {
      dispatch(updateInvoiceStatus(invoiceId, "paid"));
      // Reload the table after the invoice status is updated
      dispatch(fetchInvoices());
    }
  };
  const renderStatus = (params) => (
    <div
      style={{
        color: "#928C00",
        height: "30px",
        width: "90px",
        alignItems: "center",
        textAlign: "center",
        fontSize: "18px",
        borderRadius: "20%",
        fontWeight: "bold",
        cursor: "pointer",
        backgroundColor:
          params.row.status === "unpaid"
            ? "#FDFFB2"
            : params.row.status === "paid"
            ? "#DAFFBF"
            : "",
      }}
      onClick={() => handleStatusClick(params.row.status, params.row.id)}
    >
      {params.row.status}
    </div>
  );

  // const rows = invoices?.map((invoice) => ({
  //   id: invoice._id,
  //   name: invoice.employeeId.name,
  //   imageUrl: invoice.employeeId.imageUrl,
  //   email: invoice.employeeId.email,
  //   phone: invoice.employeeId.phone,
  //   totalPayment: invoice.totalPayment,
  //   currentworkinghours: invoice.currentworkinghours,
  //   status: invoice.status,
  // })) || [];
  const rows =
    invoices?.map((invoice) => ({
      id: invoice._id,
      name: invoice.employeeId ? invoice.employeeId.name : "N/A",
      imageUrl: invoice.employeeId ? invoice.employeeId.imageUrl : profileImage,
      email: invoice.employeeId ? invoice.employeeId.email : "N/A",
      phone: invoice.employeeId ? invoice.employeeId.phone : "N/A",
      totalPayment: invoice.totalPayment,
      currentworkinghours: invoice.currentworkinghours,
      status: invoice.status,
    })) || [];

  const columns = [
    // { field: 'id', headerName: 'ID', width: 80 },
    {
      field: "imageUrl",
      headerName: "Image",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value || profileImage}
          alt="Avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
    },
    { field: "name", headerName: "Name", width: 250 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "phone", headerName: "Phone", width: 250 },
    { field: "totalPayment", headerName: "Total Payment", width: 250 },
    {
      field: "currentworkinghours",
      headerName: "Current Working Hours",
      width: 250,
    },
    // { field: 'status', headerName: 'Status', width: 100 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 100,
    //   renderCell: (params) => (
    //     <div style={{ color: '#928C00', height: '30px', width: '90px', alignItems: 'center', textAlign: 'center', fontSize: '18px', borderRadius: '20%', fontWeight: 'bold', backgroundColor: params.row.status === 'unpaid' ? '#FDFFB2' : params.row.status === 'paid' ? '#DAFFBF' : '' }}>
    //       {params.row.status}
    //     </div>
    //   ),
    // },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      //       renderCell: (params) => (
      //         <div
      //           style={{
      //             color: '#928C00',
      //             height: '30px',
      //             width: '90px',
      //             alignItems: 'center',
      //             textAlign: 'center',
      //             fontSize: '18px',
      //             borderRadius: '20%',
      //             fontWeight: 'bold',
      //             backgroundColor: params.row.status === 'unpaid' ? '#FDFFB2' : params.row.status === 'paid' ? '#DAFFBF' : ''
      //           }}
      //           onClick={() => handleStatusClick(params.row.status, params.row.id)} // Pass invoice ID to handleStatusClick
      //         >
      //              renderCell: renderStatus
      //  {params.row.status}
      //         </div>
      //       ),
      renderCell: renderStatus,
    },
  ];

  const sortModel = [
    {
      field: "createdAt",
      sort: "desc",
    },
  ];

  const initialState = {
    pagination: {
      pageSize: 10,
    },
    sortModel,
  };

  if (loading) {
    return <SpinerLoading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="mainManagerFeedbackContainer">
      <div className="" style={{ height: "56vh", width: "80vw" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onRowClick={handleRowClick}
          initialState={initialState}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
      {isPopupOpen && selectedInvoice && (
        <div className="popupManagerDetail">
          <div className="popup-header">
            <h2>Invoice Details</h2>
            <h2
              onClick={() => setSelectedInvoice(null)}
              style={{
                cursor: "pointer",
                backgroundColor: "red",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              X
            </h2>
          </div>
          <section className="incoice_section">
            <div className="invoice_Container">
              <div className="top">
                <div
                  style={{
                    width: "100%",
                    height: "75%",
                    position: "relative",
                    background: "#71B340",
                    boxShadow: "0px 2px 19px rgba(47.78, 94.96, 150.87, 0.07)",
                    borderRadius: 24,
                    border: "0.60px #EBEFF6 solid",
                  }}
                >
                  <div style={{ left: 20, top: 46, position: "absolute" }} />
                  <img
                    style={{
                      width: 112,
                      height: 80,
                      left: 7,
                      top: 32,
                      position: "absolute",
                      background: "white",
                      boxShadow:
                        "0px 2px 19px rgba(47.78, 94.96, 150.87, 0.07)",
                      borderRadius: 24,
                      zIndex: 200,
                      border: "0.60px #EBEFF6 solid",
                    }}
                    src={divineIcon}
                  />
                </div>
              </div>
              <div className="invoice_profile">
                <div className="profile_col_1">
                  <ul>
                    <li>
                      <img />
                      <h5>Pays To:</h5>
                    </li>
                    <li>
                      <img
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50px",
                        }}
                        src={
                          invoice.employeeId && invoice.employeeId.imageUrl
                            ? invoice.employeeId.imageUrl
                            : { profileImage }
                        }
                      />
                      <h5>
                        {invoice.employeeId && invoice.employeeId.name
                          ? invoice.employeeId.name
                          : "N/A"}
                      </h5>
                    </li>
                    {/* <li>
                      <span>Employee Id: </span>
                      <p> {invoice.employeeId && invoice.employeeId._id ? invoice.employeeId._id : 'N/A'}</p>
                    </li> */}
                    <li>
                      <span>Phone No. </span>&nbsp;
                      <p>
                        {" "}
                        {invoice.employeeId && invoice.employeeId.phone
                          ? invoice.employeeId.phone
                          : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Email</span>&nbsp;
                      <p>
                        {" "}
                        {invoice.employeeId && invoice.employeeId.email
                          ? invoice.employeeId.email
                          : "N/A"}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="peofile_col_1">
                  <ul>
                    <li>
                      <img />
                      <h5>Date:</h5>
                    </li>
                    <li>
                      <span>From: </span>&nbsp;&nbsp;
                      <p>
                        {invoice.fromDate
                          ? formatDate(invoice.fromDate)
                          : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>To:</span>&nbsp;&nbsp;
                      <p>
                        {invoice.toDate ? formatDate(invoice.toDate) : "N/A"}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="invoice_detail">
                <div style={{ marginLeft: "10px" }}>
                  <legend style={{ fontWeight: "bold" }}>Details</legend>
                  <li>
                    <span>Gross Pay: </span>&nbsp;
                    <p>{invoice.grosspay ? invoice.grosspay : "N/A"}</p>
                  </li>
                  <li>
                    <span>Net Pay: </span>&nbsp;
                    <p>{invoice.netpay ? invoice.netpay : "N/A"}</p>
                  </li>
                </div>
                <fieldset>
                  <legend style={{ fontWeight: "bold" }}>Amount Details</legend>
                  <ul>
                    <li>
                      <span>Extra Amount</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.extraAmount ? invoice.extraAmount : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Total Amount</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.totalPayment ? invoice.totalPayment : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Allowances</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.allowances ? invoice.allowances : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Gross Pay</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.grosspay ? invoice.grosspay : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Tax Free Threshold</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.TaxFreeTrashHold
                          ? invoice.TaxFreeTrashHold
                          : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Fortnightly Tax</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.FortnightlyTax
                          ? invoice.FortnightlyTax
                          : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Medical Levy</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.medicalLeaveyTax
                          ? invoice.medicalLeaveyTax
                          : "N/A"}
                      </p>
                    </li>
                    <li>
                      <span>Net Pay</span>&nbsp;
                      <p style={{ marginLeft: "20px" }}>
                        {invoice.netpay ? invoice.netpay : "N/A"}
                      </p>
                    </li>
                  </ul>
                </fieldset>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default Invoices;
