import axios from "axios";
import {
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from "../constants/authConstants";
import { BaseURL } from "../constants/BaseURL";

export const LoginAction = (email, password, role) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    // console.log(email);
    const { data } = await axios.post(`${BaseURL}/compnay/login`, {
      email,
      password,
      role,
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data, // Store the token in the Redux state
    });
  } catch (error) {
    console.log(error.response.data);
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
