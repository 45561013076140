// acceptLeaveAction.js
import axios from "axios";
import { BaseURL } from "../constants/BaseURL";
import { ACCEPT_LEAVE_FAIL, ACCEPT_LEAVE_REQUEST, ACCEPT_LEAVE_SUCCESS } from "../constants/LeaveRequestConstants";
import { toast } from "react-toastify";

export const acceptLeaveAction = (requestId) => async (dispatch) => {
  try {
    dispatch({ type: ACCEPT_LEAVE_REQUEST });
    const token = localStorage.getItem('token');
    const response = await axios.put(`${BaseURL}/manager/leave-request/${requestId}`, { status: true }, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    if (response.status === 200) {
        dispatch({
            type: ACCEPT_LEAVE_SUCCESS,
            payload: response.data,
          });
        toast.success('Leave Request Accepted!', { theme: 'colored' });
      // Reload the page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 3000); // 4000 milliseconds (4 seconds)
      } 
  } catch (error) {
    dispatch({
      type: ACCEPT_LEAVE_FAIL,
      payload: error.response.data,
    });
    toast.error('An error occurred while deleting leave request.', { theme: 'colored' });
  }
};
