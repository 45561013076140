export const LEAVE_REQUEST = "LEAVE_REQUEST";
export const LEAVE_SUCCESS = "LEAVE_SUCCESS";
export const LEAVE_FAIL = "LEAVE_FAIL";

export const ACCEPT_LEAVE_FAIL = "ACCEPT_LEAVE_FAIL"
export const ACCEPT_LEAVE_REQUEST = "ACCEPT_LEAVE_REQUEST"
export const ACCEPT_LEAVE_SUCCESS = "ACCEPT_LEAVE_SUCCESS"

export const DELETE_LEAVE_REQUEST = "DELETE_LEAVE_REQUEST"
export const DELETE_LEAVE_SUCCESS = "DELETE_LEAVE_SUCCESS"
export const DELETE_LEAVE_FAIL = "DELETE_LEAVE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS";