import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";
import {
  POST_CLIENT_FAILURE,
  POST_CLIENT_REQUEST,
  POST_CLIENT_SUCCESS,
} from "../../constants/clientConstants";
import { toast } from "react-toastify";

export const postClient = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: POST_CLIENT_REQUEST });
    const token = localStorage.getItem("token");

    const response = await axios.post(
      `${BaseURL}/manager/register-client`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          "x-auth-token": token,
        },
      }
    );
    console.log("🚀 ~ postClient ~ data:", response);

    if (response.status === 201) {
      toast.success("Client Created Successfully");

      dispatch({ type: POST_CLIENT_SUCCESS, payload: response.data });
      navigate("/home");
    }
  } catch (error) {
    dispatch({
      type: POST_CLIENT_FAILURE,
      error,
    });
    toast.error("Failed to create Client. Please try again.");
  }
};
