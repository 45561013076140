import axios from "axios";
import {
  APPROVE_JOB_REQUEST_SUCCESS,
  GET_JOBS_SUCCESS,
  REJECT_JOB_REQUEST_SUCCESS,
} from "../constants/jobConstants";
import { BaseURL } from "../constants/BaseURL";

const approveJobRequestSuccess = (jobId) => ({
  type: APPROVE_JOB_REQUEST_SUCCESS,
  payload: { jobId },
});

const rejectJobRequestSuccess = (jobId) => ({
  type: REJECT_JOB_REQUEST_SUCCESS,
  payload: { jobId },
});

const getJobsSuccess = (jobs) => ({
  type: GET_JOBS_SUCCESS,
  payload: { jobs },
});

export const approveJobRequest = (jobId) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");

    await axios.put(
      `${BaseURL}/jobs/${jobId}/job-approvel`,
      { action: "approved" },
      {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      }
    );

    // Dispatch success action
    dispatch(approveJobRequestSuccess(jobId));

    // Fetch updated jobs after approval
    dispatch(fetchJobs()); // Assuming you have a fetchJobs action
  } catch (error) {
    console.error("Error approving job request:", error);
  }
};

export const rejectJobRequest = (jobId) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");

    await axios.put(
      `${BaseURL}/jobs/${jobId}/job-approvel`,
      { action: "unapprove" },
      {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      }
    );

    // Dispatch success action
    dispatch(rejectJobRequestSuccess(jobId));

    // Fetch updated jobs after rejection
    dispatch(fetchJobs()); // Assuming you have a fetchJobs action
  } catch (error) {
    console.error("Error rejecting job request:", error);
  }
};

export const fetchJobs = () => async (dispatch) => {
  try {
    // Make API request to get the updated list of jobs
    const response = await axios.get("YOUR_API_ENDPOINT_TO_GET_JOBS", {
      headers: {
        "x-auth-token": "YOUR_AUTH_TOKEN",
      },
    });

    // Dispatch success action with the updated jobs
    dispatch(getJobsSuccess(response.data));
  } catch (error) {
    console.error("Error fetching jobs:", error);
  }
};
