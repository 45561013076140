import React from 'react';
import SideBar from '../../SideBar';
import '../Availability/Availability.css';
import InvoiceRightBar from '../RightBars/InvoiceBars/InvoiceRightBar';

function Invoice(props) {
    return(
        <div className='mainAvailabilityContainer'>
            <SideBar/>
            <InvoiceRightBar/>
        </div>
    )
}
export default Invoice;