// import { FETCH_CHAT_CONTENT_FAILURE, FETCH_CHAT_CONTENT_REQUEST, FETCH_CHAT_CONTENT_SUCCESS } from "../../actions/chatActions/getMessageActions";

import { FETCH_MESSAGES_FAILURE, FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS } from "../../actions/chatActions/getMessageActions";

// const initialState = {
//   selectedChatContent: null,
//   contentLoading: false,
//   contentError: null,
// };

// const getMessageReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_CHAT_CONTENT_REQUEST:
//       return {
//         ...state,
//         contentLoading: true,
//         contentError: null,
//       };
//     case FETCH_CHAT_CONTENT_SUCCESS:
//       return {
//         ...state,
//         contentLoading: false,
//         selectedChatContent: action.payload,
//       };
//     case FETCH_CHAT_CONTENT_FAILURE:
//       return {
//         ...state,
//         contentLoading: false,
//         contentError: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default getMessageReducer;


// reducer.js
  
  // Initial State
  const initialState = {
    messages: [],
    loading: false,
    error: null,
  };
  
  // Reducer
  const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MESSAGES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FETCH_MESSAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          messages: action.payload,
          error: null,
        };
  
      case FETCH_MESSAGES_FAILURE:
        return {
          ...state,
          loading: false,
          messages: [],
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default messagesReducer;
  