import {
  CLIENT_REQUEST,
  CLIENT_SUCCESS,
  CLIENT_FAIL,
  CLEAR_CLIENT_ERRORS,
  DELETE_CLIENT_SUCCESS,
  POST_CLIENT_SUCCESS,
  POST_CLIENT_FAILURE,
  POST_CLIENT_REQUEST,
} from "../constants/clientConstants";

const initialState = {
  loading: false,
  clients: [],
  errors: null,
};

export const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.payload.clientData,
      };

    case CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case POST_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload.clientData,
        status: action.payload.status,
        error: null,
      };
    case POST_CLIENT_FAILURE:
      return {
        ...state,
        client: null,
        error: action.error,
      };
    case DELETE_CLIENT_SUCCESS: // Handle the delete action
      // Filter out the deleted company from the state
      const updatedCleints = state.clients.filter(
        (client) => client._id !== action.payload.deletedClientId
      );

      return {
        ...state,
        loading: false,
        clients: updatedCleints,
      };

    case CLEAR_CLIENT_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    default:
      return state;
  }
};
