import React from 'react';
import './Availability.css';
import SideBar from '../../SideBar';
import AvailabilityRightBar from '../RightBars/AvailabilityRightBar/AvailabilityRightBar';

function Availability(props) {
    return(
        <div className='mainAvailabilityContainer'>
            <SideBar/>
            <AvailabilityRightBar/>
        </div>
    )
}
export default Availability;