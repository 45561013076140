import React, { useEffect, useState } from 'react';
import './Chat.css';
import SideBar from '../../SideBar';
import { ConnectionState } from './connections/ConnectionState';
import { socket } from '../../../../socket';
import { Events } from './connections/Events';
import { ConnectionManager } from './connections/ConnectionManager';
import Input from '../RightBars/ChatBars/Input';
import axios from 'axios';
import { BaseURL } from '../../../../redux/constants/BaseURL';
import Messages from '../RightBars/ChatBars/Messages';
import { fetchChats } from '../../../../redux/actions/chatActions/chatActions';
import { useDispatch, useSelector } from 'react-redux';

// import Messages from './Messages'; // Import the Messages component
// import React, { useEffect, useState } from 'react';
// import './Chat.css';
// import SideBar from '../../SideBar';
// import { ConnectionState } from './connections/ConnectionState';
// import { socket } from '../../../../socket';
// import { Events } from './connections/Events';
// import { ConnectionManager } from './connections/ConnectionManager';
// import Input from '../RightBars/ChatBars/Input';
// import axios from 'axios';  
// import { BaseURL } from '../../../../redux/constants/BaseURL';
// import ChatBar from '../RightBars/ChatApp/ChatBar'
// import ChatRightBar from '../RightBars/ChatBars/ChatRightBar';
// import { MyForm } from './connections/MyForm';


  // const [isChatOpen, setIsChatOpen] = useState(false);

  // const toggleChat = () => {
  //   setIsChatOpen((prevIsChatOpen) => !prevIsChatOpen);
  // };

  // useEffect(() => {
  //   // Connect or disconnect the socket based on chat open/close state
  //   if (isChatOpen) {
  //     socket.connect();
  //   } else {
  //     socket.disconnect();
  //   }
  // }, [isChatOpen]);
  
  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [fooEvents, setFooEvents] = useState([]);
  
  // useEffect(() => {
    //   function onConnect() {
      //     setIsConnected(true);
      //   }
      
      //   function onDisconnect() {
        //     setIsConnected(false);
        //   }
        
        //   function onFooEvent(value) {
          //     setFooEvents(previous => [...previous, value]);
          //   }
          
          //   socket.on('connect', onConnect);
          //   socket.on('disconnect', onDisconnect);
          
          //   return () => {
            //     socket.off('connect', onConnect);
            //     socket.off('disconnect', onDisconnect);
            //     socket.off('foo', onFooEvent);
            //   };
            // }, []);
            
            
            
            //working
            // import React, { useEffect, useState } from 'react'
            // import './Chat.css';
            // import SideBar from '../../SideBar';
            // import { ConnectionState } from './connections/ConnectionState';
            // import { socket } from '../../../../socket';
            // import { Events } from './connections/Events';
            // import { ConnectionManager } from './connections/ConnectionManager';
            // function Chat(props) {
            // const [isConnected, setIsConnected] = useState(socket.connected);
            // const [fooEvents, setFooEvents] = useState([]);
            // const [isChatOpen, setIsChatOpen] = useState(true); // Assuming it's initially open
            
            // useEffect(() => {
              //   function onConnect() {
                //     setIsConnected(true);
                //   }
                
                //   function onDisconnect() {
                  //     setIsConnected(false);
                  //   }
                  
                  //   function onFooEvent(value) {
                    //     setFooEvents(previous => [...previous, value]);
                    //   }
                    
                    //   socket.on('connect', onConnect);
                    //   socket.on('disconnect', onDisconnect);
                    
                    //   return () => {
                      //     socket.off('connect', onConnect);
                      //     socket.off('disconnect', onDisconnect);
                      //     socket.off('foo', onFooEvent);
                      //   };
                      // }, []);
                      
                      // useEffect(() => {
                        //   console.log('isChatOpen changed:', isChatOpen);
                        // }, [isChatOpen]);
                        
                        // return (
                          //   <div className='mainChatContainer'>
                          //     <SideBar setIsChatOpen={setIsChatOpen} />
                          //     <ConnectionState isConnected={isConnected} />
                          //     <Events events={fooEvents} />
                          //     <ConnectionManager isChatOpen={isChatOpen} />
                          {/* <MyForm /> */}
                          {/* <ConnectionState />
                          <ConnectionManager />
                        <MyForm /> */}
                        {/* <ChatBar/> */}
                        {/* <ChatRightBar/> */}
                        // </div>
                        // )
                      // }
                      // export default Chat;



                      // Chat.js

// Chat.js

function Chat() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]); // State to store messages
  
  const user = useSelector((state) => state.userProfile.user);
  const userId = user ? user._id : null;
  
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        dispatch(fetchChats(userId));
      }
    };

    fetchData();
  }, [dispatch, userId]);

  useEffect(() => {
    async function createAndJoinChat() {
      try {
        const response = await axios.post(`${BaseURL}/chat/findAndCreateChat`, {
          senderId: userId,
          receiverId: '65a75f34592095c47c33a4bb',
          jobId: '6569ca2835e1fc4db9e39f1a',
        });

        setChatId(response.data.data._id);
      } catch (error) {
        console.error('Error creating and joining chat:', error);
      }
    }

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onReceiveMessage(message) {
      console.log('Received message:', message);
      setMessages((prevMessages) => [...prevMessages, message]);
    }
    

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('receive-message', onReceiveMessage);

    createAndJoinChat();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('receive-message', onReceiveMessage);
    };
  }, [messages]); // Dependency array is empty to run the effect only once

  useEffect(() => {
    console.log('isChatOpen changed:', isChatOpen);
  }, [isChatOpen]);

  const renderMessages = () => {
    return messages.map((message, index) => (
      <div key={index}>
        <p>{message.senderId === userId ? 'You: ' : 'Other user: '}{message.content}</p>
      </div>
    ));
  };

  return (
    <div className='mainChatContainer'>
      <SideBar setIsChatOpen={setIsChatOpen} />
      <ConnectionState isConnected={isConnected} />
      <Events events={fooEvents} />
      <ConnectionManager isChatOpen={isChatOpen} />
      {/* <div className="messageContainer">
        {renderMessages()}
      </div> */}
    </div>
  );
}

export default Chat;
