import axios from "axios";
import {
  CLIENT_REQUEST,
  CLIENT_SUCCESS,
  CLIENT_FAIL,
  CLEAR_CLIENT_ERRORS,
} from "../constants/clientConstants";
import { BaseURL } from "../constants/BaseURL";

export const GetClientsInfoAction = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_REQUEST });
    const token = localStorage.getItem("token");
    // const { data } = await axios.get(`${BaseURL}/client`, {
    const { data } = await axios.get(`${BaseURL}/client/`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });
    dispatch({
      type: CLIENT_SUCCESS,
      payload: {
        clientData: data.data,
      },
    });
    console.log("🚀 ~ GetClientsAction ~ data:", data);
  } catch (error) {
    dispatch({
      type: CLIENT_FAIL,
      payload: error.response.data,
    });
  }
};

export const clearClientErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CLIENT_ERRORS });
};
