import axios from "axios";
import {
  FETCH_MANAGER_DETAILS,
  FETCH_MANAGER_DETAILS_FAILURE,
  FETCH_MANAGER_DETAILS_SUCCESS,
} from "../constants/ManagerDetailContants";
import { BaseURL } from "../constants/BaseURL";

export const fetchManagerDetails = () => async (dispatch) => {
  try {
    // dispatch({ type: FETCH_MANAGER_DETAILS });
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${BaseURL}/manager/all`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });
    console.log("🚀 ~ fetchManagerDetails ~ data:", data);

    dispatch({
      type: FETCH_MANAGER_DETAILS_SUCCESS,
      payload: data,
    });
    // console.log("🚀 ~ fetchManagerDetails ~ data:", data);
  } catch (error) {
    // Handle the error, for example, dispatch an error action
    dispatch({
      type: FETCH_MANAGER_DETAILS_FAILURE,
      payload: error.message, // You can customize the payload with the error details
    });
  }
};
