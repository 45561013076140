import { React, useEffect } from "react";
import "./NavBar.css";
import searchIcon from "../../assets/Icons/searchIcon.png";
import adminProfileIcon from "../../assets/divine-logo.png";
import logo from "../../assets/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile } from "../../redux//actions/getUserProfileAction";

function NavBar(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfile.user);
  // console.log("🚀 ~ NavBar ~ user:", user);
  const role = localStorage.getItem("role");
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  return (
    <div>
      <div className="mainNavBarContainer">
        <div className="profileItem">
          <img src={user?.imageUrl} alt="" className="adminProfileIcon" />

          <div className="profileName">
            <h4>Account Details</h4>
            <span className="info-label">
              Name:
              <p className="profileName">
                {user?.companyName && user.companyName}
                {user?.companyName && user?.name && " || "}
                {user?.name && user.name}
              </p>
            </span>
            <span className="info-label">
              Code:
              <p className="profileName">{user?.companyCode}</p>
            </span>
            <span className="info-label">
              Role:
              <p className="profileName">
                {" "}
                {role === "Owner" ? "Owner" : "Manager"}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NavBar;
