import {
  FETCH_MANAGER_DETAILS,
  FETCH_MANAGER_DETAILS_FAILURE,
  FETCH_MANAGER_DETAILS_SUCCESS,
} from "../constants/ManagerDetailContants";

const initialState = {
  companyDetails: null,
  loading: false,
  error: null,
};

// Create the reducer function
export const ManagerDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MANAGER_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MANAGER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ManagerDetails: action.payload.data, // You may need to adjust the payload structure
        error: null,
      };

    case FETCH_MANAGER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        ManagerDetails: null,
        error: action.payload, // Store the error message or details in the state
      };
    default:
      return state;
  }
};

export default ManagerDetailReducer;
