import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Messages from "./Messages";
import { fetchMessages } from "../../../../../redux/actions/chatActions/getMessageActions";
import { socket } from "../../../../../socket";
import GroupInput from "./GroupInut";
import { RxAvatar } from "react-icons/rx";

function GroupChat({ selectedChat }) {
  const dispatch = useDispatch();
  console.log("🚀 ~ Chat ~ selectedChat:", selectedChat);

  const [messages, setMessages] = useState([]);
  const senderId =
    selectedChat && Array.isArray(selectedChat.memberDetails)
      ? selectedChat.memberDetails[0]._id
      : null;
  console.log("🚀 ~ GroupChat ~ MyId:", senderId);

  // console.log(senderId);
  // console.log(reciverId);

  useEffect(() => {
    if (selectedChat) {
      dispatch(fetchMessages(selectedChat.chatId));
    }
  }, [dispatch, selectedChat]);

  //   if (selectedChat) {
  //     dispatch(fetchMessages(selectedChat.chatId));
  //     // Connect to socket
  //     socket.connect();

  //     // Listen for incoming messages
  //     socket.on("receive-message", (newMessage) => {
  //       if (newMessage.chatId === selectedChat?.chatId) {
  //         setMessages((prevMessages) => [...prevMessages, newMessage]);
  //       }
  //     });

  //     // Clean up
  //     return () => {
  //       socket.off("receive-message");
  //       socket.disconnect();
  //     };
  //   }
  // }, [dispatch, selectedChat]);

  const addMessage = (newMessage) => {
    if (!newMessage || !newMessage.content) {
      console.error("Invalid message:", newMessage);
      return;
    }

    setMessages((prevMessages) => {
      if (!Array.isArray(prevMessages)) {
        return [newMessage];
      }
      return [...prevMessages, newMessage];
    });
  };

  return (
    <div className="chat">
      <div className="chatHeader">
        <div className="chatInfo">
          <span>
            {selectedChat ? selectedChat.groupName : "No Chat Selected"}
          </span>
          <div className="chatIcons"></div>
        </div>
        {selectedChat?.imageUrl ? (
          <img src={selectedChat.imageUrl} alt="Selected Chat Avatar" />
        ) : (
          <RxAvatar
            style={{
              borderRadius: "500px",
              background: "#bdc3d5",
              color: "#ffffff",
              width: "45px",
              height: "45px",
            }}
          />
        )}
      </div>
      <Messages messages={messages} />
      <GroupInput
        senderId={senderId}
        addMessage={addMessage}
        chatId={selectedChat ? selectedChat.chatId : null}
      />
    </div>
  );
}

export default GroupChat;
