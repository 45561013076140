import React, { useEffect, useState } from "react";
import "./FeedbackRightBar.css";
import NavBar from "../../../NavBar";
import ClientFeedback from "./ClientFeedback";
import ManagerFeedback from "./ManagerFeedback";
import { DataGrid } from "@mui/x-data-grid";
// import addnewIcon from '../../../../../assets/Icons/addnewIcon.png';
import updateIcon from "../../../../../assets/Icons/updateIcon.png";
import deleteIcon from "../../../../../assets/Icons/deleteIcon.png";
// import employeeFeedbackIcon from '../../../../../assets/Icons/employeeFeedbackIcon.png';
// import managerFeedbackIcon from '../../../../../assets/Icons/managerFeedbackIcon.png';
import { connect, useDispatch, useSelector } from "react-redux";
// import fetchFeedback from '../../../../../redux/actions/feedbackAction';
import { fetchFeedback } from "../../../../../redux/actions/feedbackAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import Nodata from "../../../../../assets/Nodata.png";

function formatDateTime(dateTimeString, formatType) {
  const dateTime = new Date(dateTimeString);
  if (formatType === "time") {
    return dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }
  if (formatType === "date") {
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }
  return "";
}

function FeedbackRightBar(props) {
  const dispatch = useDispatch();
  const { loading, feedbackData } = useSelector((state) => state.feedback);
  const [title, setTitle] = useState("Employee Feedback");
  const [feedbackState, setFeedbackState] = useState("FeedbackRightBar");

  const [selectedRow, setSelectedRow] = useState(null);
  const [feedback, setFeedback] = useState(null);

  const handleRowClick = (params) => {
    setSelectedRow(params.row.id); // Store the ID of the clicked row in state
    setFeedback(
      feedbackData?.filter((feedback) => feedback._id === params.row.id)
    );
  };
  console.log(feedback);

  const handleEdit = (id) => {
    // Handle edit action here
    console.log(`Edit row with ID: ${id}`);
  };

  const handleDelete = (id) => {
    // Handle delete action here
    console.log(`Delete row with ID: ${id}`);
  };

  useEffect(() => {
    dispatch(fetchFeedback()); // Dispatch the action to fetch feedback data
  }, [dispatch]);

  const rows = feedbackData
    ? feedbackData?.map((feedback) => ({
        id: feedback._id,
        imageUrl: feedback.imageUrl,
        name: feedback.name,
        email: feedback.email,
        phone: feedback.phone,
        createdAt: feedback.createdAt,
        additionalFeedback: feedback.additionalFeedback,
      }))
    : [];
  console.log(feedbackData);

  const columns = [
    {
      field: "imageUrl",
      headerName: "Image",
      width: 120,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
    },
    { field: "name", headerName: "Name", width: 220 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "phone", headerName: "Phone", width: 170 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      valueFormatter: (params) => formatDateTime(params.value, "date"),
    },

    { field: "additionalFeedback", headerName: "Feedback", width: 200 },
  ];

  const sortModel = [
    {
      field: "createdAt", // Replace 'date' with the actual field name you want to sort by
      sort: "desc", // 'asc' for ascending, 'desc' for descending
    },
  ];

  const initialState = {
    pagination: {
      pageSize: 10,
    },
    sortModel,
  };

  return (
    <div className="mainFeedbackRightBarContainer">
      <NavBar />
      <div className="itemContainer">
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Feedback</h1>
          </div>
        </div>
        <div className="feedbackItemContainer">
          <div className="" style={{ height: "56vh", width: "80vw" }}>
            {feedbackData && feedbackData.length > 0 ? ( // Check if feedbackData exists and is not empty
              <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={handleRowClick}
                sortModel={sortModel}
                initialState={initialState}
                checkboxSelection
              />
            ) : (
              <div className="NodataAvaible">
                <img className="NodataAvaible-img" src={Nodata} />
                No feedback data available
              </div>
            )}
          </div>
        </div>
        {selectedRow !== null && feedback && feedback.length > 0 && (
          <div className="popupManagerDetail">
            <div className="popup-header">
              <h2>Feedback</h2>
              <h2
                onClick={() => setSelectedRow(null)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex", // Add this to center content vertically and horizontally
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                  color: "white",
                  fontWeight: "bold", // Make the text bold
                }}
              >
                X
              </h2>
            </div>
            <section>
              <div className="info-col-1">
                <div className="incidentcontainer-2">
                  <div>
                    <h1> Details</h1>
                    <br />
                    <ul>
                      <li>
                        <h5> Report Id:</h5>
                        <span className="detail-span">
                          {feedback[0]?._id ? feedback[0]._id.slice(0, 6) : ""}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5> Employee Name:</h5>
                        <span className="detail-span">{feedback[0]?.name}</span>
                      </li>
                      <br />
                      {/* <li>
                                                <h5> Job Id:</h5>
                                                <span className="detail-span">{feedback[0]?.jobId}</span>
                                            </li> */}
                      {/* <li>
                                                <h5> Incident Type:</h5>
                                                <span className="detail-span">{feedback[0]?.type}</span>
                                            </li> */}
                      <li>
                        <h5> Email:</h5>
                        <span className="detail-span">
                          {feedback[0]?.email}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5>Contact: </h5>
                        <span className="detail-span">
                          {feedback[0]?.phone}
                        </span>
                      </li>
                      <br />
                      {/* <li>
                                                <h5>Address: </h5>
                                                <span className="detail-span">{feedback[0]?.additionalFeedback}</span>
                                            </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-col-2">
                <h1>Feedback</h1>
                <br />
                <p>{feedback[0]?.additionalFeedback}</p>
                {/* <div className="document">
                                    <h1>Documents</h1>
                                    <div>
                                        <button onClick={handleDownload} style={{background:'white', border:'white',color:'green', display:'flex', fontSize:'16px'}}>
                                            <FontAwesomeIcon icon={faCloudDownload} /> <p style={{color:'black', fontSize:'16px'}}>&nbsp;&nbsp;&nbsp;Incident Document</p>
                                        </button>
                                    </div>
                                </div> */}
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}
export default FeedbackRightBar;
