import React from "react";
import "./ChatBars.css";
import NavBar from "../../../NavBar";
import ChatSideBar from "./ChatSideBar";
import ChatNavBar from "./ChatNavBar";
import ChatSearch from "./ChatSearch";
import Chat from "./Chat";

function ChatRightBar(props) {
  return (
    <div className="mainChatRightBarContainer">
      <NavBar />
      <div className="itemContainer">
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Chat</h1>
          </div>
        </div>
        <div className="chathomeContainer">
          {/* <ChatNavBar/> */}
          <ChatSideBar />
          {/* <Chat /> */}
          <div className=""></div>
        </div>
      </div>
    </div>
  );
}
export default ChatRightBar;
