// In jobActionReducer.js
import {
  APPROVE_JOB_REQUEST_SUCCESS,
  REJECT_JOB_REQUEST_SUCCESS,
  GET_REQUESTED_JOB_SUCCESS,
} from "../constants/jobConstants";

// Initial state
const initialState = {
  loading: false,
  requested: [],
};

// Reducer function
const jobActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPROVE_JOB_REQUEST_SUCCESS:
    case REJECT_JOB_REQUEST_SUCCESS:
      // Update the state after approval or rejection
      const updatedRequested = state.requested.filter(
        (request) => request._id !== action.payload.requestId
      );
      return {
        ...state,
        requested: updatedRequested,
      };

    case GET_REQUESTED_JOB_SUCCESS:
      // Update the state with the fetched requested jobs
      return {
        ...state,
        requested: action.payload.requested,
      };

    // Handle other actions if needed

    default:
      return state;
  }
};

export default jobActionReducer;
