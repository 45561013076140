import React from 'react';
import './Manager.css';
import SideBar from '../../SideBar';
import ManagerRightBar from '../RightBars/ManagerRightBar';
function Manager(props) {
    return(
        <div className='mainManagerContainer'>
            <SideBar/>
            <ManagerRightBar/>
        </div>
    )
}
export default Manager;