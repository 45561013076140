import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
// import {io} from 'socket.io-client'

import LoginPage from './Components/LoginPage/LoginPage';
import Home from './Components/Dashboard/Pages/Home/Home';
import Manager from './Components/Dashboard/Pages/Manager/Manager';
import Client from './Components/Dashboard/Pages/Client/Client';
import Employee from './Components/Dashboard/Pages/Employee/Employee';
import Availability from './Components/Dashboard/Pages/Availability/Availability';
import IncidentReport from './Components/Dashboard/Pages/IncidentReport/IncidentReport';
import Chat from './Components/Dashboard/Pages/Chat/Chat';
import Job from './Components/Dashboard/Pages/Job/Job';
import Feedback from './Components/Dashboard/Pages/Feedback/Feedback';
import LeaveRequest from './Components/Dashboard/Pages/LeaveRequest/LeaveRequest';
import Owner from './Middleware/Owner';
import Managers from './Middleware/Managers';
import PrivateComponent from './Middleware/PrivateComponent';
import SetAvailability from './Components/Dashboard/Pages/RightBars/AvailabilityRightBar/SetAvailability';
import Invoice from './Components/Dashboard/Pages/Invoice/Invoice';
import Roster from './Components/Dashboard/Pages/Roster/Roster';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<PrivateComponent />}>
        <Route path="/home" element={<Home />} />
        <Route path='/Manager' element={<Owner children={<Manager />} />} />
        <Route path="/Employee" element={<Employee />} />
        <Route path="/Client" element={<Client />} />
        <Route path='/Availability' element={<Managers children={<Availability />} />} />
        <Route path='/setAvailability' element={<SetAvailability />} />
        <Route path="/IncidentReport" element={<IncidentReport />} />
        <Route path='/Invoice' element={<Invoice/>}/>
        <Route path='/Roster' element={<Roster/>}/>
        <Route path="/Chat" element={<Chat />} />
        <Route path="/Job" element={<Job />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/LeaveRequest" element={<Managers children={<LeaveRequest />} />} />
      </Route>
      <Route path="/LoginPage" element={<LoginPage />} />
    </Routes>
  );
}

export default App;
