import React from 'react';
import './Home.css';
import SideBar from '../../SideBar';
import HomeRightBar from '../RightBars/HomeRightBar';
function Home(props) {
    return(
        <div className='mainHomeContainer'>
        <SideBar/>
        <HomeRightBar/>
        </div>
    )
}
export default Home;