import {
  CLEAR_ERRORS,
  DELETE_EMPLOYEE_SUCCESS,
  EMPLOYEE_FAIL,
  EMPLOYEE_REQUEST,
  EMPLOYEE_SUCCESS,
  POST_EMPLOYEE_FAILURE,
  POST_EMPLOYEE_SUCCESS,
} from "../constants/EmployeeConstants";

const initialState = {
  loading: false,
  employees: [],
  createdEmployee: null,
  employeeId: null, // Add a field to store the employee ID
  errors: null,
};
export const employeeReducer = (state = initialState, action) => {
  // console.log(action.type)
  switch (action.type) {
    case EMPLOYEE_REQUEST:
      return {
        loading: true,
      };

    case EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload.employeeData,
      };
    case EMPLOYEE_FAIL:
      return {
        loading: false,
        errors: action.payload,
      };
    case POST_EMPLOYEE_SUCCESS:
      return {
        ...state,
        myEmployee: action.payload.employee,
        employeeId: action.payload.employeeId, // Store the employee ID
        status: 204,
        error: null,
      };
    case POST_EMPLOYEE_FAILURE:
      return {
        ...state,
        employee: null,
        error: action.error,
      };

    case DELETE_EMPLOYEE_SUCCESS: // Handle the delete action
      // Filter out the deleted company from the state
      const updatedEmployees = state.employees.filter(
        (employee) => employee._id !== action.payload.deletedEmployeeId
      );
      return {
        ...state,
        loading: false,
        employees: updatedEmployees,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        message: null,
        status: null,
        errors: null,
      };

    case "SET_AVAILABILITY_SUCCESS":
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        errors: null,
      };

    case "SET_AVAILABILITY_FAILURE":
      return {
        ...state,
        message: null,
        status: null,
        errors: action.payload,
      };

    default:
      return state;
  }
};
