import React from 'react';
import './ManagerFeedback.css';
import { DataGrid } from '@mui/x-data-grid';
import updateIcon from '../../../../../assets/Icons/updateIcon.png';
import deleteIcon from '../../../../../assets/Icons/deleteIcon.png';

function ManagerFeedback(props) {

    const handleEdit = (id) => {
        // Handle edit action here
        console.log(`Edit row with ID: ${id}`);
    };

    const handleDelete = (id) => {
        // Handle delete action here
        console.log(`Delete row with ID: ${id}`);
    };

    const rows = [
        { id: 1, image: 'https://images.unsplash.com/photo-1687523327554-fa9f50423489?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email1@example.com', Phone: '1234567891', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 2, image: 'https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email2@example.com', Phone: '9876543210', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 3, image: 'https://images.unsplash.com/photo-1618588429012-0559f1cbc5aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 4, image: 'https://images.unsplash.com/photo-1627843563095-f6e94676cfe0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=580&q=80', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbour,', Category: 'Nurse', Type: 'Casual' },
        { id: 5, image: 'https://images.unsplash.com/photo-1618588429012-0559f1cbc5aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 6, image: 'https://images.unsplash.com/photo-1618588429012-0559f1cbc5aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 7, image: 'https://images.unsplash.com/photo-1618588429012-0559f1cbc5aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 8, image: 'https://images.unsplash.com/photo-1618588429012-0559f1cbc5aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 9, image: 'https://images.unsplash.com/photo-1618588429012-0559f1cbc5aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },
        { id: 10, image: 'https://images.unsplash.com/photo-1618588429012-0559f1cbc5aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29tcGFueSUyMGxvZ29zfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60', Name: 'Disability Support Worker', email: 'email3@example.com', Phone: '5555555555', address: 'Level 10,Collins Street, Melbourne,', Category: 'Nurse', Type: 'Casual' },

    ];
    const columns = [
        { field: 'image', headerName: 'Image', width: 100, renderCell: (params) => <img src={params.value} alt='Avatar' style={{ width: 30, height: 30, borderRadius: '50%' }} /> },
        { field: 'Name', headerName: 'Name', width: 220 },
        { field: 'email', headerName: 'Email', width: 220 },
        { field: 'Phone', headerName: 'Phone', width: 170 },
        { field: 'Category', headerName: 'Category', width: 100 },
        { field: 'Type', headerName: 'Type', width: 100 },
        { field: 'address', headerName: 'Address', width: 280 },
        {
            width: 100,
            renderCell: (params) => (
                <div>
                    <img
                        src={updateIcon}
                        alt='Edit'
                        className='actionIcon'
                        onClick={() => handleEdit(params.row.id)}
                    />
                    <img
                        src={deleteIcon}
                        alt='Delete'
                        className='actionIcon'
                        onClick={() => handleDelete(params.row.id)}
                    />
                </div>
            ),
        },
    ];


    return(
        <div className='mainManagerFeedbackContainer'>
          {/* <div className='jobItemContainer'> */}
                <div className='' style={{ height: '56vh', width: '100%' }}>

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 18 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                    />
                </div>
            </div>
        // </div>
    )
}
export default ManagerFeedback;