// // socket.js

// const socket = io(`http://localhost:3000`, {
//   autoConnect: false,
//   withCredentials: true,
// });

// export default socket;

import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = 'http://localhost:3000';

export const socket = io(URL, {
    autoConnect: false
  });