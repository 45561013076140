import React, { useEffect, useState } from "react";
// import '../RightBars/FeedbackRightBar/FeedbackRightBar.css'
import "../FeedbackRightBar/FeedbackRightBar.css";
import { DataGrid } from "@mui/x-data-grid";
import { connect, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import NavBar from "../../../NavBar";
import { fetchFeedback } from "../../../../../redux/actions/feedbackAction";
import { GetEmployeesAction } from "../../../../../redux/actions/GetEmployeesAction";
import { generateInvoice } from "../../../../../redux/actions/createActions/generateInvoiceActions";
import Invoices from "./Invoices";
import { GetManagerdetailAction } from "../../../../../redux/actions/GetManagerdetailAction";
import SpinerLoading from "../../../../Loading";

function formatDateTime(dateTimeString, formatType) {
  const dateTime = new Date(dateTimeString);
  if (formatType === "time") {
    return dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }
  if (formatType === "date") {
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }
  return "";
}

function InvoiceRightBar(props) {
  const dispatch = useDispatch();
  const { loading, employees, status, myEmployee } = useSelector(
    (state) => state.employee
  );
  const [title, setTitle] = useState("Employee");
  const [invoiceState, setinvoiceState] = useState("InvoiceRightBar");

  const [selectedRow, setSelectedRow] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const { invoice, error } = useSelector((state) => state.invoice);
  console.log("🚀 ~ InvoiceRightBar ~ invoice:", invoice);

  //   const handleGenerateInvoice = (employeeId) => {
  //     dispatch(generateInvoice(employeeId))
  //     .then(() => {
  console.log("🚀 ~ InvoiceRightBar ~ employees:", employees);
  //         // Optional: If you want to log the response in the console
  //         console.log("Invoice generated successfully:", invoice);

  //         // Additional logic or state updates if needed
  //       })
  //       .catch((error) => {
  //         console.error("Error generating invoice:", error);
  //         // Handle error or update state accordingly
  //       });
  //   };

  // const handleGenerateInvoice = (employeeId) => {
  //     dispatch(generateInvoice(employeeId))
  //       .then((response) => {
  //         // You can access the generated invoice here
  //         const generatedInvoice = response.payload.invoice;
  //         // Do something with the generated invoice, e.g., display it to the user
  //         console.log("Generated Invoice:", generatedInvoice);
  //       })
  //       .catch((error) => {
  //         console.error("Error generating invoice:", error);
  //       });
  //   };

  const handleGenerateInvoice = (employee) => {
    const confirmMessage = `Do you want to generate invoice for ${employee.name} (ID: ${employee.id})?`;
    if (window.confirm(confirmMessage)) {
      dispatch(generateInvoice(employee.id))
        .then((response) => {
          if (
            response &&
            response.msg === "Invoice generation is not due yet"
          ) {
            alert("Invoice generation is not due yet. Please try again later.");
          } else if (response && response.invoice) {
            console.log("Invoice generated successfully:", response.invoice);
            alert("Invoice generated successfully!");
          } else {
            alert("Error generating invoice: Unknown error.");
          }
        })
        .catch((error) => {
          console.error("Error generating invoice:", error);
          if (error && error.message) {
            alert("Error generating invoice: " + error.message);
          } else {
            alert("Error generating invoice: Unknown error.");
          }
        });
    }
  };

  const renderInvoicePopup = () => {
    if (invoice) {
      return (
        <div className="invoice-popup">
          <h2>Generated Invoice Details</h2>
          <p>{invoice.msg}</p>
          <pre>{JSON.stringify(invoice.invoice, null, 2)}</pre>
        </div>
      );
    }
    return null;
  };

  // const handleRowClick = (params) => {
  //     setSelectedRow(params.row.id); // Store the ID of the clicked row in state
  //     setFeedback(feedbackData?.filter((feedback) => feedback._id === params.row.id));

  // };
  console.log(employees);

  useEffect(() => {
    dispatch(GetEmployeesAction());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetManagerdetailAction());
  }, [dispatch]);

  const rows =
    employees?.map((employee) => ({
      id: employee._id,
      name: employee.name,
      email: employee.email,
      image: employee.imageUrl,
      category: employee.category,
      employeeType: employee.employeeType,
      address: employee.address,
    })) || [];
  const columns = [
    // { field: 'id', headerName: 'ID', width: 80 },
    {
      field: "image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
    },
    { field: "name", headerName: "Name", width: 220 },
    { field: "email", headerName: "Email", width: 980 },
    {
      width: 200,
      renderCell: (params) => (
        <div>
          {/* <button className='createButton'  onClick={handleGenerateInvoice}>Generate</button> */}
          <button
            className="createButton"
            onClick={() => handleGenerateInvoice(params.row)}
          >
            Generate
          </button>
        </div>
      ),
    },
  ];
  const sortModel = [
    {
      field: "createdAt", // Replace 'date' with the actual field name you want to sort by
      sort: "desc", // 'asc' for ascending, 'desc' for descending
    },
  ];

  const initialState = {
    pagination: {
      pageSize: 10,
    },
    sortModel,
  };

  return (
    <div className="mainFeedbackRightBarContainer">
      <NavBar />
      <div className="itemContainer">
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Invoice</h1>

            <div className="feedbackFlex">
              <p
                className="employeefeedback"
                onClick={() => {
                  setinvoiceState("InvoiceRightBar");
                  setTitle("Employee Feedback");
                }}
              >
                {/* <img src='' alt='' className='feedbackIcon' />Employee */}
                <p
                  className="createButton"
                  style={{ height: "10px", fontWeight: "lighter" }}
                >
                  Payslips
                </p>
              </p>
              <p
                className="managerfeedback"
                onClick={() => {
                  setinvoiceState("Invoices");
                  setTitle("Manager Feedback");
                }}
              >
                {/* <img src='' alt='' className='feedbackIcon' /> Invoices */}
                <p
                  className="createButton"
                  style={{ height: "10px", fontWeight: "lighter" }}
                >
                  Ready Payslips
                </p>
              </p>
              {/* <p className='clientfeedback' onClick={() => {
                                setFeedbackState("ClientFeedback")
                                setTitle("Client Feedback ");
                            }}>
                                <img src={employeeFeedbackIcon} alt='' className='feedbackIcon' /> Client
                            </p> */}
            </div>
          </div>
        </div>
        <div className="feedbackItemContainer">
          <div className="" style={{ height: "56vh", width: "80vw" }}>
            {invoiceState === "InvoiceRightBar" ? (
              <>
                {loading ? (
                  <SpinerLoading />
                ) : (
                  // <div>Loading...</div>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    sortModel={sortModel}
                    initialState={initialState}
                    // pageSizeOptions={[5, 10]}
                    checkboxSelection
                  />
                )}
              </>
            ) : (
              ""
            )}
            {invoiceState === "Invoices" ? <Invoices /> : ""}
            {/* {
                            feedbackState === "ClientFeedback" ?
                                <ClientFeedback /> : ''
                        } */}
          </div>
        </div>
        <div>
          <div>{renderInvoicePopup()}</div>
        </div>
      </div>
    </div>
  );
}
export default InvoiceRightBar;
