import { CLEAR_INCIDENT_ERRORS, INCIDENT_FAIL, INCIDENT_REQUEST, INCIDENT_SUCCESS } from "../constants/incidentConstants";

const initialState = {
    loading: false,
    incidentData: [],
    errors: null,
  };
  export const incidentReducer = (state = initialState, action) => {
    switch (action.type) {
      case INCIDENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case INCIDENT_SUCCESS:
        return {
          ...state,
          loading: false,
          incidentData: action.payload,
        };
      case INCIDENT_FAIL:
        return {
          ...state,
          loading: false,
          errors: action.payload,
        };
      case CLEAR_INCIDENT_ERRORS:
        return {
          ...state,
          loading: false,
          errors: null,
        };
      default:
        return state;
    }
  };