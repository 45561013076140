import React from "react";
// import ChatNavBar from './ChatNavBar';
import Chats from "./Chats";

function ChatSideBar(props) {
  return (
    <div className="mainChatSideBarContainer">
      <Chats />
    </div>
  );
}
export default ChatSideBar;
