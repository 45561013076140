import {
  CLEAR_ERRORS,
  JOB_FAIL,
  JOB_REQUEST,
  GET_JOBS_CANCEL,
} from "../constants/jobConstants";

export const cancelJobReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_REQUEST:
      return {
        loading: true,
      };
    case GET_JOBS_CANCEL:
      return {
        ...state,
        loading: false,
        canceljobs: action.payload.jobcancel,
        status: action.payload.status,
      };
    case JOB_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };
    default:
      return state;
  }
};
