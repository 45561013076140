export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const AUTH_USER_REQUEST = "AUTH_USER_REQUEST";//we r not using in code
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAIL = "AUTH_USER_FAIL";

export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
