import axios from "axios";
import { fetchJobs } from "./jobActions";
import { BaseURL } from "../constants/BaseURL";
import { toast } from "react-toastify";

// Action Types
export const ACCEPT_REQUEST_SUCCESS = "ACCEPT_REQUEST_SUCCESS";
export const REJECT_REQUEST_SUCCESS = "REJECT_REQUEST_SUCCESS";
export const ACCEPT_REJECT_REQUEST_FAILURE = "ACCEPT_REJECT_REQUEST_FAILURE";

// Action Creators
export const acceptRequestSuccess = (message) => ({
  type: ACCEPT_REQUEST_SUCCESS,
  payload: { message },
});

export const rejectRequestSuccess = (message) => ({
  type: REJECT_REQUEST_SUCCESS,
  payload: { message },
});

export const acceptRejectRequestFailure = (error) => ({
  type: ACCEPT_REJECT_REQUEST_FAILURE,
  payload: { error },
});

// Async Action Creator
export const acceptRejectRequest =
  (employeeId, decision) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        ` |${BaseURL}/manager/jobs/${employeeId}/process-request`,
        {
          employeeId,
          decision,
        },
        {
          headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
          },
        }
      );
      //     dispatch(fetchJobs());
      //    catch (error) {
      //     dispatch(acceptRejectRequestFailure(error.message));
      //   }
      dispatch(fetchJobs());
    } catch (error) {
      dispatch(acceptRejectRequestFailure(error.message));
    }
  };

export const acceptRejectCancelRequest =
  (cancelJobId, action) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${BaseURL}/manager/job/cancel-request/${cancelJobId}`,
        {
          action,
        },
        {
          headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg);
        window.location.reload();
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      dispatch(acceptRejectRequestFailure(error.message));
    }
  };
