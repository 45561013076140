import { CREATE_JOB_FAILURE, CREATE_JOB_REQUEST, CREATE_JOB_SUCCESS } from "../constants/jobConstants";

// Define the initial state for the job creation
const initialState = {
    loading: false,
    jobData: null,
    error: null,
};

// Define the reducer function
const createJobReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_JOB_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CREATE_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                jobData: action.payload,
                error: null,
            };
        // case CREATE_JOB_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         jobData: null,
        //         error: action.payload,
        //     };

        // Instead of storing the entire error object
case CREATE_JOB_FAILURE:
    return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default createJobReducer;
