import React, { useEffect, useState } from "react";
import "./CompletedJob.css";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchCancelJobs } from "../../../../../redux/actions/jobActions";
import { acceptRejectCancelRequest } from "../../../../../redux/actions/jobRequestAction";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import SpinerLoading from "../../../../Loading";

function formatDateTime(dateTimeString, formatType) {
  const dateTime = new Date(dateTimeString);

  if (formatType === "time") {
    return dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  }
  if (formatType === "date") {
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }
  return "";
}

function CanceledJob() {
  const employeeId = "";
  const dispatch = useDispatch();
  const { loading, canceljobs } = useSelector((state) => state.canceljobs);
  console.log("🚀 ~ CanceledJob ~ canceljobs:", canceljobs);
  const [canceledJobs, setCanceledJobs] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false); // Add state for reload

  useEffect(() => {
    dispatch(fetchCancelJobs());
  }, [dispatch]);

  useEffect(() => {
    if (canceljobs) {
      setCanceledJobs(canceljobs);
    }
  }, [canceljobs]);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    console.log("🚀 ~ handleRowClick ~ params:", params.row._id);
  };
  const columns = [
    { field: "title", headerName: "Job Title", width: 200 },
    {
      field: "client",
      headerName: "Client",
      flex: 1,
      valueGetter: (params) => params.row.client?.[0]?.name ?? "",
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 1,
      valueGetter: (params) => params.row.manager?.[0]?.name ?? "",
    },
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
      valueGetter: (params) => params.row.employee?.[0]?.name ?? "",
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 150,
      valueGetter: (params) => params.row?.jobDetails?.startTime ?? "",
      valueFormatter: (params) => {
        const startTime = params.value;
        return startTime ? formatDateTime(startTime, "time") : "N/A";
      },
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 150,
      valueGetter: (params) => params.row?.jobDetails?.endTime ?? "",
      valueFormatter: (params) => {
        const endTime = params.value;
        return endTime ? formatDateTime(endTime, "time") : "N/A";
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      valueGetter: (params) => params.row?.jobDetails?.createdAt ?? "",
      valueFormatter: (params) => {
        const date = params.value;
        return date ? formatDateTime(date, "date") : "N/A";
      },
    },
  ];

  const handleAccept = () => {
    if (selectedRow) {
      const id = selectedRow._id;
      console.log("🚀 ~ handleAccept ~ id:", id);
      dispatch(acceptRejectCancelRequest(id, "accept"));
    } else {
      console.log("No row selected");
    }
  };

  const handleReject = async () => {
    if (selectedRow) {
      const id = selectedRow._id;
      console.log("🚀 ~ handleAccept ~ id:", id);
      dispatch(acceptRejectCancelRequest(id, "reject"));
    } else {
      console.log("No row selected");
    }
  };

  return (
    <div className="mainAvailableJobRightBarContainer">
      <div style={{ height: "56vh", width: "100%" }}>
        {loading ? (
          <SpinerLoading />
        ) : (
          <DataGrid
            rows={canceledJobs}
            columns={columns}
            getRowId={(row) => row._id}
            onRowClick={handleRowClick}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 18 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        )}
      </div>

      {selectedRow !== null && (
        <div className="popupManagerDetail">
          <div className="popup-header">
            <h2>Job Detail ({selectedRow?.jobDetails?.title})</h2>
            <h2
              onClick={() => setSelectedRow(null)}
              style={{
                cursor: "pointer",
                backgroundColor: "red",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              X
            </h2>
          </div>
          <section>
            <div className="info-col-1">
              <div className="jobcontainer-1">
                <h4 style={{ alignItems: "center", textAlign: "center" }}>
                  Employee Details
                </h4>
                <ul>
                  {selectedRow?.employee?.map((emp) => (
                    <div key={emp.email}>
                      <li className="profile">
                        <img
                          src={emp?.imageUrl ?? "N/A"}
                          alt="employee"
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: "50%",
                          }}
                        />
                        <span className="detail-span">{emp?.name}</span>
                      </li>
                      <li>
                        <h5>Category:</h5>
                        <span className="detail-span">
                          {emp?.category ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Email:</h5>
                        <span className="detail-span">
                          {emp?.email ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Contact:</h5>
                        <span className="detail-span">
                          {emp?.phone ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Address:</h5>
                        <span className="detail-span">
                          {emp?.address ?? "N/A"}
                        </span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className="jobcontainer-2">
                <h4 style={{ alignItems: "center", textAlign: "center" }}>
                  Manager Details
                </h4>
                <ul>
                  {selectedRow?.manager?.map((manager) => (
                    <div key={manager.email}>
                      <li className="profile">
                        <img
                          src={manager?.imageUrl ?? "N/A"}
                          alt="manager"
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: "50%",
                          }}
                        />
                        <span className="detail-span" style={{ marginLeft: 0 }}>
                          {manager?.name}
                        </span>
                      </li>
                      <li>
                        <h5>Email:</h5>
                        <span className="detail-span">
                          {manager?.email ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Contact:</h5>
                        <span className="detail-span">
                          {manager?.phone ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Address:</h5>
                        <span className="detail-span">
                          {manager?.address ?? "N/A"}
                        </span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className="jobcontainer-2">
                <h4 style={{ alignItems: "center", textAlign: "center" }}>
                  Client Details
                </h4>
                <ul>
                  {selectedRow?.client?.map((client) => (
                    <div key={client.email}>
                      <li className="profile">
                        <img
                          src={client?.imageUrl ?? "N/A"}
                          alt="client"
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: "50%",
                          }}
                        />
                        <span className="detail-span">{client?.name}</span>
                      </li>
                      <li>
                        <h5>Email:</h5>
                        <span className="detail-span">
                          {client?.email ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Contact:</h5>
                        <span className="detail-span">
                          {client?.phone ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Address:</h5>
                        <span className="detail-span">
                          {client?.address ?? "N/A"}
                        </span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className="jobinfo-col-2">
              <h1 style={{ marginTop: 0 }}>Detail</h1>
              <p style={{ fontWeight: "bold" }}>
                Description: {selectedRow?.jobDetails.description}
              </p>

              <div className="jobdescription">
                <br />
                <br />
                <div>
                  <h3>Tasks</h3>
                  {selectedRow?.jobDetails?.task?.map((task, index) => (
                    <p key={index}>{`${index + 1}. ${task.title}`}</p>
                  ))}
                </div>
                <br />
                <div>
                  <h3>Why Cancel:</h3>
                  <p style={{ fontWeight: "bold" }}>
                    Description: {selectedRow?.title}
                  </p>

                  <p style={{ fontWeight: "bold" }}>
                    Description: {selectedRow?.reason}
                  </p>
                </div>
                <br />
              </div>
              <br />

              <div>
                <div>
                  <button
                    className="managerRegister-btn"
                    onClick={handleAccept}
                  >
                    Approve
                  </button>
                  <button
                    className="availability-btn"
                    style={{ backgroundColor: "red" }}
                    onClick={handleReject}
                  >
                    Unapprove
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default CanceledJob;
