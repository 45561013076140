import { CLEAR_ERRORS, REQUESTED_AVAILABILITY_FAIL, REQUESTED_AVAILABILITY_REQUEST, REQUESTED_AVAILABILITY_SUCCESS } from "../constants/AvailabilityConstants";

export const requestReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUESTED_AVAILABILITY_REQUEST:
      return {
        loading: true,
      };
    case REQUESTED_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        requested: action.payload,
        status: action.payload.status,
      };
    case REQUESTED_AVAILABILITY_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };
    default:
      return state;
  }
};
