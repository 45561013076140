import React from 'react';
import './Employee.css';
import SideBar from '../../SideBar';
import EmployeeRightBar from '../RightBars/EmployeeRightBar';

function Employee(props) {
    return(
        <div className='mainEmployeeContainer'>
            <SideBar/>
            <EmployeeRightBar/>
        </div>
    )
}
export default Employee;