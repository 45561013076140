import React, { useEffect, useState } from "react";
import "./AvailabilityRightBar.css";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { GetRequestedAvailabilitiesAction } from "../../../../../redux/actions/availabilityActions/requestedActions";
import {
  approveAvailabilityRequest,
  rejectAvailabilityRequest,
} from "../../../../../redux/actions/availabilityActions/updateAvailabilityAction";
import { ToastContainer, toast } from "react-toastify";
import SpinerLoading from "../../../../Loading";

function AvailabilityRequest(props) {
  const dispatch = useDispatch();
  const { loading, requested } = useSelector((state) => state.request);
  console.log("🚀 ~ AvailabilityRequest ~ requested:", requested);
  const [selectedRow, setSelectedRow] = useState(null);
  const [request, setRequest] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false); // Add state for reload

  const handleRowClick = (params) => {
    setSelectedRow(params.row.id);
    setRequest(requested?.filter((request) => request._id === params.row.id));
  };

  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const formatDateTime = (dateTimeString) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };
  useEffect(() => {
    dispatch(GetRequestedAvailabilitiesAction());
  }, [dispatch, reloadComponent]); // Add reloadComponent to dependencies
  // setTimeout(() => {
  //     dispatch(GetRequestedAvailabilitiesAction());
  // }, 5000);
  const handleApprove = async () => {
    if (selectedRow !== null) {
      if (
        window.confirm(
          "Are you sure you want to approve this availability request?"
        )
      ) {
        try {
          await dispatch(approveAvailabilityRequest(selectedRow));
          toast.success("Availability request approved successfully");
          setReloadComponent((prev) => !prev); // Toggle reloadComponent state
        } catch (error) {
          toast.error("Error approving availability request");
          console.error("Error approving availability request:", error);
        }
      }
    }
  };

  const handleReject = async () => {
    if (selectedRow !== null) {
      if (
        window.confirm(
          "Are you sure you want to reject this availability request?"
        )
      ) {
        try {
          await dispatch(rejectAvailabilityRequest(selectedRow));
          toast.success("Availability request rejected successfully");
          setReloadComponent((prev) => !prev); // Toggle reloadComponent state
        } catch (error) {
          toast.error("Error rejecting availability request");
          console.error("Error rejecting availability request:", error);
        }
      }
    }
  };
  const rows =
    requested?.map((request) => ({
      id: request._id,
      name: request.employee.name,
      email: request.employee.email,
      phone: request.employee.phone,
      address: request.employee.address,
      employeeType: request.employee.employeeType,
      category: request.employee.category,
    })) || [];
  const columns = [
    // { field: 'id', headerName: 'ID', width: 150 },
    { field: "name", headerName: "Employee Name", width: 200 },
    { field: "email", headerName: "Email", width: 180 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "employeeType", headerName: "Employee Type", width: 220 },
    { field: "category", headerName: "Category", width: 220 },
    { field: "startDate", headerName: "startDate", width: 100 },
    { field: "endDate", headerName: "endDate", width: 100 },
  ];
  return (
    <div className="mainManagerFeedbackContainer">
      <div className="" style={{ height: "56vh", width: "100%" }}>
        {loading ? (
          <SpinerLoading />
        ) : (
          <DataGrid
            style={{ width: "76vw" }}
            rows={rows}
            columns={columns}
            onRowClick={handleRowClick}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 18 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        )}
      </div>
      {selectedRow !== null && (
        <div className="popupRequestDetail">
          <div className="popup-header">
            <h2>Availability Request</h2>
            <h2
              onClick={() => setSelectedRow(null)}
              style={{
                cursor: "pointer",
                backgroundColor: "red",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              X
            </h2>
          </div>
          {request.map((requestDetails, index) => (
            <section key={index}>
              <div className="request-container">
                <div className="request-profile">
                  <img src={requestDetails.employee.imageUrl} alt="profile" />
                  <div>
                    <h4 style={{ margin: "10px 20px 0px 0px" }}>
                      {requestDetails.employee.name}
                    </h4>
                    <p>{requestDetails.employee.phone}</p>
                  </div>
                </div>
                <div className="">
                  <p style={{ padding: " 0px 0px 2px 20px" }}>Days</p>
                  <div
                    style={{
                      display: "flex",
                      width: "350px",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      <p style={{ color: "silver" }}>From</p>
                      <div
                        className="Rectangle892"
                        style={{
                          width: 125,
                          height: 40,
                          left: 268,
                          top: 68,
                          background: "white",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 8,
                        }}
                      >
                        {" "}
                        {formatDateString(requestDetails.startDate)}
                      </div>
                    </div>
                    <div>
                      <p style={{ color: "silver" }}>To</p>
                      <div
                        className="Rectangle892"
                        style={{
                          width: 125,
                          height: 40,
                          left: 268,
                          top: 68,
                          background: "white",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 8,
                        }}
                      >
                        {" "}
                        {formatDateString(requestDetails.endDate)}
                      </div>
                    </div>
                  </div>
                  <p style={{ color: "silver", padding: "10px 0px 2px 20px" }}>
                    Shifts
                  </p>
                  <div>
                    {requestDetails.days.map((day, dayIndex) => (
                      <React.Fragment key={dayIndex}>
                        {day.fullDay ? (
                          <div
                            key={dayIndex}
                            className="shadedBox"
                            style={{
                              margin: "10px",
                              width: "30%",
                              boxSizing: "border-box",
                              display: "inline-block",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                              }}
                            >
                              <p style={{ color: "#78BE25" }}>
                                {formatDateString(day.date)}
                              </p>
                              <p>Full Day</p>
                            </div>
                          </div>
                        ) : (
                          <div
                            key={dayIndex}
                            className="shadedBox"
                            style={{
                              margin: "10px",
                              width: "32%",
                              boxSizing: "border-box",
                              display: "inline-block",
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <p style={{ color: "#78BE25" }}>
                                {formatDateString(day.date)}
                              </p>
                              {day.shifts.length > 2 ? (
                                // Render a dropdown if there are more than 2 shifts
                                <div>
                                  <select>
                                    {day.shifts.map((shift, shiftIndex) => (
                                      <option key={shiftIndex}>
                                        {`Shift ${
                                          shiftIndex + 1
                                        }: ${formatDateTime(
                                          shift.startTime
                                        )} - ${formatDateTime(shift.endTime)}`}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              ) : (
                                <>
                                  {day.shifts.map((shift, shiftIndex) => (
                                    <div key={shiftIndex}>
                                      <div
                                        style={{
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        <p>
                                          <strong>
                                            Shift: {shiftIndex + 1}
                                          </strong>
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-evenly",
                                        }}
                                      >
                                        <p>
                                          <strong>Start: </strong>{" "}
                                          {formatDateTime(shift.startTime)}
                                        </p>
                                        <p>
                                          <strong>End:</strong>{" "}
                                          {formatDateTime(shift.endTime)}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div style={{ display: "flex", margin: "20px" }}>
                  <button
                    className="managerRegister-btn"
                    onClick={handleApprove}
                  >
                    Approve
                  </button>
                  <button
                    className="availability-btn"
                    style={{ backgroundColor: "red" }}
                    onClick={handleReject}
                  >
                    Disapprove
                  </button>
                </div>
              </div>
            </section>
          ))}
        </div>
      )}
      <ToastContainer position="bottom-right" autoClose={3000} />
    </div>
  );
}
export default AvailabilityRequest;
