// import axios from "axios";
// import { BaseURL } from "../../constants/BaseURL";
// import { CLEAR_ERRORS, REQUESTED_AVAILABILITY_FAIL, REQUESTED_AVAILABILITY_REQUEST, REQUESTED_AVAILABILITY_SUCCESS } from "../../constants/AvailabilityConstants";

// export const GetRequestedAvailabilitiesAction = () => async (dispatch) => {
//   try {
//     dispatch({ type: REQUESTED_AVAILABILITY_REQUEST });
//     const token = localStorage.getItem('token');
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         "x-auth-token": token,
//       },
//     };
//     const response = await axios.get(`${BaseURL}/manager/requested-availability`, config);
//     const responseData = response.data;

//     dispatch({
//       type: REQUESTED_AVAILABILITY_SUCCESS,
//       payload: responseData.data,
//     });
//   } catch (error) {
//     dispatch({
//       type: REQUESTED_AVAILABILITY_FAIL,
//       payload: error.response.data,
//     });
//   }
// };
// export const clearErrors = () => async (dispatch) => {
//   dispatch({ type: CLEAR_ERRORS });
// };

import { CLEAR_ERRORS, REQUESTED_AVAILABILITY_FAIL, REQUESTED_AVAILABILITY_REQUEST, REQUESTED_AVAILABILITY_SUCCESS } from "../../constants/AvailabilityConstants";
import { BaseURL } from "../../constants/BaseURL";

export const GetRequestedAvailabilitiesAction = () => async (dispatch) => {
    dispatch({ type: REQUESTED_AVAILABILITY_REQUEST });
    try {
      // Make the API request using the fetch function or Axios
      const token = localStorage.getItem('token');
      const response = await fetch(`${BaseURL}/manager/requested-availability`, {
        headers: {
          'x-auth-token': token, // Replace with your actual auth token
        },
      });
      const data = await response.json();
      dispatch({ type: REQUESTED_AVAILABILITY_SUCCESS, payload: data.requestedAvailability });
      console.log(data);
    } catch (error) {
      dispatch({ type: REQUESTED_AVAILABILITY_FAIL, payload: error.message });
    }
  };
  export const clearIncidentErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };