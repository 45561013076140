import axios from "axios";
import { BaseURL } from "../constants/BaseURL";

// Action Types
export const FETCH_EMPLOYEE_REQUESTS_SUCCESS =
  "FETCH_EMPLOYEE_REQUESTS_SUCCESS";
export const FETCH_EMPLOYEE_REQUESTS_FAILURE =
  "FETCH_EMPLOYEE_REQUESTS_FAILURE";

// Action Creators
export const fetchEmployeeRequestsSuccess = (employeeRequests) => ({
  type: FETCH_EMPLOYEE_REQUESTS_SUCCESS,
  payload: { employeeRequests },
});

export const fetchEmployeeRequestsFailure = (error) => ({
  type: FETCH_EMPLOYEE_REQUESTS_FAILURE,
  payload: { error },
});

// Async Action Creator
export const fetchEmployeeRequests = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BaseURL}/manager/jobs/requests`, {
      headers: {
        "x-auth-token": token,
      },
    });

    console.log("🚀 ~ fetchEmployeeRequests ~ response:", response);
    dispatch(fetchEmployeeRequestsSuccess(response.data.employeeRequests));
  } catch (error) {
    dispatch(fetchEmployeeRequestsFailure(error.message));
  }
};
