import React, { useEffect, useState } from "react";
import "./AvailabilityRightBar.css";
import NavBar from "../../../NavBar";
import { DataGrid } from "@mui/x-data-grid";

import deleteIcon from "../../../../../assets/Icons/deleteIcon.png";
import SetAvailability from "./SetAvailability";
import AvailabilityRequest from "./AvailabilityRequest";
import { useDispatch, useSelector } from "react-redux";
import { GetCreatedAvailabilitiesAction } from "../../../../../redux/actions/availabilityActions/getAction";
import { useLocation } from "react-router";
import { IoIosCreate } from "react-icons/io";
import { FaCodePullRequest } from "react-icons/fa6";
import SpinerLoading from "../../../../Loading";
import { RxAvatar } from "react-icons/rx";

function AvailabilityRightBar(props) {
  const [title, setTitle] = useState("All Availabilities");
  const [feedbackState, setFeedbackState] = useState("AvailabilityRightBar");

  const [selectedRow, setSelectedRow] = useState(null);
  const [request, setRequest] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false); // Add state for reload

  const handleRowClick = (params) => {
    setSelectedRow(params.row.id);
    setRequest(requested?.filter((request) => request._id === params.row.id));
  };
  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const formatDateTime = (dateTimeString) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };
  const location = useLocation();
  const hasEmployeeId = location.pathname.includes("myEmployee._id");

  const handleDelete = (id) => {
    // Handle delete action here
    console.log(`Delete row with ID: ${id}`);
  };
  const dispatch = useDispatch();
  const { loading, requested } = useSelector((state) => state.request);

  //  const formatDateString = (dateString) => {
  //     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  //     return new Date(dateString).toLocaleDateString(undefined, options);
  //   };
  useEffect(() => {
    dispatch(GetCreatedAvailabilitiesAction());
  }, [dispatch, reloadComponent]);

  const rows =
    requested?.map((request) => ({
      id: request._id,
      name: request.employee.name,
      email: request.employee.email,
      phone: request.employee.phone,
      address: request.employee.address,
      employeeType: request.employee.employeeType,
      category: request.employee.category,
      startDate: formatDateString(request.startDate),
      endDate: formatDateString(request.endDate),
    })) || [];

  const columns = [
    { field: "name", headerName: "Employee Name", width: 200 },
    { field: "email", headerName: "Email", width: 180 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "address", headerName: "Address", width: 250 },
    { field: "employeeType", headerName: "Employee Type", width: 220 },
    { field: "category", headerName: "Category", width: 220 },
    { field: "startDate", headerName: "startDate", width: 100 },
    { field: "endDate", headerName: "endDate", width: 100 },
  ];
  return (
    <div className="mainAvailabilityRightBar">
      <NavBar />
      <div className="container">
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Availability</h1>
            <br />
            <br />
            <h1 className="dashtext">{title}</h1>
          </div>
        </div>
        <div className="availabilityFlex">
          <p
            className="employeefeedback"
            onClick={() => {
              setFeedbackState("AvailabilityRightBar");
              // alert("check request");

              setTitle("All Availabilities ");
              setReloadComponent(!reloadComponent);
            }}
          >
            <IoIosCreate
              style={{ height: "20px", width: "20px", marginRight: "12px" }}
            />
            Created
          </p>
          <p
            className="clientfeedback"
            onClick={() => {
              setFeedbackState("AvailabilityRequest");
              setTitle("Availability Requests ");
              // setReloadComponent(!reloadComponent);
            }}
          >
            <FaCodePullRequest
              style={{ height: "20px", width: "20px", marginRight: "12px" }}
            />
            Requested
          </p>
        </div>
        <div className="availabilityItemContainer">
          <div className="" style={{ height: "56vh", width: "100%" }}>
            {feedbackState === "AvailabilityRightBar" ? (
              <>
                {loading ? (
                  <SpinerLoading />
                ) : (
                  <DataGrid
                    style={{ width: "76vw" }}
                    rows={rows}
                    columns={columns}
                    onRowClick={handleRowClick}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 18 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                  />
                )}
              </>
            ) : (
              ""
            )}

            {feedbackState === "AvailabilityRequest" ? (
              <AvailabilityRequest />
            ) : (
              ""
            )}
          </div>
        </div>
        {selectedRow !== null && (
          <div className="popupRequestDetail">
            <div className="popup-header">
              <h2>Availability</h2>
              <h2
                onClick={() => setSelectedRow(null)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                X
              </h2>
            </div>
            {request.map((requestDetails, index) => (
              <section key={index}>
                <div className="created-container">
                  <div className="request-profile">
                    {requestDetails.employee?.imageUrl ? (
                      <img
                        src={requestDetails.employee.imageUrl}
                        alt="profile"
                      />
                    ) : (
                      <RxAvatar
                        style={{
                          borderRadius: "500px",
                          background: "#bdc3d5",
                          color: "#ffffff",
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    )}
                    <div>
                      <h4 style={{ margin: "10px 20px 0px 0px" }}>
                        {requestDetails.employee.name}
                      </h4>
                      <p>{requestDetails.employee.phone}</p>
                    </div>
                  </div>
                  <div className="">
                    <p style={{ padding: " 20px 0px 2px 20px" }}>Days</p>
                    <div
                      style={{
                        display: "flex",
                        width: "350px",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <p style={{ color: "silver" }}>From</p>
                        <div
                          className="Rectangle892"
                          style={{
                            width: 125,
                            height: 40,
                            left: 268,
                            top: 68,
                            background: "white",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: 8,
                          }}
                        >
                          {" "}
                          {formatDateString(requestDetails.startDate)}
                        </div>
                      </div>
                      <div>
                        <p style={{ color: "silver" }}>To</p>
                        <div
                          className="Rectangle892"
                          style={{
                            width: 125,
                            height: 40,
                            left: 268,
                            top: 68,
                            background: "white",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: 8,
                          }}
                        >
                          {" "}
                          {formatDateString(requestDetails.endDate)}
                        </div>
                      </div>
                    </div>
                    <p
                      style={{ color: "silver", padding: "15px 0px 2px 20px" }}
                    >
                      Shifts
                    </p>
                    <div>
                      {requestDetails.days.map((day, dayIndex) => (
                        <React.Fragment key={dayIndex}>
                          {day.fullDay ? (
                            <div
                              key={dayIndex}
                              className="shadedBox"
                              style={{
                                margin: "10px",
                                width: "30%",
                                boxSizing: "border-box",
                                display: "inline-block",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <p style={{ color: "#78BE25" }}>
                                  {formatDateString(day.date)}
                                </p>
                                <p>Full Day</p>
                              </div>
                            </div>
                          ) : (
                            <div
                              key={dayIndex}
                              className="shadedBox"
                              style={{
                                margin: "10px",
                                width: "32%",
                                boxSizing: "border-box",
                                display: "inline-block",
                              }}
                            >
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <p style={{ color: "#78BE25" }}>
                                  {formatDateString(day.date)}
                                </p>
                                {day.shifts.length > 2 ? (
                                  // Render a dropdown if there are more than 2 shifts
                                  <div>
                                    <select className="shiftDropDown">
                                      {day.shifts.map((shift, shiftIndex) => (
                                        <option key={shiftIndex}>
                                          {`Shift ${
                                            shiftIndex + 1
                                          }: ${formatDateTime(
                                            shift.startTime
                                          )} - ${formatDateTime(
                                            shift.endTime
                                          )}`}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ) : (
                                  <>
                                    {day.shifts.map((shift, shiftIndex) => (
                                      <div key={shiftIndex}>
                                        <div
                                          style={{
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <p>
                                            <strong>
                                              Shift: {shiftIndex + 1}
                                            </strong>
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-evenly",
                                          }}
                                        >
                                          <p>
                                            <strong>Start: </strong>{" "}
                                            {formatDateTime(shift.startTime)}
                                          </p>
                                          <p>
                                            <strong>End:</strong>{" "}
                                            {formatDateTime(shift.endTime)}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
export default AvailabilityRightBar;
