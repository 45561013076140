
// import socket from "../../../../../socket";

// const Events = () => {
//   const sendMessage = (message) => {
//     socket.emit('chat message', message);
//   };

//   return null;
// };

// export default Events;


import React from 'react';

export function Events({ events }) {
  return (
    <ul>
    {
      events.map((event, index) =>
        <li key={ index }>{ event }</li>
      )
    }
    </ul>
  );
}