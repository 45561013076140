import { ADD_MANAGER_ERROR, ADD_MANAGER_SUCCESS, CLEAR_ERRORS, DELETE_MANAGER_SUCCESS, MANAGER_FAIL, MANAGER_REQUEST, MANAGER_SUCCESS, POST_MANAGER_FAILURE, POST_MANAGER_SUCCESS } from "../constants/managerConstants";

export const managerReducer = (state = {}, action) => {
    // console.log(action.type)
    switch (action.type) {
        case MANAGER_REQUEST:
            return {
                loading: true,
            };
        case MANAGER_SUCCESS:
            return {
                ...state,
                loading: false,
                managers: action.payload.managers,
            };
        case MANAGER_FAIL:
            return {
                loading: false,
                errors: action.payload,
            };
            case ADD_MANAGER_SUCCESS:
                return {
                  ...state,
                  manager: action.payload.manager,
                  error: null,
                };
              case ADD_MANAGER_ERROR:
                return {
                  ...state,
                  manager: null,
                  error: action.error,
                };
        case DELETE_MANAGER_SUCCESS: // Handle the delete action
            // Filter out the deleted company from the state
            const updatedManagers = state.managers.filter(
                (manager) => manager._id !== action.payload.deletedManagerId
            );

            return {
                ...state,
                loading: false,
                managers: updatedManagers,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                message: null,
                status: null,
                errors: null,
            };


        default:
            return state;
    }
}