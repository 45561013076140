import React from 'react';
import SideBar from '../../SideBar';
import './IncidentReport.css';
import IncidentReportRightBar from '../RightBars/IncidentReportRightBar';

function IncidentReport(props) {
    return(
        <div className='mainIncidentReportContainer'>
            <SideBar/>
            <IncidentReportRightBar/>
        </div>
    )
}
export default IncidentReport;