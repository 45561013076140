import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import divineLogo from "../../../src/assets/divine-logo.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction, clearErrors } from "../../redux/actions/authAcrions";
import { toast } from "react-toastify";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role, isAuthenticated, status, message, token } = useSelector(
    (state) => state.auth
  );
  const [login, setLogin] = useState({
    email: "",
    password: "",
    role: "",
  });

  const loginInpChange = (event) => {
    setLogin({ ...login, [event.target.name]: event.target.value });
  };

  const loginFormSubmit = (event) => {
    event.preventDefault();
    dispatch(LoginAction(login.email, login.password, login.role));
  };

  useEffect(() => {
    if (status && status === 401) {
      toast.error(message, { theme: "colored" });
      dispatch(clearErrors());
    }

    if (status && status === 200) {
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      toast.success(message, { theme: "colored" });
      navigate("/home");
    }

    if (status && status === 404) {
      toast.error(message, { theme: "colored" });
      dispatch(clearErrors());
    }

    if (isAuthenticated && isAuthenticated === true) {
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      navigate("/home");
    }
    console.log(token);
  }, [dispatch, navigate, status, message, isAuthenticated, token, role]);

  return (
    <section className="register">
      <div className="col-1">
        <form
          id="loginform"
          className="flex flex-col"
          method="post"
          onSubmit={loginFormSubmit}
        >
          <h2>Sign In Your Account</h2>
          <span>Enter your email and password to sign in!</span>
          <label>
            Email* <br />
            <br />
            <input
              type="text"
              placeholder="mail@example.com"
              className="textBox"
              value={login.email}
              onChange={loginInpChange}
              name="email"
              required
            />
          </label>
          <label>
            Password* <br />
            <br />
            <input
              type="password"
              placeholder="Min. 8 characters"
              className="textBox"
              value={login.password}
              onChange={loginInpChange}
              name="password"
              required
            />
          </label>
          <label>
            {" "}
            Role* <br />
            <select
              className="roleDropDown"
              value={login.role}
              onChange={loginInpChange}
              name="role"
              required
            >
              <option>Select Role...</option>
              <option value="Owner">Owner</option>
              <option value="Manager">Manager</option>
            </select>
          </label>
          <label>
            <input type="checkbox" /> Keep me logged in
          </label>
          <button className="btn">Sign In</button>
          <p>{message}</p>
        </form>
      </div>
      <div className="col-2">
        <img src={divineLogo} alt="Divine Logo" />
      </div>
    </section>
  );
};

export default LoginPage;
