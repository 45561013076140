import axios from "axios";
import { BaseURL } from "../constants/BaseURL";
import { CLEAR_ERRORS, MANAGER_FAIL, MANAGER_REQUEST, MANAGER_SUCCESS } from "../constants/managerConstants";

export const GetManagerAction = () => async (dispatch) => {
    try {
        dispatch({ type: MANAGER_REQUEST });
        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${BaseURL}/compnay/all-manager`, {
            headers: { "Content-Type": "application/json",
                "x-auth-token": token
            }
        });
        dispatch({
            type: MANAGER_SUCCESS,
            payload: data
        })
        console.log(data)
    } catch (error) {
        dispatch({
            type: MANAGER_FAIL,
            payload: error.response.data
        })
    }
}
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS})
}

export const GetManagerDetailAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: MANAGER_REQUEST });
        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${BaseURL}/compnay/all-manager/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": token
            }
        });
        dispatch({
            type: MANAGER_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: MANAGER_FAIL,
            payload: error.response.data
        });
    }
};
