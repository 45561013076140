// employeeActions.js

import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";
import {
  POST_EMPLOYEE_FAILURE,
  POST_EMPLOYEE_REQUEST,
  POST_EMPLOYEE_SUCCESS,
} from "../../constants/EmployeeConstants";

export const postEmployee = (formData) => async (dispatch) => {
  try {
    console.log(formData);
    dispatch({ type: POST_EMPLOYEE_REQUEST });
    const token = localStorage.getItem("token");

    const { data } = await axios.post(
      `${BaseURL}/manager/register-employee`,
      formData,
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
      }
    );
    dispatch({
      type: POST_EMPLOYEE_SUCCESS,
      payload: data, // Store the token in the Redux state
    });
  } catch (error) {
    console.log(error.response.data);
    dispatch({
      type: POST_EMPLOYEE_FAILURE,
      payload: error.response.data,
    });
  }
};
