import {
  JOB_FAIL,
  JOB_REQUEST,
  JOB_SUCCESS,
  GET_JOBS_CANCEL,
} from "../constants/jobConstants";
import { BaseURL } from "../constants/BaseURL";
import axios from "axios";
export const fetchJobs = () => async (dispatch) => {
  try {
    dispatch({ type: JOB_REQUEST });

    //manager/job/cancel-request
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${BaseURL}/jobs/jobs-with-details`, {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    });
    dispatch({
      type: JOB_SUCCESS,
      payload: data,
    });
    console.log(data);
  } catch (error) {
    console.log(error.response.data);
    dispatch({
      type: JOB_FAIL,
      payload: error.response.data,
    });
  }
};
export const fetchCancelJobs = () => async (dispatch) => {
  try {
    dispatch({ type: JOB_REQUEST });

    //manager/job/cancel-request
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${BaseURL}/manager/job/cancel-request `, {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
    });
    dispatch({
      type: GET_JOBS_CANCEL,
      payload: data,
    });
    console.log(data);
  } catch (error) {
    console.log(error.response.data);
    dispatch({
      type: JOB_FAIL,
      payload: error.response.data,
    });
  }
};
