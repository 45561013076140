import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Messages from "./Messages";
import Input from "./Input";
import { fetchMessages } from "../../../../../redux/actions/chatActions/getMessageActions";

function SperateChat({ selectedChat }) {
  const dispatch = useDispatch();
  console.log("🚀 ~ Chat ~ selectedChat:", selectedChat);

  const [messages, setMessages] = useState([]);
  const senderId =
    selectedChat && Array.isArray(selectedChat.members)
      ? selectedChat.members[0]
      : null;
  const reciverId =
    selectedChat && Array.isArray(selectedChat.members)
      ? selectedChat.members[1]
      : null;

  useEffect(() => {
    if (selectedChat) {
      dispatch(fetchMessages(selectedChat._id));
    }
  }, [dispatch, selectedChat]);

  const addMessage = (newMessage) => {
    if (!newMessage || !newMessage.content) {
      console.error("Invalid message:", newMessage);
      return;
    }

    setMessages((prevMessages) => {
      if (!Array.isArray(prevMessages)) {
        return [newMessage];
      }
      return [...prevMessages, newMessage];
    });
  };
  return (
    <div className="chat">
      <div className="chatHeader">
        {selectedChat?.imageUrl && <img src={selectedChat.imageUrl} />}
        <div className="chatInfo">
          <span>{selectedChat ? selectedChat.name : "No Chat Selected"}</span>
          <div className="chatIcons"></div>
        </div>
      </div>
      <Messages messages={messages} />
      <Input
        senderId={senderId}
        reciverId={reciverId}
        addMessage={addMessage}
        chatId={selectedChat ? selectedChat._id : null}
      />
    </div>
  );
}

export default SperateChat;
