// import axios from "axios";
// import { BaseURL } from "../../constants/BaseURL";

// export const updateAvailabilityAction = (requestId) => async (dispatch) => {
//   try {
//     dispatch({ type: APPROVE_AVAILABILITY_REQUEST });
//     const token = localStorage.getItem('token');
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         "x-auth-token": token,
//       },
//     };
//     const response = await axios.get(`${BaseURL}/manager/availability-updateStatus/${requestId}`, config);
//     { action: 'Approve' 
//     }
//     dispatch({
//       type: REJECT_AVAILABILITY_REQUEST,
//       payload: data.employeeAvailability    });
//   } catch (error) {
//     dispatch({
//       type: REQUESTED_AVAILABILITY_FAIL,
//       payload: error.response.data,
//     });
//   }
// };
// export const clearErrors = () => async (dispatch) => {
//   dispatch({ type: CLEAR_ERRORS });
// };


import axios from 'axios';
import { BaseURL } from '../../constants/BaseURL';
import { APPROVE_AVAILABILITY_REQUEST_SUCCESS, GET_REQUESTED_AVAILABILITIES_SUCCESS, REJECT_AVAILABILITY_REQUEST_SUCCESS } from '../../constants/AvailabilityConstants';

const approveAvailabilityRequestSuccess = (requestId) => ({
  type: APPROVE_AVAILABILITY_REQUEST_SUCCESS,
  payload: { requestId },
});
const rejectAvailabilityRequestSuccess = (requestId) => ({
  type: REJECT_AVAILABILITY_REQUEST_SUCCESS,
  payload: { requestId },
});
const getRequestedAvailabilitiesSuccess = (requested) => ({
  type: GET_REQUESTED_AVAILABILITIES_SUCCESS,
  payload: { requested },
});
export const approveAvailabilityRequest = (requestId) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');

    await axios.put(
      `${BaseURL}/manager/availability-updateStatus/${requestId}`,
      { action: 'Accept' },
      {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
      }
    );

    // Dispatch success action
    dispatch(approveAvailabilityRequestSuccess(requestId));

    // Fetch updated requested availabilities after approval
    dispatch(getRequestedAvailabilities());
  } catch (error) {
    // Handle errors as needed
    console.error('Error approving availability request:', error);
  }
};

export const rejectAvailabilityRequest = (requestId) => async (dispatch) => {
  try {
    // Make API request to reject the availability request
    const token = localStorage.getItem('token');

    await axios.put(
      `${BaseURL}/manager/availability-updateStatus/${requestId}`,
      { action: 'Reject' },
      {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
      }
    );

    // Dispatch success action
    dispatch(rejectAvailabilityRequestSuccess(requestId));

    // Fetch updated requested availabilities after rejection
    dispatch(getRequestedAvailabilities());
  } catch (error) {
    // Handle errors as needed
    console.error('Error rejecting availability request:', error);
  }
};

export const getRequestedAvailabilities = () => async (dispatch) => {
  try {
    // Make API request to get the updated list of requested availabilities
    const response = await axios.get(
      'YOUR_API_ENDPOINT_TO_GET_REQUESTED_AVAILABILITIES',
      {
        headers: {
          'x-auth-token': 'YOUR_AUTH_TOKEN',
        },
      }
    );

    // Dispatch success action with the updated requested availabilities
    dispatch(getRequestedAvailabilitiesSuccess(response.data));
  } catch (error) {
    // Handle errors as needed
    console.error('Error fetching requested availabilities:', error);
  }
};
