import React, { useEffect, useState } from "react";
import "./AvailableJob.css";
import { DataGrid } from "@mui/x-data-grid";

import { GiPathDistance } from "react-icons/gi"; // Import from the 'react-icons/gi' library
import updateIcon from "../../../../../assets/Icons/updateIcon.png";
import deleteIcon from "../../../../../assets/Icons/deleteIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../../../../redux/actions/jobActions";
import { acceptRejectRequest } from "../../../../../redux/actions/jobRequestAction";
import { fetchEmployeeRequests } from "../../../../../redux/actions/employeeJobRequestAction";
import SpinerLoading from "../../../../Loading";

function formatDateTime(dateTimeString, formatType) {
  const dateTime = new Date(dateTimeString);

  if (formatType === "time") {
    return dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  }
  if (formatType === "date") {
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }
  return "";
}
function AvailableJob(props) {
  const employeeId = "";
  const [availableJobs, setAvailableJobs] = useState([]);
  const { loading, jobs } = useSelector((state) => state.job);
  const { acceptanceMessage, rejectionMessage } = useSelector(
    (state) => state.job
  );
  const { employeeRequests, error } = useSelector((state) => state.employee);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployeeRequests());
  }, [dispatch]);

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(acceptRejectRequest(selectedEmployee._id, "accept"));
    }
  }, [dispatch, selectedEmployee]);

  useEffect(() => {
    if (jobs) {
      setAvailableJobs(
        jobs.filter(
          (job) =>
            job.status === "pending" &&
            (!job.employee || job.employee.length == 0)
        )
      );
    }
  }, [jobs]);
  console.log(availableJobs);
  // const handleEdit = (id) => {
  //   console.log(`Edit row with ID: ${id}`);
  // };
  const handleDelete = (id) => {
    console.log(`Delete row with ID: ${id}`);
  };
  const handleAccept = () => {
    dispatch(acceptRejectRequest(employeeId, "accept"));
  };

  const handleReject = () => {
    dispatch(acceptRejectRequest(employeeId, "reject"));
  };
  const [selectedRow, setSelectedRow] = useState(null);
  const [job, setJob] = useState(null);

  const handleRowClick = (params) => {
    setSelectedRow(); // Store the entire data of the clicked row in stat
    setSelectedEmployee(null); // Clear selected employee when a new row is clicked

    // console.log(params.id);
    setJob(jobs?.find((job1) => job1._id === params.id));
  };
  const shiftStartTime = job?.startTime ? new Date(job.startTime) : null;
  const shiftEndTime = job?.endTime ? new Date(job.endTime) : null;
  let shiftHours = 0; // Declare shiftHours and provide an initial value

  // Check if both shift start time and end time are defined
  if (shiftStartTime && shiftEndTime) {
    const timeDifference = shiftEndTime - shiftStartTime;
    shiftHours = timeDifference / (1000 * 60 * 60);
    // Continue using shiftHours or perform other operations
  } else {
    // Handle the case where either start time or end time is undefined
  }

  // const availableJobs = useSelector((state) => state.job.availableJobs);
  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

  const columns = [
    { field: "title", headerName: "Job Title", width: 200 },
    {
      field: "client",
      headerName: "Client",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.client && params.row.client.length > 0) {
          return params.row.client[0]?.name;
        }
        return "";
      },
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.manager && params.row.manager.length > 0) {
          return params.row.manager[0].name;
        }
        return "";
      },
    },
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.employee && params.row.employee.length > 0) {
          return params.row.employee[0].name;
        }
        return "";
      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 150,
      valueFormatter: (params) => formatDateTime(params.value, "time"),
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 150,
      valueFormatter: (params) => formatDateTime(params.value, "time"),
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      valueFormatter: (params) => formatDateTime(params.value, "date"),
    },
    {
      width: 100,
      renderCell: (params) => (
        <div>
          {/* <img
            src={updateIcon}
            alt="Edit"
            className="actionIcon"
            onClick={() => handleEdit(params.row.id)}
          /> */}
          <img
            src={deleteIcon}
            alt="Delete"
            className="actionIcon"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="mainAvailableJobRightBarContainer">
      {/* <div className="table-container"> */}
      <div
        className=""
        style={{ height: "56vh", width: "100%", overflow: "scroll" }}
      >
        <DataGrid
          style={{ width: "80vw" }}
          rows={availableJobs}
          columns={columns}
          getRowId={(row) => row._id}
          onRowClick={handleRowClick}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 7 },
            },
          }}
          pageSizeOptions={[5, 5]}
          checkboxSelection
        />
        {loading && <SpinerLoading />}

        {/* </div> */}
      </div>
      {selectedRow !== null && (
        <div className="popupManagerDetail">
          <div className="popup-header">
            <h2>Job Detail ( {job?.title})</h2>
            <h2
              onClick={() => setSelectedRow(null)}
              style={{
                cursor: "pointer",
                backgroundColor: "red",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex", // Add this to center content vertically and horizontally
                alignItems: "center", // Center vertically
                justifyContent: "center", // Center horizontally
                color: "white",
                fontWeight: "bold", // Make the text bold
              }}
            >
              X
            </h2>
          </div>
          <section>
            <div className="info-col-1">
              {/* <div className="jobcontainer-1">
                  <h4 style={{ alignItems: 'center', textAlign: 'center' }}>Employee Details</h4>
                  <ul>
                    {
                      job?.employee?.map((emp) => (
                        <div>
                          <li className='profile' >
                            <img src={emp?.imageUrl ?? "N/A"} alt='manager image' style={{ width: 70, height: 70, borderRadius: '50%' }} />
                            <span className="detail-span">{emp?.name}</span>
                          </li>
                          <li >
                            <h5>Category:</h5>
                            <span className="detail-span">{emp?.category ?? "N/A"}</span>
                          </li>
                          <li>
                            <h5>Email:</h5>
                            <span className="detail-span">{emp?.email ?? "N/A"}</span>
                          </li>
                          <li>
                            <h5>Contact:</h5>
                            <span className="detail-span">{emp?.phone ?? "N/A"}</span>
                          </li>
                          <li>
                            <h5>Address:</h5>
                            <span className="detail-span">{emp?.address ?? "N/A"}</span>
                          </li>
                        </div>
                      ))
                    }
                  </ul>
                </div> */}
              <div className="jobcontainer-2">
                <h4 style={{ alignItems: "center", textAlign: "center" }}>
                  Manager Details
                </h4>
                <ul>
                  {job?.manager?.map((manager) => (
                    <div>
                      <li className="profile">
                        {/* <h5>Name:</h5> */}
                        <img
                          src={manager?.imageUrl ?? "N/A"}
                          alt="manager image"
                          style={{ width: 70, height: 70, borderRadius: "50%" }}
                        />
                        <span className="detail-span" style={{ marginLeft: 0 }}>
                          {manager?.name}
                        </span>
                      </li>
                      {/* <li >
                            <h5>Category:</h5>
                            <span className="detail-span">{manager?.category ?? "N/A"}</span>
                          </li> */}
                      <li>
                        <h5>Email:</h5>
                        <span className="detail-span">
                          {manager?.email ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Contact:</h5>
                        <span className="detail-span">
                          {manager?.phone ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Address:</h5>
                        <span className="detail-span">
                          {manager?.address ?? "N/A"}
                        </span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className="jobcontainer-2">
                <h4 style={{ alignItems: "center", textAlign: "center" }}>
                  Client Details
                </h4>
                <ul>
                  {job?.client?.map((client) => (
                    <div>
                      <li className="profile">
                        <img
                          src={client?.imageUrl ?? "N/A"}
                          alt="manager image"
                          style={{ width: 70, height: 70, borderRadius: "50%" }}
                        />
                        <span className="detail-span">{client?.name}</span>
                      </li>
                      {/* <li >
                            <h5>Category:</h5>
                            <span className="detail-span">{client?.category ?? "N/A"}</span>
                          </li> */}
                      <li>
                        <h5>Email:</h5>
                        <span className="detail-span">
                          {client?.email ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Contact:</h5>
                        <span className="detail-span">
                          {client?.phone ?? "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Address:</h5>
                        <span className="detail-span">
                          {client?.address ?? "N/A"}
                        </span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className="jobinfo-col-2">
              <h1 style={{ marginTop: 0 }}>Description</h1>
              <div className="jobdescription">
                <h2>Disability Support Worker</h2>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <p style={{ fontWeight: "bold" }}>
                    Allownces: ${job?.extraAmount}
                  </p>
                  <p
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src="https://as2.ftcdn.net/v2/jpg/01/25/75/67/1000_F_125756794_egU6KkEDJXj05BeKNBkvBMl8Vbs8yomS.jpg"
                      style={{ height: "30px", width: "30px" }}
                    />
                    {job?.shift}
                  </p>
                  <p>
                    {" "}
                    <img
                      style={{
                        background: "green",
                        height: "25px",
                        width: "25px",
                      }}
                      src=<GiPathDistance />
                    />{" "}
                    {job?.extraDistance}
                  </p>
                  <p>Shift Hours: {shiftHours.toFixed(2)}</p>
                </div>
                <br />
                <p>{job?.description}</p>
                <br />
                <div>
                  <h3>Tasks</h3>
                  {job?.task.map((task, index) => (
                    <p>{`${index + 1}.  ${task.title}`}</p>
                  ))}
                </div>
                <br />
              </div>
              <br />
              <div>
                {error && <p>{error}</p>}
                <label>
                  Employee Requests:
                  <select
                    onChange={(e) =>
                      setSelectedEmployee(JSON.parse(e.target.value))
                    }
                    className="managerDropDown"
                  >
                    <option value={null}>Select an employee</option>
                    {employeeRequests &&
                      employeeRequests.map((employee) => (
                        <option
                          key={employee._id}
                          value={JSON.stringify(employee)}
                        >
                          {employee.email}
                        </option>
                      ))}
                  </select>
                </label>
              </div>
            </div>
          </section>
          {/* <div>
            {error && <p>{error}</p>}
            <label>
              Select Employee:
              <select onChange={(e) => setSelectedEmployee(JSON.parse(e.target.value))}>
                <option value={null}>Select an employee</option>
                {employeeRequests.map((employee) => (
                  <option key={employee._id} value={JSON.stringify(employee)}>
                    {employee.email}
                  </option>
                ))}
              </select>
            </label>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default AvailableJob;
