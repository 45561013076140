import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";
import { CLEAR_ERRORS, REQUESTED_AVAILABILITY_FAIL, REQUESTED_AVAILABILITY_REQUEST, REQUESTED_AVAILABILITY_SUCCESS,  } from "../../constants/AvailabilityConstants";

export const GetCreatedAvailabilitiesAction = () => async (dispatch) => {
  try {
    dispatch({ type: REQUESTED_AVAILABILITY_REQUEST });
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
    const response = await axios.get(`${BaseURL}/manager/employee-avilability`, config);
    const data = response.data;

    dispatch({
      type: REQUESTED_AVAILABILITY_SUCCESS,
      payload: data.employeeAvailability    });
  } catch (error) {
    dispatch({
      type: REQUESTED_AVAILABILITY_FAIL,
      payload: error.response.data,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
