import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";
import {
  ADD_MANAGER_ERROR,
  ADD_MANAGER_SUCCESS,
} from "../../constants/managerConstants";
import { toast } from "react-toastify";

export const addManager = (formData, navigate) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BaseURL}/compnay/create-manager`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "Content-Type": "multipart/form-data",
            "x-auth-token": token,
          },
        }
      );
      console.log("🚀 ~ return ~ response:", response);

      if (response.status === 201) {
        toast.success("Manager Created Successfully");
        dispatch({ type: ADD_MANAGER_SUCCESS, payload: response.data });
        navigate("/home"); // Navigate to the /home route after success
      }
    } catch (error) {
      dispatch({ type: ADD_MANAGER_ERROR, error });
      toast.error("Failed to create manager. Please try again.");
    }
  };
};
