import { AUTH_LOGOUT_SUCCESS, AUTH_USER_REQUEST, AUTH_USER_SUCCESS, CLEAR_ERRORS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS } from "../constants/authConstants";

export const AuthReducer = (state = { user: {} }, action) => {

    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            };
        case AUTH_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                status: action.payload.status,
                // status: 200,
                // company: action.payload.company,
                role: action.payload.role,
                token: action.payload.token,
            };
        case AUTH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                role: action.payload.role,
            };

        case LOGIN_FAIL:
            return {
                loading: false,
                isAuthenticated: false,
                errors: action.payload,
                message: action.payload.msg,
                status: 401,
            };

        case AUTH_LOGOUT_SUCCESS:
            return {
                loading: false,
                isAuthenticated: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                message: null,
                status: null,
                errors: null,
            };


        default:
            return state;
    }
}