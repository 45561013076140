import { CLEAR_ERRORS, JOB_FAIL, JOB_REQUEST, JOB_SUCCESS } from "../constants/jobConstants";

export const jobReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_REQUEST:
      return {
        loading: true,
      };
    case JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.payload.jobsWithDetails,
        status: action.payload.status,
      };
    case JOB_FAIL:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };
    default:
      return state;
  }
};
