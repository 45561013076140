import React from 'react';
import './Client.css';
import ClientRightBar from '../RightBars/ClientRightBar';
import SideBar from '../../SideBar';

function Client(props) {
    return (
        <div className='mainClientContainer'>
            <SideBar />
            <ClientRightBar />
        </div>
    )
}
export default Client;