import axios from "axios";
import { BaseURL } from "../constants/BaseURL";

import { toast } from "react-toastify";
import {
  CLEAR_ERRORS,
  DELETE_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
} from "../constants/EmployeeConstants";

export const deleteEmployeeAction = (employeeId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_EMPLOYEE_REQUEST });
    const token = localStorage.getItem("token");
    console.log("🚀 ~ token:", token);
    const response = await axios.put(
      `${BaseURL}/employee/update/${employeeId}`,
      {
        isDelete: true,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("🚀 ~ response:", response);
    if (response.status === 200) {
      dispatch({
        type: DELETE_EMPLOYEE_SUCCESS,
        payload: response.data,
      });
      toast.success("Employee deleted successfully!", { theme: "colored" });
      // Reload the page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 4000); // 4000 milliseconds (4 seconds)
    }
  } catch (error) {
    dispatch({
      type: DELETE_EMPLOYEE_FAIL,
      payload: error.response.data,
    });
    toast.error("An error occurred while deleting the Employee.", {
      theme: "colored",
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
