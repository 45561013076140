import React from 'react';
import './Job.css';
import AssignedJob from '../RightBars/JobRightBar/AssignedJob';
import SideBar from '../../SideBar';

function Job(props) {
    return(
        <div className='mainJobContainer'>
<SideBar/>

        <AssignedJob/>
        </div>
    )
}
export default Job;