import React, { useEffect, useState } from "react";
import "./LeaveRequestRightBar.css";
import NavBar from "../../NavBar";
import { DataGrid } from "@mui/x-data-grid";

import checked from "../../../../assets/Icons/doneIcon.png";
import decline from "../../../../assets/Icons/decline.png";
import { useDispatch, useSelector } from "react-redux";
import { GetLeaveRequestAction } from "../../../../redux/actions/leaveRequestAction";
import { deleteLeaveAction } from "../../../../redux/actions/deleteLeaveAction";
import { acceptLeaveAction } from "../../../../redux/actions/acceptLeaveAction";

function LeaveRequestRightBar(props) {
  const [filter, setFilter] = useState("paidleaves");
  const dispatch = useDispatch();
  const { loading, leaveRequests } = useSelector((state) => state.leaveRequest);
  console.log("🚀 ~ LeaveRequestRightBar ~ leaveRequests:", leaveRequests);

  // =============================
  const [selectedRow, setSelectedRow] = useState(null);
  const [leaveRequest, setLeaveRequest] = useState(null);

  const handleRowClick = (params) => {
    setSelectedRow(params.row.id); // Store the ID of the clicked row in state
    setLeaveRequest(
      leaveRequests?.filter(
        (leaveRequest) => leaveRequest._id === params.row.id
      )
    );
  };

  // ====================================

  useEffect(() => {
    dispatch(GetLeaveRequestAction());
  }, [dispatch]);

  const handleAccept = (id) => {
    if (window.confirm("Are you sure you want to accept this Leave Request?")) {
      dispatch(acceptLeaveAction(id));
      console.log(`Accept row with ID: ${id}`);
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this Leave Request?")) {
      dispatch(deleteLeaveAction(id));
      console.log(`Delete row with ID: ${id}`);
    }
  };

  // Filter paid and unpaid leaves
  const filteredLeaves =
    leaveRequests?.filter((leave) =>
      filter === "paidleaves" ? leave.type === "paid" : leave.type === "free"
    ) || [];
  console.log("🚀 ~ LeaveRequestRightBar ~ leaveRequests:", leaveRequests);

  // Transform your API response data into rows for DataGrid
  const rows =
    filteredLeaves?.map((leaveRequest) => ({
      id: leaveRequest._id,
      name: leaveRequest.employee_details.name,
      title: leaveRequest.title,
      phone: leaveRequest.employee_details.phone,
      category: leaveRequest.employee_details.category,
      type: leaveRequest.type, // Directly use the type from response
      reason: leaveRequest.reason,
      email: leaveRequest.employee_details.email,
    })) || [];

  const columns = [
    { field: "name", headerName: "Name", width: 220 },
    { field: "title", headerName: "Title", width: 200 },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "category", headerName: "Category", width: 120 },
    filter === "unpaidleaves" && {
      field: "type",
      headerName: "Type",
      width: 100,
    },
    { field: "reason", headerName: "Reason", width: 280 },
    { field: "email", headerName: "Email", width: 220 },
    {
      width: 100,
      renderCell: (params) => (
        <div>
          <img
            src={checked}
            alt="Accept"
            className="adddltIcon"
            onClick={() => handleAccept(params.row.id)}
          />
          <img
            src={decline}
            alt="Delete"
            className="adddltIcon"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <div className="mainLeaveRequestRightBar">
      <NavBar />
      <div>
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Leave Requests</h1>
            <div className="suspendedActivebt">
              <button
                className="AllowBtn"
                onClick={() => setFilter("paidleaves")}
              >
                Paid Leave
              </button>
              <button
                className="AllowBtn"
                onClick={() => setFilter("unpaidleaves")}
              >
                Unpaid Leave
              </button>
            </div>
          </div>
        </div>
        <div className="managerItemContainer">
          <div style={{ height: "56vh", width: "80vw" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              onRowClick={handleRowClick}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
          </div>
          {selectedRow !== null && leaveRequest && leaveRequest.length > 0 && (
            <div className="popupManagerDetail">
              <div className="popup-header">
                <h2>Leave Request</h2>
                <h2
                  onClick={() => setSelectedRow(null)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "red",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: "flex", // Add this to center content vertically and horizontally
                    alignItems: "center", // Center vertically
                    justifyContent: "center", // Center horizontally
                    color: "white",
                    fontWeight: "bold", // Make the text bold
                  }}
                >
                  X
                </h2>
              </div>
              <section>
                <div className="info-col-1">
                  <div className="incidentcontainer-2">
                    <div>
                      <h1> Details</h1>
                      <br />
                      <ul>
                        <li>
                          <h5> Employee Name:</h5>
                          <span className="detail-span">
                            {leaveRequest[0]?.employee_details.name}
                          </span>
                        </li>
                        <br />

                        <li>
                          <h5> Email:</h5>
                          <span className="detail-span">
                            {leaveRequest[0]?.employee_details.email}
                          </span>
                        </li>
                        <br />
                        <li>
                          <h5>Contact: </h5>
                          <span className="detail-span">
                            {leaveRequest[0]?.employee_details.phone}
                          </span>
                        </li>
                        <br />
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="info-col-2">
                  <h1>Reason</h1>
                  <br />
                  <p>{leaveRequest[0]?.reason}</p>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaveRequestRightBar;
