import axios from "axios";
import { BaseURL } from "../../constants/BaseURL";

export const FETCH_ROSTER_REQUEST = "FETCH_ROSTER_REQUEST";
export const FETCH_ROSTER_SUCCESS = "FETCH_ROSTER_SUCCESS";
export const FETCH_ROSTER_FAILURE = "FETCH_ROSTER_FAILURE";

export const fetchCompanyRoster = (selectedDate) => {
  const token = localStorage.getItem("token");

  return (dispatch) => {
    dispatch(fetchRosterRequest());
    axios
      .post(
        `${BaseURL}/compnay/get-roaster`,
        { date: selectedDate },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      )
      .then((response) => {
        const roster = response.data;
        console.log("Roster data:", roster); // Log the response data
        dispatch(fetchRosterSuccess(roster));
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(fetchRosterFailure(errorMessage));
      });
  };
};

export const fetchRosterRequest = () => {
  return {
    type: FETCH_ROSTER_REQUEST,
  };
};

export const fetchRosterSuccess = (roster) => {
  return {
    type: FETCH_ROSTER_SUCCESS,
    payload: roster,
  };
};

export const fetchRosterFailure = (error) => {
  return {
    type: FETCH_ROSTER_FAILURE,
    payload: error,
  };
};
