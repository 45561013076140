import React from "react";
import "./Loading.css";

function SpinerLoading() {
  return (
    <div className="SpinnerCont">
      <div class="spinner"></div>
    </div>
  );
}

export default SpinerLoading;
