// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import "./AvailabilityRightBar.css";
// import { setAvailabilityAction } from "../../../../../redux/actions/availabilityActions/setAvailabilityAction";
// import { IoAddSharp } from "react-icons/io5";
// import { GetEmployeesAction } from "../../../../../redux/actions/GetEmployeesAction";

// function getTodayDate() {
//   const today = new Date();
//   const year = today.getFullYear();
//   const month = (today.getMonth() + 1).toString().padStart(2, "0");
//   const day = today.getDate().toString().padStart(2, "0");
//   return `${year}-${month}-${day}`;
// }

// function getNext7DaysWithWeekday(dateString) {
//   const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   const selectedDate = new Date(dateString);
//   const next7Days = [];

//   for (let i = 0; i < 7; i++) {
//     const nextDay = new Date(selectedDate);
//     nextDay.setDate(selectedDate.getDate() + i);
//     const dayOfWeek = daysOfWeek[nextDay.getDay()];
//     const formattedDate = nextDay.toISOString().split("T")[0];
//     next7Days.push({ date: formattedDate, dayOfWeek });
//   }

//   return next7Days;
// }

// function SetAvailability() {
//   const dispatch = useDispatch();
//   const [next7Days, setNext7Days] = useState([]);
//   const [selectedDate, setSelectedDate] = useState("");
//   const [startTime, setStartTime] = useState("");
//   const [endTime, setEndTime] = useState("");
//   const employeeId = localStorage.getItem("employeeId");
//   const [shifts, setShifts] = useState([]);
//   const [isNewShiftAdded, setIsNewShiftAdded] = useState(false);
//   const [availability, setAvailability] = useState([]);

//   //use SELECTORS:
//   const { myEmployee } = useSelector((state) => state.employee);

//   const handleDateChange = (e) => {
//     setSelectedDate(e.target.value);
//     setNext7Days(getNext7DaysWithWeekday(e.target.value));
//     setShifts(new Array(7).fill([]));
//     // setShifts(new Array(7).fill({ startTime: '', endTime: '', allDay: false }));
//     setAvailability(new Array(7).fill(false));
//   };

//   const handleAddShiftClick = (dayIndex) => {
//     const newShift = {
//       startTime,
//       endTime,
//     };

//     const updatedShifts = [...shifts];
//     updatedShifts[dayIndex] = [...(updatedShifts[dayIndex] || []), newShift];

//     setShifts(updatedShifts);
//     setStartTime("");
//     setEndTime("");
//     setIsNewShiftAdded(false);
//   };

//   const handleToggleAvailability = (index) => {
//     const updatedAvailability = [...availability];
//     updatedAvailability[index] = !updatedAvailability[index];
//     setAvailability(updatedAvailability);
//     if (updatedAvailability[index]) {
//       const updatedShifts = [...shifts];
//       // updatedShifts[index] = { startTime: '', endTime: '', allDay: false };
//       updatedShifts[index] = [];

//       setShifts(updatedShifts);
//     }
//   };

//   const handleShiftTimeChange = (dayIndex, field, value) => {
//     const updatedShifts = [...shifts];
//     updatedShifts[dayIndex] = { ...updatedShifts[dayIndex], [field]: value };
//     setShifts(updatedShifts);
//   };

//   const handleSubmit = () => {
//     if (!myEmployee || !myEmployee._id) {
//       console.error("Invalid employee data");
//       return;
//     }

//     const daysArray = next7Days.map((day, index) => {
//       return {
//         date: day.date,
//         dayOfWeek: index,
//         fullDay: availability[index],
//         shifts: availability[index] ? [] : shifts[index] || [],
//       };
//     });

//     const data = {
//       employeeId: myEmployee._id,
//       startDate: selectedDate,
//       endDate: selectedDate,
//       days: daysArray,
//     };

//     dispatch(setAvailabilityAction(data));
//     console.log(data);
//   };
//   console.log("isNewShiftAdded:", isNewShiftAdded);
//   console.log("startTime:", startTime);
//   console.log("endTime:", endTime);

//   return (
//     <div className="mainSetAvailability">
//       <h2>Set Availability</h2>
//       <div className="dayFlex">
//         <p>Select Day</p>&nbsp;&nbsp;&nbsp;
//         <input
//           type="date"
//           className="dateTextBox"
//           min={getTodayDate()}
//           onChange={handleDateChange}
//           required
//         />
//       </div>
//       <div className="grid-container">
//         {next7Days.map((day, index) => (
//           <React.Fragment key={index}>
//             <div className="shadedBox">
//               <div className="dateDay">
//                 <strong>
//                   {day.date} &nbsp;&nbsp;&nbsp;&nbsp; {day.dayOfWeek}
//                 </strong>
//                 {selectedDate && (
//                   <div>
//                     <div className="innerDiv">
//                       <div className="input-row">
//                         <label>From</label>
//                         <div className="input-row">
//                           <input
//                             type="time"
//                             value={startTime}
//                             onChange={(e) => setStartTime(e.target.value)}
//                             disabled={availability[index]}
//                             step="1"
//                           />
//                         </div>
//                       </div>
//                       <div className="input-row">
//                         <label>To</label>
//                         <div className="input-row">
//                           <input
//                             type="time"
//                             value={endTime}
//                             onChange={(e) => setEndTime(e.target.value)}
//                             disabled={availability[index]}
//                             step="1"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div
//                       className="shift"
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div>
//                         <input
//                           type="checkbox"
//                           checked={availability[index] || false}
//                           onChange={() => handleToggleAvailability(index)}
//                           style={{ background: "green" }}
//                         />{" "}
//                         Select All Day Availability
//                       </div>
//                       <div
//                         onClick={() => handleAddShiftClick(index)}
//                         disabled={!isNewShiftAdded || !startTime || !endTime}
//                       >
//                         <label>
//                           <IoAddSharp
//                             className={`add-shift-icon ${
//                               availability[index] ? "active" : ""
//                             }`}
//                             style={{ color: "green" }}
//                           />
//                           Add More Shifts
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//                 {shifts[index] &&
//                   shifts[index].map((shift, shiftIndex) => (
//                     <div key={shiftIndex} style={{ padding: "15px" }}>
//                       <p>Start Time: {shift.startTime}</p>
//                       <p>End Time: {shift.endTime}</p>
//                     </div>
//                   ))}
//               </div>
//             </div>
//           </React.Fragment>
//         ))}
//       </div>
//       <div>
//         <button
//           type="button"
//           className="managerRegister-btn"
//           onClick={handleSubmit}
//         >
//           Register
//         </button>
//       </div>
//     </div>
//   );
// }

// export default SetAvailability;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AvailabilityRightBar.css";
import { setAvailabilityAction } from "../../../../../redux/actions/availabilityActions/setAvailabilityAction";
import { IoAddSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function getNext7DaysWithWeekday(dateString) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const selectedDate = new Date(dateString);
  const next7Days = [];

  for (let i = 0; i < 7; i++) {
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + i);
    const dayOfWeek = daysOfWeek[nextDay.getDay()];
    const formattedDate = nextDay.toISOString().split("T")[0];
    next7Days.push({ date: formattedDate, dayOfWeek });
  }

  return next7Days;
}

// Helper function to format time to ISO string
const formatTimeToISO = (timeString) => {
  const today = new Date();
  const [hours, minutes, seconds] = timeString.split(":");
  today.setHours(hours, minutes, seconds);
  return today.toISOString(); // Converts to ISO string
};

function SetAvailability() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [next7Days, setNext7Days] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const employeeId = localStorage.getItem("employeeId");
  const [shifts, setShifts] = useState([]);
  const [isNewShiftAdded, setIsNewShiftAdded] = useState(false);
  const [availability, setAvailability] = useState([]);

  //use SELECTORS:
  const { myEmployee } = useSelector((state) => state.employee);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setNext7Days(getNext7DaysWithWeekday(e.target.value));
    setShifts(new Array(7).fill([]));
    setAvailability(new Array(7).fill(false));
  };

  const handleAddShiftClick = (dayIndex) => {
    const newShift = {
      startTime: formatTimeToISO(startTime),
      endTime: formatTimeToISO(endTime),
    };

    const updatedShifts = [...shifts];
    updatedShifts[dayIndex] = [...(updatedShifts[dayIndex] || []), newShift];

    setShifts(updatedShifts);
    setStartTime("");
    setEndTime("");
    setIsNewShiftAdded(false);
  };

  const handleToggleAvailability = (index) => {
    const updatedAvailability = [...availability];
    updatedAvailability[index] = !updatedAvailability[index];
    setAvailability(updatedAvailability);
    if (updatedAvailability[index]) {
      const updatedShifts = [...shifts];
      updatedShifts[index] = [];
      setShifts(updatedShifts);
    }
  };

  const handleShiftTimeChange = (dayIndex, field, value) => {
    const updatedShifts = [...shifts];
    updatedShifts[dayIndex] = updatedShifts[dayIndex].map((shift, shiftIndex) =>
      shiftIndex === field
        ? { ...shift, [field]: formatTimeToISO(value) }
        : shift
    );
    setShifts(updatedShifts);
  };

  const handleSubmit = () => {
    if (!myEmployee || !myEmployee._id) {
      console.error("Invalid employee data");
      return;
    }

    const daysArray = next7Days.map((day, index) => {
      return {
        date: day.date,
        dayOfWeek: index,
        fullDay: availability[index],
        shifts: availability[index] ? [] : shifts[index] || [],
      };
    });

    const data = {
      employeeId: myEmployee._id,
      startDate: selectedDate,
      endDate: selectedDate,
      days: daysArray,
    };

    dispatch(setAvailabilityAction(data, navigate));
    // navigate("/home");
  };

  return (
    <div className="mainSetAvailability">
      <h2>Set Availability</h2>
      <div className="dayFlex">
        <p>Select Day</p>&nbsp;&nbsp;&nbsp;
        <input
          type="date"
          className="dateTextBox"
          min={getTodayDate()}
          onChange={handleDateChange}
          required
        />
      </div>
      <div className="grid-container">
        {next7Days.map((day, index) => (
          <React.Fragment key={index}>
            <div className="shadedBox">
              <div className="dateDay">
                <strong>
                  {day.date} &nbsp;&nbsp;&nbsp;&nbsp; {day.dayOfWeek}
                </strong>
                {selectedDate && (
                  <div>
                    <div className="innerDiv">
                      <div className="input-row">
                        <label>From</label>
                        <div className="input-row">
                          <input
                            type="time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            disabled={availability[index]}
                            step="1"
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <label>To</label>
                        <div className="input-row">
                          <input
                            type="time"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            disabled={availability[index]}
                            step="1"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="shift"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <input
                          type="checkbox"
                          checked={availability[index] || false}
                          onChange={() => handleToggleAvailability(index)}
                          style={{ background: "green" }}
                        />{" "}
                        Select All Day Availability
                      </div>
                      <div
                        onClick={() => handleAddShiftClick(index)}
                        disabled={!isNewShiftAdded || !startTime || !endTime}
                      >
                        <label>
                          <IoAddSharp
                            className={`add-shift-icon ${
                              availability[index] ? "active" : ""
                            }`}
                            style={{ color: "green" }}
                          />
                          Add More Shifts
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {shifts[index] &&
                  shifts[index].map((shift, shiftIndex) => (
                    <div key={shiftIndex} style={{ padding: "15px" }}>
                      <p>Start Time: {shift.startTime}</p>
                      <p>End Time: {shift.endTime}</p>
                    </div>
                  ))}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div>
        <button
          type="button"
          className="managerRegister-btn"
          onClick={handleSubmit}
        >
          Register
        </button>
      </div>
    </div>
  );
}

export default SetAvailability;
