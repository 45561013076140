import React, { useState, useEffect } from "react";
import "./ManagerRightBar.css";
import NavBar from "../../NavBar";
import { DataGrid } from "@mui/x-data-grid";

import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import deleteIcon from "../../../../assets/Icons/deleteIcon.png";
import addnewIcon from "../../../../assets/Icons/addnewIcon.png";
import doneIcon from "../../../../assets/Icons/doneIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { GetManagerAction } from "../../../../redux/actions/managerAction";
import { deleteManagerAction } from "../../../../redux/actions/deleteManagerAction";
import { addManager } from "../../../../redux/actions/createActions/createManagerAction";
import SpinerLoading from "../../../Loading";

function ManagerRightBar(props) {
  const navigate = useNavigate();

  const [addManagerPopup, setAddManagerPopup] = useState(false);
  const [donePopup, setDonePopup] = useState(false);
  const [image, setImage] = useState();
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    managerType: "",
    imageUrl: "",
    driving_license: "",
    category: "",
    DOB: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    emptyImage: "",
    emptyFields: "",
    shortPassword: "",
  });
  const dispatch = useDispatch();
  const { loading, managers, status } = useSelector((state) => state.manager);
  console.log("🚀 ~ ManagerRightBar ~ managers:", managers);

  const handleAddManagerClick = () => {
    setAddManagerPopup(true);
  };
  const closeAddManagerPopup = () => {
    setAddManagerPopup(false);
  };
  // const closeDonePopup = () => {
  //   setDonePopup(false);
  // };
  const [selectedRow, setSelectedRow] = useState(null);
  const [manager, setManager] = useState(null);

  const handleRowClick = (params) => {
    setSelectedRow(params.row.id);
    setManager(managers?.filter((manager) => manager._id === params.row.id));
  };
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }
  const handleInputChange = (event) => {
    const newData = { ...data };
    const { name, value } = event.target;
    if (event.target.name === "file") {
      setImage(event.target.files[0]);
    } else {
      // setData(newData);
      setData({ ...data, [name]: value });
    }
    // setData({ ...data, [event.target.name]: event.target.value })
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!data.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!data.address.trim()) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    if (!data.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }
    if (!image) {
      newErrors.emptyImage = "Image is required";
    }

    if (!data.phone.trim()) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10,13}$/.test(data.phone)) {
      newErrors.phone = "Phone number must be between 10 and 13 digits";
      isValid = false;
    }
    if (!data.password.trim()) {
      newErrors.shortPassword = "Password number is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleRegisterClick = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("password", data.password);
      formData.append("DOB", data.DOB);
      formData.append("address", data.address);
      formData.append("managerType", data.managerType);
      formData.append("category", data.category);
      formData.append("driving_license", data.driving_license);
      formData.append("imageUrl", image);

      await dispatch(addManager(formData, navigate));
      // console.log(data);
      // setDonePopup(true);
    }
  };
  useEffect(() => {
    if (status && status === 201) {
      setDonePopup(true);

      toast.success("Manager created successfully", { theme: "colored" });
    }
  }, [navigate, status, managers]);

  function DoneOk() {
    navigate(`/home`);
    setDonePopup(false);
  }
  useEffect(() => {
    dispatch(GetManagerAction());
  }, [dispatch]);
  // const handleEdit = (id) => {
  //     console.log(`Edit row with ID: ${id}`);
  // };
  const handleDelete = (id) => {
    // Handle delete action here
    if (window.confirm("Are you sure you want to delete this Manager?")) {
      dispatch(deleteManagerAction(id));
      console.log(`Delete row with ID: ${id}`);
    }
  };

  const rows =
    managers?.map((manager) => ({
      id: manager?._id,
      image: manager?.imageUrl,
      Name: manager?.name, // Assuming there's a 'name' field in your manager data
      email: manager?.email,
      Role: manager?.role, // Assuming there's a 'role' field in your manager data
      workingHour: manager?.workingHour,
      address: manager?.address,
    })) || [];

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
    },
    { field: "Name", headerName: "Name", width: 220 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "Role", headerName: "Role", width: 170 },
    { field: "workingHour", headerName: "Working Hours", width: 150 },
    { field: "address", headerName: "Address", width: 280 },
    {
      width: 100,
      renderCell: (params) => (
        <div>
          {/* <img
                        src={updateIcon}
                        alt='Edit'
                        className='actionIcon'
                        onClick={() => handleEdit(params.row.id)}
                    /> */}
          <img
            src={deleteIcon}
            alt="Delete"
            className="actionIcon"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="mainManagerRightBar">
      <NavBar />
      <div>
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Manager Details</h1>
            {/* <span className="dashboardSpan">Lorem ipsum dolor sit amet</span> */}
          </div>
          <div>
            <button className="createButton" onClick={handleAddManagerClick}>
              <img src={addnewIcon} alt="Add New" className="addIcon" />
              Add New Manager
            </button>
            <div>
              {addManagerPopup && (
                <div className="clientmain">
                  <div className="popupClientForm">
                    <div className="popup-header">
                      <h2>Add New Manager</h2>
                      <h2
                        onClick={closeAddManagerPopup}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "red",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          display: "flex", // Add this to center content vertically and horizontally
                          alignItems: "center", // Center vertically
                          justifyContent: "center", // Center horizontally
                          color: "white",
                          fontWeight: "bold", // Make the text bold
                        }}
                      >
                        X
                      </h2>
                    </div>
                    <form id="form" className="mainForm">
                      <section className="clientFormContainer">
                        <div className="clientCol-1">
                          <label>
                            Name
                            <br />
                            <input
                              type="text"
                              className="managerTextBox"
                              name="name"
                              value={data.name}
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.name}</div>
                          </label>
                          <br />
                          <label>
                            Email <br />
                            <input
                              type="text"
                              className="managerTextBox"
                              name="email"
                              value={data.email}
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.email}</div>
                          </label>
                          <br />
                          <label>
                            Contact <br />
                            <input
                              type="number"
                              className="managerTextBox"
                              name="phone"
                              value={data.phone}
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.phone}</div>
                          </label>
                          <br />
                          <label>
                            Driving License <br />
                            <select
                              className="managerDropDown"
                              name="driving_license"
                              value={data.driving_license}
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                Select option
                              </option>
                              <option value="valid">valid</option>
                              <option value="invalid">invalid</option>
                            </select>
                          </label>

                          <br />
                          <br />
                          <label>
                            Manager Type: <br />
                            <select
                              className="managerDropDown"
                              name="managerType"
                              value={data.managerType}
                              onChange={handleInputChange}
                            >
                              <option value="" disabled>
                                Select Type
                              </option>
                              <option value="casual">Casual</option>
                              <option value="permanent">Permanent</option>
                            </select>
                          </label>

                          <br />
                          <br />
                        </div>
                        <div className="clientCol-2">
                          <label>
                            Date of Birth:
                            <input
                              type="date"
                              name="DOB"
                              value={data.DOB}
                              onChange={handleInputChange}
                            />
                          </label>
                          <label>
                            <br />
                            Address <br />
                            <input
                              type="text"
                              className="managerTextBox"
                              name="address"
                              value={data.address}
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.address}</div>
                          </label>
                          <br />
                          <label>
                            Password <br />
                            <input
                              type="text"
                              className="managerTextBox"
                              name="password"
                              value={data.password}
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.shortPassword}</div>
                          </label>
                          <br />
                          <label>
                            Manager Category: <br />
                            <select
                              className="managerDropDown"
                              name="category"
                              value={data.category}
                              onChange={handleInputChange}
                            >
                              {/* <option value="role1">Select Type</option> */}
                              <option value="Project Manager">
                                Project Manager
                              </option>
                              <option value="General Manager">
                                General Manager
                              </option>
                              <option value="Director">Director</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Image Upload:
                            <input
                              type="file"
                              name="file"
                              accept="imageUrl/*"
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.emptyImage}</div>
                          </label>
                          <br />
                          <div className="error">{errors.emptyFields}</div>
                          <button
                            className="managerRegister-btn"
                            onClick={handleRegisterClick}
                          >
                            Register
                          </button>
                        </div>
                      </section>
                      <br />
                    </form>
                  </div>
                </div>
              )}
              {donePopup && (
                <div className="main">
                  <div className="done-popUpFrame1">
                    <div className="done-popupFrame2">
                      <div className="done-popupFrame3">
                        <img
                          src={doneIcon}
                          alt="Done"
                          className="doneIconStyle"
                        />
                        <h1 className="done">Done!</h1>
                        <h5>Manager Added Successfully</h5>
                        <buttonon Click={DoneOk}>Ok</buttonon>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ManagerItemContainer">
        <div className="table-container">
          <div className="" style={{ height: "56vh", width: "80vw" }}>
            {loading ? (
              <SpinerLoading />
            ) : (
              <DataGrid
                className="mainTabel"
                rows={rows}
                columns={columns}
                onRowClick={handleRowClick} // Attach the event handler
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 18 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            )}
          </div>
        </div>
        {selectedRow !== null && (
          <div className="popupManagerDetail">
            <div className="popup-header">
              <h2>Manager Details</h2>
              <h2
                onClick={() => setSelectedRow(null)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex", // Add this to center content vertically and horizontally
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                  color: "white",
                  fontWeight: "bold", // Make the text bold
                }}
              >
                X
              </h2>
            </div>
            <section>
              <div className="info-col-1">
                <div className="container-1">
                  <div className="profile">
                    <div className="profile-img">
                      <img
                        style={{
                          width: "120px",
                          height: "90px",
                          borderRadius: 50,
                        }}
                        src={manager[0]?.imageUrl}
                      />
                    </div>
                    <div className="profile-name">
                      <h3> {manager[0]?.name}</h3>
                    </div>
                  </div>
                </div>
                <div className="container-2">
                  <div>
                    <h1> Details</h1>
                    <br />
                    <ul>
                      <li>{/* <h5> Company Name: [manager[0]?.]</h5> */}</li>
                      <li>
                        <h5> Company Id:</h5>
                        <span className="detail-span">
                          {manager[0]?.companyId}
                        </span>
                      </li>
                      <li>
                        <h5>Email: </h5>
                        <span className="detail-span">{manager[0]?.email}</span>
                      </li>
                      <li>
                        <h5>Contact: </h5>
                        <span className="detail-span">{manager[0]?.phone}</span>
                      </li>
                      <li>
                        <h5>Date of Birth: </h5>
                        <span className="detail-span">
                          {manager[0]?.DOB ? formatDate(manager[0].DOB) : "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Address: </h5>
                        <span className="detail-span">
                          {manager[0]?.address}
                        </span>
                      </li>
                      <li>
                        <h5>Manager Type: </h5>
                        <span className="detail-span">
                          {manager[0]?.managerType}
                        </span>
                      </li>
                      <li>
                        <h5>Category: </h5>
                        <span className="detail-span">
                          {manager[0]?.category}
                        </span>
                      </li>
                      <li>
                        <h5>Driving License: </h5>
                        <br />
                        <span className="detail-span">
                          {manager[0]?.driving_license}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-col-2">
                <div className="description">
                  <h1>Description</h1>
                  <p>this is description of manager.</p>
                </div>
                <br />
                <div className="document">
                  <h1>Documents</h1>
                  <file className="textBox">
                    Document: {manager[0]?.documnet}
                  </file>
                  <br />
                  <br />
                  {/* <file className='textBox' >Document 2</file><br /><br />
                                    <file className='textBox' >Document 3</file><br /><br /> */}
                </div>
              </div>
            </section>
            {/* Popup content goes here */}
            {/* <button onClick={() => setSelectedRow(null)}>Close Popup</button> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ManagerRightBar;
