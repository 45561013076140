import axios from "axios";
import { BaseURL } from "../constants/BaseURL";

import { toast } from "react-toastify";
import { CLEAR_ERRORS, DELETE_MANAGER_FAIL, DELETE_MANAGER_REQUEST, DELETE_MANAGER_SUCCESS } from "../constants/managerConstants";
export const deleteManagerAction = (managerId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_MANAGER_REQUEST });
    const token = localStorage.getItem('token');
    const response = await axios.delete(`${BaseURL}/manager/${managerId}/delete-manager`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });
    if (response.status === 200) {
        dispatch({
          type: DELETE_MANAGER_SUCCESS,
          payload: response.data,
        });
        toast.success('Manager deleted successfully!', { theme: 'colored' });
      // Reload the page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 4000); // 4000 milliseconds (4 seconds)
      } 
    } catch (error) {
      dispatch({
        type: DELETE_MANAGER_FAIL,
        payload: error.response.data,
      });
      toast.error('An error occurred while deleting the Manager.', { theme: 'colored' });
    }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
