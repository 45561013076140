// chatActions.js

import axios from 'axios';
import { BaseURL } from '../../constants/BaseURL';

export const FETCH_CHATS_REQUEST = 'FETCH_CHATS_REQUEST';
export const FETCH_CHATS_SUCCESS = 'FETCH_CHATS_SUCCESS';
export const FETCH_CHATS_FAILURE = 'FETCH_CHATS_FAILURE';

const fetchChatsRequest = () => ({
  type: FETCH_CHATS_REQUEST,
});

const fetchChatsSuccess = (chats) => ({
  type: FETCH_CHATS_SUCCESS,
  payload: chats,
});

const fetchChatsFailure = (error) => ({
  type: FETCH_CHATS_FAILURE,
  payload: error,
});

export const fetchChats = (userId) => {
  return (dispatch) => {
    dispatch(fetchChatsRequest());
    
    const url = `${BaseURL}/chat/${userId}`;
    
    axios.get(url)
      .then((response) => {
        dispatch(fetchChatsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(fetchChatsFailure(error.message));
      });
  };
};
