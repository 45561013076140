import React, { useEffect, useState } from "react";
import { socket } from "../../../../../socket";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../../../redux/actions/getUserProfileAction";
import { fetchMessages } from "../../../../../redux/actions/chatActions/getMessageActions";
import { IoMdSend } from "react-icons/io";

function Input({ addMessage, chatId, senderId, reciverId }) {
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userProfile.user);
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (chatId) {
      dispatch(fetchMessages(chatId));
    }
  }, [dispatch]);

  const userId = user ? user._id : null;
  const handleSubmit = (e) => {
    e.preventDefault();

    const newMessage = {
      chatId: chatId,
      content: message,
      senderId: senderId,
      receiverId: reciverId,
    };
    console.log("🚀 ~ handleSubmit ~ newMessage:", newMessage);

    // Call the parent component's function to update messages
    addMessage(newMessage);

    // Emit the message to the server
    socket.emit("send-message", newMessage);
    // Clear the input field
    setMessage("");
  };

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type your message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button className="send" onClick={handleSubmit}>
        Send
        <IoMdSend />
      </button>
    </div>
  );
}

export default Input;
