import React, { useEffect, useState } from "react";
import "./IncidentReportRightBar.css";
import NavBar from "../../NavBar";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";

import updateIcon from "../../../../assets/Icons/updateIcon.png";
import deleteIcon from "../../../../assets/Icons/deleteIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchIncidentData } from "../../../../redux/actions/incidentActions";
import Nodata from "../../../../assets/Nodata.png";

function formatTime(dateTime) {
  const date = new Date(dateTime);
  const formattedDate = date.toLocaleDateString(); // Format date
  const formattedTime = date.toLocaleTimeString(); // Format time
  return { date: formattedDate, time: formattedTime };
}

function IncidentReportRightBar(props) {
  const handleDownload = () => {
    // Create an invisible anchor element
    const anchor = document.createElement("a");
    anchor.href = incidentData[0].imageUrl;
    anchor.download = "incident_document.pdf"; // You can specify the desired file name here
    anchor.style.display = "none";

    // Append the anchor to the body and trigger a click event
    document.body.appendChild(anchor);
    anchor.click();

    // Remove the anchor from the body
    document.body.removeChild(anchor);
  };

  const dispatch = useDispatch();
  // const { incidentData } = useSelector((state) => state.incident); // Assuming you have a reducer for incident data
  const { loading, incidentData, errors } = useSelector(
    (state) => state.incident
  );

  const [selectedRow, setSelectedRow] = useState(null);
  const [incidentReport, setIncidentReport] = useState(null);

  const handleRowClick = (params) => {
    setSelectedRow(params.row.id); // Store the ID of the clicked row in state
    setIncidentReport(
      incidentData?.filter(
        (incidentReport) => incidentReport._id === params.row.id
      )
    );
  };
  console.log(incidentReport);

  const handleEdit = (id) => {
    // Handle edit action here
    console.log(`Edit row with ID: ${id}`);
  };
  const handleDelete = (id) => {
    // Handle delete action here
    console.log(`Delete row with ID: ${id}`);
  };
  useEffect(() => {
    dispatch(fetchIncidentData());
  }, [dispatch]);

  const formattedData = incidentData?.map((incident) => {
    const formattedTimeData = formatTime(incident.time);
    return { ...incident, ...formattedTimeData };
  });

  const rows =
    formattedData?.map((incident) => ({
      id: incident._id,
      imageUrl: incident.imageUrl,
      staffName: incident.staffName,
      clientName: incident.clientName,
      type: incident.type,
      date: incident.date, // Update this line
      time: incident.time,
      staffDesignation: incident.staffDesignation, // Note the capital 'D'
      location: incident.location,
      jobId: incident.jobId ? incident.jobId.slice(0, 6) : "",
    })) || [];

  const columns = [
    {
      field: "imageUrl",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
    },
    { field: "staffName", headerName: "Employee Name", width: 230 },
    { field: "clientName", headerName: "Client Name", width: 170 },
    { field: "jobId", headerName: "Shift ID", width: 230 },
    { field: "type", Clockify: "Incident Type", width: 150 },
    { field: "date", headerName: "Date", width: 100 },
    { field: "time", headerName: "Time", width: 100 },
    // { field: 'staffDesignation', headerName: 'Staff Designation', width: 200 },
    { field: "location", headerName: "Location", width: 200 },
    // {
    //     width: 100,
    //     renderCell: (params) => (
    //         <div>
    //             <img
    //                 src={deleteIcon}
    //                 alt='Delete'
    //                 className='actionIcon'
    //                 onClick={() => handleDelete(params.row.id)}
    //             />
    //         </div>
    //     ),
    // },
  ];
  const sortModel = [
    {
      field: "date", // Replace 'date' with the actual field name you want to sort by
      field: "time",
      sort: "desc", // 'asc' for ascending, 'desc' for descending
    },
  ];

  const initialState = {
    pagination: {
      pageSize: 18,
    },
    sortModel,
  };

  return (
    <div className="mainIncidentReportRightBarContainer">
      <NavBar />
      <div>
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Incident Reports</h1>
          </div>
        </div>
        <div className="managerItemContainer">
          <div className="" style={{ height: "56vh", width: "80vw" }}>
            {incidentData ? (
              <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={handleRowClick} // Attach the event handler
                pageSizeOptions={[5, 10]}
                checkboxSelection
                sortModel={sortModel}
                initialState={initialState}
              />
            ) : (
              <div className="NodataAvaible">
                <img className="NodataAvaible-img" src={Nodata} />
                No Incidents Repoted ...
              </div>
            )}
          </div>
        </div>

        {selectedRow !== null && (
          <div className="popupManagerDetail">
            <div className="popup-header">
              <h2>Incident Report</h2>
              <h2
                onClick={() => setSelectedRow(null)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex", // Add this to center content vertically and horizontally
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                  color: "white",
                  fontWeight: "bold", // Make the text bold
                }}
              >
                X
              </h2>
            </div>
            <section>
              <div className="info-col-1">
                <div className="incidentcontainer-2">
                  <div>
                    <h1> Details</h1>
                    <ul>
                      <li>
                        <h5> Report Id:</h5>
                        <span className="detail-span">
                          {incidentData[0]?._id
                            ? incidentData[0]._id.slice(0, 6)
                            : ""}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5> Employee Name:</h5>
                        <span className="detail-span">
                          {incidentData[0]?.staffName}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5> Job Id:</h5>
                        <span className="detail-span">
                          {incidentData[0]?.jobId
                            ? incidentData[0]?.jobId.slice(0, 6)
                            : ""}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5> Client Name:</h5>
                        <span className="detail-span">
                          {incidentData[0]?.clientName}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5> Incident Type:</h5>
                        <span className="detail-span">
                          {incidentData[0]?.type}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5> Action:</h5>
                        <span className="detail-span">
                          {incidentData[0]?.action}
                        </span>
                      </li>
                      <br />
                      <li>
                        <h5>Address: </h5>
                        <span className="detail-span">
                          {incidentData[0]?.location}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="info-col-2">
                <div className="description">
                  <h1>Description</h1>
                  <br />
                  <p>{incidentData[0]?.description}</p>
                </div>
                <br />
                <div className="document">
                  <h1>Documents</h1>
                  <div>
                    <button
                      onClick={handleDownload}
                      style={{
                        background: "white",
                        border: "white",
                        color: "green",
                        display: "flex",
                        fontSize: "16px",
                      }}
                    >
                      <FontAwesomeIcon icon={faCloudDownload} />{" "}
                      <p style={{ color: "black", fontSize: "16px" }}>
                        &nbsp;&nbsp;&nbsp;Incident Document
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}
export default IncidentReportRightBar;
