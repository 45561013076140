import { BaseURL } from "../../constants/BaseURL";
import {
  ADD_ROSTER_FAILURE,
  ADD_ROSTER_REQUEST,
  ADD_ROSTER_SUCCESS,
} from "../../constants/rosterConstants";
import { toast } from "react-toastify";

export const addRoster = (rosterData) => {
  return (dispatch) => {
    dispatch({ type: ADD_ROSTER_REQUEST });
    const token = localStorage.getItem("token");

    fetch(`${BaseURL}/manager/create-roaster`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify(rosterData),
    })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response.ok) {
          toast.success("Roster Created Successfully");
        }
        return response.json();
      })
      .then((data) => {
        dispatch({
          type: ADD_ROSTER_SUCCESS,
          payload: data.message,
        });
      })
      .catch((error) => {
        toast.error(
          error.message || "Failed to add roster. Please try again later."
        );
        dispatch({
          type: ADD_ROSTER_FAILURE,
          payload:
            error.message || "Failed to add roster. Please try again later.",
        });
      });
  };
};
