import {
  FETCH_CHAT_FAILURE,
  FETCH_CHAT_SUCCESS,
} from "../../actions/chatActions/createChatActions";

// import { SEND_MESSAGE_FAILURE, SEND_MESSAGE_REQUEST, SEND_MESSAGE_SUCCESS } from "../../actions/chatActions/createChatActions";

const initialState = {
  chatData: null,
  error: null,
  sendingMessage: false,
  sentMessage: null,
  sendMessageError: null,
};

const createChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHAT_SUCCESS:
      return {
        ...state,
        chatData: action.payload.chatData,
        error: null,
      };
    case FETCH_CHAT_FAILURE:
      return {
        ...state,
        chatData: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default createChatReducer;

// // Import necessary actions

// // Initial state
// const initialState = {
//   sendingMessage: false,
//   sentMessage: null,
//   sendMessageError: null,
//   // Add a messages array to store sent messages
//   messages: [],
// };

// // Reducer
// const createChatReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SEND_MESSAGE_REQUEST:
//       return {
//         ...state,
//         sendingMessage: true,
//         sentMessage: null,
//         sendMessageError: null,
//       };
//     case SEND_MESSAGE_SUCCESS:
//       return {
//         ...state,
//         sendingMessage: false,
//         sentMessage: action.payload,
//         sendMessageError: null,
//         // Add the sent message to the messages array
//         messages: [...state.messages, action.payload],
//       };
//     case SEND_MESSAGE_FAILURE:
//       return {
//         ...state,
//         sendingMessage: false,
//         sentMessage: null,
//         sendMessageError: action.payload,
//       };
//     // Handle other actions if needed
//     default:
//       return state;
//   }
// };

// export default createChatReducer;
