import axios from "axios";
import { BaseURL } from "../constants/BaseURL";
import {
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
} from "../constants/userProfileConstants";
import { connectUserToSocket } from "./userSocketActions";

const fetchUserProfileRequest = () => ({
  type: FETCH_USER_PROFILE_REQUEST,
});

const fetchUserProfileSuccess = (data) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: data,
});

const fetchUserProfileFailure = (error) => ({
  type: FETCH_USER_PROFILE_FAILURE,
  payload: error,
});

export const fetchUserProfile = () => {
  return (dispatch) => {
    dispatch(fetchUserProfileRequest());
    const token = localStorage.getItem("token");

    axios
      .get(`${BaseURL}/compnay/get-profile`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((response) => {
        dispatch(fetchUserProfileSuccess(response.data));

        // Extract user ID from the response and dispatch connectUserToSocket action
        console.log("🚀 ~ .then ~ response:", response);
        const userId = response.data.user._id;
        dispatch(connectUserToSocket(userId));
      })
      .catch((error) => {
        dispatch(fetchUserProfileFailure(error));
      });
  };
};
