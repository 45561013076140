import axios from "axios";
import { BaseURL } from "../constants/BaseURL";

import { toast } from "react-toastify";
import { CLEAR_ERRORS, DELETE_LEAVE_FAIL, DELETE_LEAVE_REQUEST, DELETE_LEAVE_SUCCESS } from "../constants/LeaveRequestConstants";

export const deleteLeaveAction = (requestId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_LEAVE_REQUEST });
    const token = localStorage.getItem('token');
    const response = await axios.delete(`${BaseURL}/manager/leave-request/${requestId}/delete`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });
    if (response.status === 200) {
        dispatch({
          type: DELETE_LEAVE_SUCCESS,
          payload: response.data,
        });
        toast.success('Request Denied!', { theme: 'colored' });
      // Reload the page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 3000); // 4000 milliseconds (4 seconds)
      } 
    } catch (error) {
      dispatch({
        type: DELETE_LEAVE_FAIL,
        payload: error.response.data,
      });
      toast.error('An error occurred while deleting leave request.', { theme: 'colored' });
    }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
