import React, { useEffect, useState } from "react";
import "./EmployeeRightBar.css";
import NavBar from "../../NavBar";
import { DataGrid } from "@mui/x-data-grid";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetEmployeesAction } from "../../../../redux/actions/GetEmployeesAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../../../assets/Icons/deleteIcon.png";
import addnewIcon from "../../../../assets/Icons/addnewIcon.png";
import doneIcon from "../../../../assets/Icons/doneIcon.png";
import earningIcon from "../../../../assets/Icons/earningIcon.png";
import shiftIcon from "../../../../assets/Icons/shiftIcon.png";
import hourIcon from "../../../../assets/Icons/hourIcon.png";
import { deleteEmployeeAction } from "../../../../redux/actions/deleteEmployee";
import { postEmployee } from "../../../../redux/actions/createActions/createEmployeeAction";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ImCross } from "react-icons/im";
import { GetManagerAction } from "../../../../redux/actions/GetManagerAction";
import SpinerLoading from "../../../Loading";

function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}
function EmployeeRightBar(props) {
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRegistered, setIsRegistered] = useState(false);
  const [filter, setFilter] = useState("active");
  const [addEmployeePopup, setAddEmployeePopup] = useState(false);
  //   const [donePopup, setDonePopup] = useState(false);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const initialDOB = formattedDate.substr(0, 10);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    employeeType: "",
    driving_license: "",
    category: "",
    DOB: "",
    daynormal: 0,
    daysaturday: 0,
    daysunday: 0,
    dayholidays: 0,
    nightnormal: 0,
    nightsaturday: 0,
    nightsunday: 0,
    nightholidays: 0,
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    DOB: "",
    address: "",
    emptyFields: "",
    shortPassword: "",
  });

  const {
    loading,
    employees = [],
    status,
    myEmployee,
  } = useSelector((state) => state.employee);

  console.log("🚀 ~emplooee", employees);
  console.log("🚀 ~myEmployee", myEmployee);
  const handleAddEmployeeClick = () => {
    setAddEmployeePopup(true);
  };
  const closeAddEmployeePopup = () => {
    setAddEmployeePopup(false);
  };
  const [selectedRow, setSelectedRow] = useState(null);
  const [employee, setEmployee] = useState(null);
  const handleRowClick = (params) => {
    setSelectedRow(params.row.id);
    setEmployee(
      employees?.filter((employee) => employee._id === params.row.id)
    );
  };

  const handleInputChange = (event) => {
    const newData = { ...data };
    setData({ ...data, [event.target.name]: event.target.value });

    // Clear previous errors when the user starts typing
    setErrors({ ...errors, [event.target.name]: "" });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (
      !data.name ||
      !data.email ||
      !data.phone ||
      !data.address ||
      !data.DOB
    ) {
      newErrors.emptyFields = "All fields are required";
    }
    if (!data.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!data.address.trim()) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    if (!data.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }

    if (!data.phone.trim()) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10,13}$/.test(data.phone)) {
      newErrors.phone = "Phone number must be between 10 and 13 digits";
      isValid = false;
    }
    if (!data.password.trim()) {
      newErrors.shortPassword = "Password number is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleRegisterClick = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      let formdata = JSON.stringify({
        name: data.name,
        email: data.email,
        DOB: data.DOB,
        password: data.password,
        phone: data.phone,
        driving_license: data.driving_license,
        employeeType: data.employeeType,
        category: data.category,
        address: data.address,
        dayShiftPaymentRates: {
          normal: Number(data.daynormal),
          saturday: Number(data.daysaturday),
          sunday: Number(data.daysunday),
          holidays: Number(data.dayholidays),
        },
        nightShiftPaymentRates: {
          normal: Number(data.nightnormal),
          saturday: Number(data.nightsaturday),
          sunday: Number(data.nightsunday),
          holidays: Number(data.nightholidays),
        },
      });
      await dispatch(postEmployee(formdata));
    }
  };

  // const handleRegisterClick = async (event) => {
  //   event.preventDefault();
  //   if (validateForm()) {
  //     let formdata = JSON.stringify({
  //       name: data.name,
  //       email: data.email,
  //       DOB: data.DOB,
  //       password: data.password,
  //       phone: data.phone,
  //       driving_license: data.driving_license,
  //       employeeType: data.employeeType,
  //       category: data.category,
  //       address: data.address,
  //       dayShiftPaymentRates: {
  //         normal: data.daynormal,
  //         saturday: data.daysaturday,
  //         sunday: data.daysunday,
  //         holidays: data.dayholidays,
  //       },
  //       nightShiftPaymentRates: {
  //         normal: data.nightnormal,
  //         saturday: data.nightsaturday,
  //         sunday: data.nightsunday,
  //         holidays: data.nightholidays,
  //       },
  //     });
  //     await dispatch(postEmployee(formdata));
  //   }
  // };

  useEffect(() => {
    if (status && status === 204) {
      setIsRegistered(true);

      toast.success("Employee created successfully", { theme: "colored" });
      localStorage.setItem("employeeId", myEmployee._id);
    }
  }, [navigate, status, myEmployee]);

  function DoneOk() {
    navigate(`/setAvailability?employee/${myEmployee?._id}`);
  }
  useEffect(() => {
    dispatch(GetEmployeesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetManagerAction());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this Employee?")) {
      dispatch(deleteEmployeeAction(id));
    }
  };

  const filteredEmployees =
    employees?.filter((employee) =>
      filter === "active" ? !employee.isDelete : employee.isDelete
    ) || [];
  console.log("🚀 ~ EmployeeRightBar ~ filteredEmployees:", filteredEmployees);

  const rows =
    filteredEmployees?.map((employee) => ({
      id: employee._id,
      name: employee.name,
      email: employee.email,
      phone: employee.phone,
      category: employee.category,
      employeeType: employee.employeeType,
      address: employee.address,
      status: employee.isDelete ? "Deleted" : "Active",
    })) || [];
  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
    },
    { field: "name", headerName: "Name", width: 220 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "phone", headerName: "Phone", width: 170 },
    { field: "category", headerName: "Category", width: 200 },
    { field: "employeeType", headerName: "Employee Type", width: 200 },
    { field: "address", headerName: "Address", width: 250 },
    filter === "suspended" && {
      field: "status",
      headerName: "Status",
      width: 220,
    },
    {
      width: 100,
      renderCell: (params) => (
        <div>
          <img
            src={deleteIcon}
            alt="Delete"
            className="actionIcon"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <div className="mainEmployeeRightBar">
      <NavBar />
      <div>
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Employee Details</h1>
            {/* <span className="dashboardSpan">Lorem ipsum dolor sit amet </span> */}
            {/* {role && role === "Owner" && (
              <> */}
            <div className="suspendedActivebt">
              <button className="AllowBtn " onClick={() => setFilter("active")}>
                Active
              </button>
              <button
                className="AllowBtn "
                onClick={() => setFilter("suspended")}
              >
                Suspended
              </button>
            </div>
            {/* </>
            )} */}
          </div>
          <div>
            {role && role === "Manager" && (
              <button className="createButton" onClick={handleAddEmployeeClick}>
                <img src={addnewIcon} alt="Add New" className="addIcon" />
                Add New Employee
              </button>
            )}
            <div>
              {addEmployeePopup && (
                <div className="clientmain">
                  <div className="popupClientForm">
                    <div className="popup-header">
                      <h2>Add New Employee</h2>
                      <ImCross
                        style={{
                          padding: "3px",
                          color: "white",
                          background: "red",
                          height: "27px",
                          width: "27px",
                          borderRadius: "100%",
                        }}
                        onClick={closeAddEmployeePopup}
                      />
                    </div>
                    <form id="clientForm" className="mainForm" method="POST">
                      <section className="clientFormContainer">
                        <div className="clientCol-1">
                          <label>
                            Name
                            <br />
                            <input
                              type="text"
                              className="clientTextBox"
                              name="name"
                              value={data.name}
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.name}</div>
                          </label>
                          <br />
                          <label>
                            Email <br />
                            <input
                              type="text"
                              className="clientTextBox"
                              value={data.email}
                              name="email"
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.email}</div>
                          </label>
                          <br />
                          <label>
                            Contact <br />
                            <input
                              type="number"
                              className="clientTextBox"
                              value={data.phone}
                              name="phone"
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.phone}</div>
                          </label>
                          <br />
                          <label>
                            Date of Birth <br />
                            <input
                              type="date"
                              className="clientTextBox"
                              value={data.DOB}
                              name="DOB"
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.DOB}</div>
                          </label>
                          <br />
                          <label>
                            Employee Type : <br />
                            <select
                              className="clientDropDown"
                              value={data.employeeType}
                              name="employeeType"
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              <option value="casual">Casual</option>
                              <option value="permanent">Permanent</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Paymet Rate <br />
                            <br />
                            <div
                              className="Group1000001308"
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              }}
                            >
                              <div className="Rectangle2646" />
                              <div
                                className="Rectangle2645"
                                style={{
                                  width: 107,
                                  height: 219,
                                  left: 0,
                                  top: 0,
                                  position: "absolute",
                                  background: "white",
                                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                  borderRadius: 20,
                                }}
                              />
                              <div
                                className="Week"
                                style={{
                                  width: 115,
                                  height: 7,
                                  left: 10,
                                  top: 47,
                                  position: "absolute",
                                  color: "#1D1D1D",
                                  fontSize: 13,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Week days
                              </div>
                              <div
                                className="Saturday"
                                style={{
                                  width: 115,
                                  height: 7,
                                  left: 10,
                                  top: 85,
                                  position: "absolute",
                                  color: "#1D1D1D",
                                  fontSize: 13,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Saturday
                              </div>
                              <div
                                className="Sunday"
                                style={{
                                  width: 115,
                                  height: 7,
                                  left: 10,
                                  top: 124,
                                  position: "absolute",
                                  color: "#1D1D1D",
                                  fontSize: 13,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Sunday
                              </div>
                              <div
                                className="Holyday"
                                style={{
                                  width: 115,
                                  height: 7,
                                  left: 10,
                                  top: 164,
                                  position: "absolute",
                                  color: "#1D1D1D",
                                  fontSize: 13,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Public Holiday
                              </div>
                              <div
                                className="Day"
                                style={{
                                  width: 115,
                                  height: 7,
                                  left: 135,
                                  top: 8,
                                  position: "absolute",
                                  color: "#1D1D1D",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Day ($)
                              </div>
                              <div className="Rectangle59">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 119,
                                    top: 48,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  name="daynormal"
                                  value={data.daynormal}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="Rectangle60">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 119,
                                    top: 88,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  value={data.daysaturday}
                                  name="daysaturday"
                                  onChange={handleInputChange}

                                  //  name='daysaturday'
                                />
                              </div>
                              <div className="Rectangle61">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 119,
                                    top: 128,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  value={data.daysunday}
                                  name="daysunday"
                                  onChange={handleInputChange}

                                  // name='daysunday'
                                />
                              </div>
                              <div className="Rectangle62">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 119,
                                    top: 166,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  value={data.dayholidays}
                                  name="dayholidays"
                                  onChange={handleInputChange}

                                  // name='dayholidays'
                                />
                              </div>
                              <div
                                className="Night"
                                style={{
                                  width: 115,
                                  height: 7,
                                  left: 258,
                                  top: 8,
                                  position: "absolute",
                                  color: "#1D1D1D",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Night ($)
                              </div>
                              <div className="Rectangle63">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 245,
                                    top: 47,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  value={data.nightnormal}
                                  name="nightnormal"
                                  onChange={handleInputChange}

                                  // name='daynormal'
                                />
                              </div>
                              <div className="Rectangle64">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 245,
                                    top: 87,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  value={data.nightsaturday}
                                  name="nightsaturday"
                                  onChange={handleInputChange}

                                  // name='daysaturday'
                                />
                              </div>
                              <div className="Rectangle65">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 245,
                                    top: 127,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  value={data.nightsunday}
                                  name="nightsunday"
                                  onChange={handleInputChange}

                                  // name='daysunday'
                                />
                              </div>
                              <div className="Rectangle66">
                                <input
                                  style={{
                                    width: 77,
                                    height: 22,
                                    left: 245,
                                    top: 165,
                                    position: "absolute",
                                    background: "white",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: 5,
                                    border: "1px #EFF0F6 solid",
                                  }}
                                  type="number"
                                  min="0"
                                  value={data.nightholidays}
                                  name="nightholidays"
                                  onChange={handleInputChange}

                                  // name='dayholidays'
                                />
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="clientCol-2 ">
                          <label>
                            Address <br />
                            <input
                              type="text"
                              className="clientTextBox"
                              value={data.address}
                              name="address"
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.address}</div>
                          </label>
                          <br />
                          <label>
                            Driving Licence <br />
                            {/* <input type="text" className='managerTextBox' value={data.driving_license}
                                                            name='driving_license'
                                                            onChange={handleInputChange} /> */}
                            <select
                              className="clientDropDown"
                              name="driving_license"
                              value={data.driving_license}
                              onChange={handleInputChange}
                            >
                              {/* <option value="">Select Type</option> */}
                              <option value="">Select</option>
                              <option value="valid">valid</option>
                              <option value="invalid">invalid</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Password <br />
                            <input
                              type="password"
                              className="clientTextBox"
                              value={data.password}
                              name="password"
                              onChange={handleInputChange}
                            />
                            <div className="error">{errors.shortPassword}</div>
                          </label>
                          <br />
                          <label>
                            Employee Category : <br />
                            <select
                              className="clientDropDown"
                              value={data.category}
                              name="category"
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              <option value="Nurse">Nurse </option>
                              <option value="Clinical Nurse">
                                Clinical Nurse
                              </option>
                              <option value="Social Worker">
                                Social Worker
                              </option>
                              <option value="Occupational Therapy (OT)">
                                Occupational Therapy (OT)
                              </option>
                              <option value="Psychologist">Psychologist</option>
                              <option value="Support worker">
                                Support worker
                              </option>
                            </select>
                          </label>
                          <br />
                          <div className="error-message">
                            {errors.emptyFields}
                          </div>
                          <button
                            type="submit"
                            className="managerRegister-btn"
                            onClick={handleRegisterClick}
                          >
                            Register
                          </button>
                        </div>
                      </section>
                      <br />
                    </form>
                  </div>
                </div>
              )}

              {isRegistered && (
                <div className="main">
                  <div id="scrollstyle" className="done-popUpFrame1">
                    <div className="done-popupFrame2">
                      <div className="done-popupFrame3">
                        <img
                          src={doneIcon}
                          alt="Done"
                          className="doneIconStyle"
                        />
                        <h1 className="done">Done!</h1>
                        <h5>Employee Added Successfully</h5>
                        <button onClick={DoneOk} className="AllowBtn">
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {role && role === "Owner" && (
          <div
            style={{
              backgroundColor: "#f9f9f9",
              padding: "20px",
            }}
            className="Ownertable"
          >
            <div className="" style={{ height: "56vh", width: "80vw" }}>
              {loading ? (
                <SpinerLoading />
              ) : (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  onRowClick={handleRowClick}
                  initialState={{}}
                  checkboxSelection
                />
              )}
            </div>
          </div>
        )}
        {role && role === "Manager" && (
          <div className="managerItemContainer">
            <div className="table-container">
              <div className="" style={{ height: "56vh", width: "80vw" }}>
                {loading ? (
                  <SpinerLoading />
                ) : (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    onRowClick={handleRowClick}
                    initialState={{}}
                    checkboxSelection
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {selectedRow !== null && (
          <div className="popupManagerDetail">
            <div className="popup-header">
              <h2>Employee Details</h2>
              <h2
                onClick={() => setSelectedRow(null)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                X
              </h2>
            </div>
            <section>
              <div className="info-col-1">
                <div className="employeecontainer-1">
                  <div className="profile">
                    <div className="profile-img">
                      <img
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: 50,
                        }}
                        src={employee[0]?.imageUrl}
                      />
                    </div>
                    <div className="profile-name">
                      <h3> {employee[0]?.name}</h3>
                    </div>
                  </div>
                  <div className="shift-info">
                    <ul>
                      <li>
                        <img className="addIcon" src={earningIcon} />
                        <p>Earning</p>
                      </li>
                      <h4>${employee[0]?.totalPayment}</h4>
                    </ul>
                    <ul>
                      <li>
                        <img className="addIcon" src={hourIcon} />
                        <p>Current Hours</p>
                      </li>
                      <h4>{employee[0]?.currentworkinghours}</h4>
                    </ul>
                    <ul>
                      <li>
                        <img className="addIcon" src={shiftIcon} />
                        <p>Total Hours</p>
                      </li>
                      <h4> {employee[0]?.allowedworkinghours}</h4>
                    </ul>
                  </div>
                </div>
                <div className="employeecontainer-2">
                  <div>
                    <h1> Details</h1>
                    <ul>
                      <li>
                        <h5> Company Id:</h5>
                        <span className="detail-span">
                          {employee[0]?.companyId}
                        </span>
                      </li>
                      <li>
                        <h5> Manager:</h5>
                        <span className="detail-span">
                          {employee[0]?.companyId}
                        </span>
                      </li>
                      <li>
                        <h5>Email: </h5>
                        <span className="detail-span">
                          {employee[0]?.email}
                        </span>
                      </li>
                      <li>
                        <h5>Contact: </h5>
                        <span className="detail-span">
                          {employee[0]?.phone}
                        </span>
                      </li>
                      <li>
                        <h5>Date of Birth: </h5>
                        <span className="detail-span">
                          {employee[0]?.DOB
                            ? formatDate(employee[0].DOB)
                            : "N/A"}
                        </span>
                      </li>
                      <li>
                        <h5>Address: </h5>
                        <span className="detail-span">
                          {employee[0]?.address}
                        </span>
                      </li>
                      <li>
                        <h5>Employee Type: </h5>
                        <span className="detail-span">
                          {employee[0]?.employeeType}
                        </span>
                      </li>
                      <li>
                        <h5>Category: </h5>
                        <span className="detail-span">
                          {employee[0]?.category}
                        </span>
                      </li>
                      <li>
                        <h5>Driving License: </h5>
                        <br />
                        <span className="detail-span">
                          {employee[0]?.driving_license}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="employeeinfo-col-2">
                <div className="payment-Rate">
                  <h2>Payment Rate</h2>
                  <div class="group">
                    <div class="rectangle" id="rectangle2646"></div>
                    <div class="rectangle" id="rectangle2645"></div>
                    <div class="text" id="week">
                      Week
                    </div>
                    <div class="text" id="saturday">
                      Saturday
                    </div>
                    <div class="text" id="sunday">
                      Sunday
                    </div>
                    <div class="text" id="holiday">
                      Holiday
                    </div>
                    <div class="text" id="day">
                      Day
                    </div>
                    <div class="input-box" id="rectangle59">
                      <span className="payment-span">
                        {employee && employee[0]?.dayShiftPaymentRates.normal}
                      </span>
                    </div>
                    <div class="input-box" id="rectangle60">
                      <span className="payment-span">
                        {employee && employee[0]?.dayShiftPaymentRates.saturday}
                      </span>
                    </div>
                    <div class="input-box" id="rectangle61">
                      <span className="payment-span">
                        {employee && employee[0]?.dayShiftPaymentRates.sunday}
                      </span>
                    </div>
                    <div class="input-box" id="rectangle62">
                      <span className="payment-span">
                        {employee && employee[0]?.dayShiftPaymentRates.holidays}
                      </span>
                    </div>
                    <div class="text" id="night">
                      Night
                    </div>
                    <div class="input-box" id="rectangle63">
                      <span className="payment-span">
                        {employee && employee[0]?.nightShiftPaymentRates.normal}
                      </span>
                    </div>
                    <div class="input-box" id="rectangle64">
                      <span className="payment-span">
                        {employee &&
                          employee[0]?.nightShiftPaymentRates.saturday}
                      </span>
                    </div>
                    <div class="input-box" id="rectangle65">
                      <span className="payment-span">
                        {employee && employee[0]?.nightShiftPaymentRates.sunday}
                      </span>
                    </div>
                    <div class="input-box" id="rectangle66">
                      <span className="payment-span">
                        {employee &&
                          employee[0]?.nightShiftPaymentRates.holidays}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="employeedocument">
                  <h1>Documents</h1>
                  <div>
                    {employee &&
                      employee[0]?.doc.map((doc) => (
                        <div className="DriverLenience" key={doc._id}>
                          <h5>{doc.title}</h5>
                          <p>{doc.details}</p>
                          <a
                            href={doc.fileurl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{ color: "green" }}
                            />
                          </a>
                          <br />
                          <button
                            onClick={() => {
                              // Open the document in a popup (if needed)
                              // ...

                              // Simulate the document approval (toggle between black icon and green checkmark)
                              const isApproved = doc.approved; // Assuming there's a property 'approved' in your document
                              const approvalIcon = isApproved
                                ? "faCheck"
                                : "faFile";

                              // Display the icon based on the approval status
                              const iconColor = isApproved ? "green" : "black";
                              const iconStyle = { color: iconColor };

                              // Output the icon
                              const iconElement = (
                                <FontAwesomeIcon
                                  icon={approvalIcon}
                                  style={iconStyle}
                                />
                              );
                              console.log("Icon:", iconElement);
                            }}
                          >
                            Approve Document
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    postEmployee: (employeeData) => dispatch(postEmployee(employeeData)),
  };
};
export default connect(null, mapDispatchToProps)(EmployeeRightBar);
