import React, { useEffect, useState } from "react";
import "./ClientRightBar.css";
import NavBar from "../../NavBar";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { GetClientsAction } from "../../../../redux/actions/clientActions";
import deleteIcon from "../../../../assets/Icons/deleteIcon.png";
import addnewIcon from "../../../../assets/Icons/addnewIcon.png";
import doneIcon from "../../../../assets/Icons/doneIcon.png";
import { deleteClientAction } from "../../../../redux/actions/deleteClientAction";
import { postClient } from "../../../../redux/actions/createActions/createClientAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { GetManClientAction } from "../../../../redux/actions/GetmManClientAction";
import SpinerLoading from "../../../Loading";

function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}
function ClientRightBar(props) {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [addClientPopup, setAddClientPopup] = useState(false);
  // const [dataErrors, setDataErrors] = useState({});
  const [donePopup, setDonePopup] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState();
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    documnet: "",
    DOB: "",
    imageUrl: "",
    driving_license: "",
    Dermatological: "",
    ChronicRespiratory: "",
    EyeConditions: "",
    Cancer: "",
    Neurological: "",
    GastrointestinalDisease: "",
    BloodPressure: "",
    Thyroid: false,
    Diabetes: false,
    Dementia: false,
    password: "", // Add password to the initial state
  });
  const [dataErrors, setDataErrors] = useState({
    emptyFields: "",
    invalidEmail: "",
    invalidPhone: "",
    emptyDOB: "",
    emptyImage: "",
    shortPassword: "",
    emptyName: "",
  });
  const handleAddClientClick = () => {
    setAddClientPopup(true);
  };
  const closeAddClientPopup = () => {
    setAddClientPopup(false);
  };
  const dispatch = useDispatch();
  const { loading, clients, errors, status, msg } = useSelector(
    (state) => state.client
  );
  console.log("🚀 ~ ClientRightBar ~ clients:", clients);

  const [selectedRow, setSelectedRow] = useState(null);
  const [client, setClient] = useState(null);

  const handleRowClick = (params) => {
    setSelectedRow(params.row.id);
    setClient(clients?.filter((client) => client._id === params.row.id));
  };
  useEffect(() => {
    dispatch(GetManClientAction());
    dispatch(GetClientsAction());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this Client?")) {
      dispatch(deleteClientAction(id)).then(() => {
        navigate("/home"); // Navigate after successful deletion
      });
      console.log(`Delete row with ID: ${id}`);
    }
  };
  const handleInputChange = (event) => {
    const newData = { ...data };

    if (event.target.type === "checkbox") {
      newData[event.target.name] = event.target.checked;
    } else if (event.target.name === "DOB") {
      const selectedDate = event.target.value;
      const formattedDate = selectedDate + "T06:22:13.652Z";
      newData["DOB"] = formattedDate;
    } else {
      newData[event.target.name] = event.target.value;
    }
    if (event.target.name === "file") {
      setImage(event.target.files[0]);
    }
    setData(newData);
  };

  useEffect(() => {
    if (status && status === 201) {
      toast.success(msg, { theme: "colored" });
      console.log(msg);
      // navigate('/ClientRightBar');
    } else if (status === 400) {
      toast.error(msg, { theme: "colored" });
      console.log("Bad Request: Fill all fields", msg);
    } else if (status === 422) {
      toast.error(msg, { theme: "colored" });
      console.log("Not Found: Image not selected", msg);
    } else {
      toast.error(msg, { theme: "colored" });
      console.log("Unexpected status: " + status);
    }
  }, [dispatch, status, msg]);

  const handleRegisterClick = async (event) => {
    event.preventDefault();
    const validationErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email)) {
      validationErrors.invalidEmail = "Invalid email address";
    }

    const phoneRegex = /^\d{10,13}$/;
    if (!phoneRegex.test(data.phone)) {
      validationErrors.invalidPhone =
        "Phone number must be between 10 to 13 digits";
    }

    if (!data.DOB) {
      validationErrors.emptyDOB = "Date of Birth is required";
    }
    if (!data.name) {
      validationErrors.emptyName = "Please enter name";
    }

    if (!image) {
      validationErrors.emptyImage = "Image is required";
    }

    // Check password if it exists in the form
    if (data.password && data.password.length < 8) {
      validationErrors.shortPassword =
        "Password must be at least 8 characters long";
    }

    if (Object.keys(validationErrors).length > 0) {
      setDataErrors(validationErrors);
      return;
    }

    setDataErrors({});
    // setDonePopup(true);
    // closeAddClientPopup();

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("password", data.password);
    formData.append("address", data.address);
    formData.append("DOB", data.DOB);
    formData.append("driving_license", data.driving_license);
    formData.append("Dermatological", data.Dermatological);
    formData.append("ChronicRespiratory", data.ChronicRespiratory);
    formData.append("EyeConditions", data.EyeConditions);
    formData.append("Cancer", data.Cancer);
    formData.append("Neurological", data.Neurological);
    formData.append("GastrointestinalDisease", data.GastrointestinalDisease);
    formData.append("BloodPressure", data.BloodPressure);
    formData.append("Thyroid", data.Thyroid);
    formData.append("Dementia", data.Dementia);
    formData.append("Diabetes", data.Diabetes);
    formData.append("imageUrl", image);
    await dispatch(postClient(formData, navigate));
    console.log(data);
  };

  const rows = (clients || [])
    .filter((client) => {
      return client.isDelete === false;
    })
    .map((client) => ({
      id: client._id,
      image: client.imageUrl,
      name: client.name,
      email: client.email,
      phone: client.phone,
      address: client.address,
    }));

  const columns = [
    { field: "", headerName: "", width: 20 },
    {
      field: "image",
      headerName: "Image",
      width: 120,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
    },
    { field: "name", headerName: "Name", width: 250 },
    { field: "email", headerName: "Email", width: 270 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "address", headerName: "Address", width: 350 },
    {
      width: 150,
      renderCell: (params) => (
        <div>
          <img
            src={deleteIcon}
            alt="Delete"
            className="actionIcon"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="mainClientRightBar">
      <NavBar />
      <div className="clientitemContainer">
        <div className="dashboardBar">
          <div className="dashboardContainer">
            <h1 className="dashtext">Client Details</h1>
          </div>
          <div>
            {role && role === "Manager" && (
              <button className="createButton" onClick={handleAddClientClick}>
                <img src={addnewIcon} alt="Add New" className="addIcon" />
                Add New Client
              </button>
            )}
            <div>
              {addClientPopup && (
                <div className="clientmain">
                  <div className="popupClientForm">
                    <div className="popup-header">
                      <h2>Add New Client</h2>
                      <h2
                        onClick={closeAddClientPopup}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "red",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        X
                      </h2>
                    </div>
                    <form id="clientform" className="mainForm">
                      <section className="clientFormContainer">
                        <div className="clientCol-1">
                          <label>
                            Name
                            <br />
                            <input
                              type="text"
                              className="clientTextBox"
                              name="name"
                              value={data.name}
                              onChange={handleInputChange}
                              required
                            />
                            <div className="error-message">
                              {dataErrors.emptyName}
                            </div>
                          </label>
                          <br />
                          <label>
                            Email <br />
                            <input
                              type="text"
                              className="clientTextBox"
                              name="email"
                              value={data.email}
                              onChange={handleInputChange}
                            />
                            <div className="error-message">
                              {dataErrors.invalidEmail}
                            </div>
                          </label>
                          <br />
                          <label>
                            Date of Birth <br />
                            {/* <input type="date" className='clientTextBox'
                                                            name='DOB' value={data.DOB} onChange={handleInputChange} />
                                                    */}
                            <input
                              type="date"
                              className="clientTextBox"
                              name="DOB"
                              value={data.DOB ? data.DOB.slice(0, 10) : ""} // Display date if available
                              onChange={handleInputChange}
                            />
                          </label>
                          <br />
                          <br />
                          <label>
                            Neurological: <br />
                            <select
                              className="clientDropDown"
                              name="Neurological"
                              value={data.Neurological}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Type</option>
                              <option value="Epilepsy">Epilepsy</option>
                              <option value="Parkinson’s">Parkinson’s</option>
                              <option value="Multiple Sclerosis">
                                Multiple Sclerosis
                              </option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Driving Licence: <br />
                            <select
                              className="clientDropDown"
                              name="driving_license"
                              value={data.driving_license}
                              onChange={handleInputChange}
                            >
                              <option value="">Select type</option>
                              <option value="valid">valid</option>
                              <option value="invalid">invalid</option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Chronic Respiratory: <br />
                            <select
                              className="clientDropDown"
                              name="ChronicRespiratory"
                              value={data.ChronicRespiratory}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Type</option>
                              <option value="Asthmatic">Asthmatic</option>
                              <option value="COPD">COPD</option>
                              <option value="GORD">GORD</option>
                              <option value="Obstructive Sleep Apnoea">
                                Obstructive Sleep Apnoea
                              </option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Dermatological(Degenerative): <br />
                            <select
                              className="clientDropDown"
                              name="Dermatological"
                              value={data.Dermatological}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Type</option>
                              <option value="Eczema">Eczema</option>
                              <option value="Psoriasis">Psoriasis</option>
                              <option value="Acne">Acne</option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Eye Coditions(Degenerative): <br />
                            <select
                              className="clientDropDown"
                              name="EyeConditions"
                              value={data.EyeConditions}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Type</option>
                              <option value="Cataract">Cataract</option>
                              <option value="glaucoma">glaucoma</option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Blood Pressure: <br />
                            <select
                              className="clientDropDown"
                              name="BloodPressure"
                              value={data.BloodPressure}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Type</option>
                              <option value="Low">Low</option>
                              <option value="High">High</option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                        </div>
                        <div className="clientCol-2">
                          <label>
                            Contact <br />
                            <input
                              type="number"
                              className="clientTextBox"
                              name="phone"
                              value={data.phone}
                              onChange={handleInputChange}
                            />
                            <div className="error-message">
                              {dataErrors.invalidPhone}
                            </div>
                          </label>
                          <br />
                          <label>
                            Address <br />
                            <input
                              type="text"
                              className="clientTextBox"
                              name="address"
                              value={data.address}
                              onChange={handleInputChange}
                            />
                          </label>
                          <br />
                          <br />
                          <label>
                            Password <br />
                            <input
                              type="password"
                              className="clientTextBox"
                              name="password"
                              value={data.password}
                              onChange={handleInputChange}
                            />
                            <div className="error-message">
                              {dataErrors.shortPassword}
                            </div>
                          </label>
                          <br />
                          <label>
                            Cancer : <br />
                            <select
                              className="clientDropDown"
                              name="Cancer"
                              value={data.Cancer}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Type</option>
                              <option value="prostate">prostate</option>
                              <option value="lung">lung</option>
                              <option value="bowel">bowel</option>
                              <option value="head and neck">
                                head and neck
                              </option>
                              <option value="kidney">kidney</option>
                              <option value="breast">breast</option>
                              <option value="uterus">uterus</option>
                              <option value="skin">skin</option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Gastrointestinal disease : <br />
                            <select
                              className="clientDropDown"
                              name="GastrointestinalDisease"
                              value={data.GastrointestinalDisease}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Type</option>
                              <option value="IBD - inflammatory bowel disease">
                                IBD - inflammatory bowel disease
                              </option>
                              <option value="IBS - irritable bowel syndrome">
                                IBS - irritable bowel syndrome
                              </option>
                              <option value="pancreatitis">pancreatitis</option>
                              <option value="peptic ulcer">peptic ulcer</option>
                              <option value="GORD">GORD</option>
                              <option value="diverticula disease">
                                diverticula disease
                              </option>
                              <option value="haemorrhoids">haemorrhoids</option>
                              <option value="">None</option>
                            </select>
                          </label>
                          <br />
                          <br />
                          <label>
                            Image Upload:
                            <input
                              type="file"
                              name="file"
                              accept="imageUrl/*"
                              onChange={handleInputChange}
                            />
                            <div className="error-message">
                              {dataErrors.emptyImage}
                            </div>
                          </label>
                          <br />
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              name="Thyroid"
                              value={data.Thyroid}
                              onChange={handleInputChange}
                            />{" "}
                            Thyroid(Endocrine/Degenerative)
                          </label>
                          <br />
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              name="Diabetes"
                              value={data.Diabetes}
                              onChange={handleInputChange}
                            />{" "}
                            Diabetes
                          </label>
                          <br />
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              name="Dementia"
                              value={data.Dementia}
                              onChange={handleInputChange}
                            />{" "}
                            Dementia
                          </label>
                          <br />
                          <div className="error-message">
                            {dataErrors.emptyFields}
                          </div>
                          <button
                            className="managerRegister-btn"
                            onClick={handleRegisterClick}
                          >
                            Register
                          </button>
                        </div>
                      </section>
                      <br />
                    </form>
                  </div>
                </div>
              )}
              {donePopup && (
                <div className="main">
                  <div className="done-popUpFrame1">
                    <div className="done-popupFrame2">
                      <div className="done-popupFrame3">
                        <img
                          src={doneIcon}
                          alt="Done"
                          className="doneIconStyle"
                        />
                        <h1 className="done">Done!</h1>
                        <h5>Manager Added Successfully</h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="managerItemContainer">
        <div style={{ height: "56vh", width: "100%" }}>
          {loading ? (
            <SpinerLoading />
          ) : (
            <DataGrid
              style={{ backgroundColor: "", width: "80vw" }}
              rows={rows}
              columns={columns}
              onRowClick={handleRowClick}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 18 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
          )}
        </div>
      </div>
      {selectedRow !== null && (
        <div className="popupManagerDetail">
          <div className="popup-header">
            <h2>Client Details</h2>
            <h2
              onClick={() => setSelectedRow(null)}
              style={{
                cursor: "pointer",
                backgroundColor: "red",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              X
            </h2>
          </div>
          <section>
            <div className="info-col-1">
              <div className="clientcontainer-1">
                <div className="profile">
                  <div className="profile-img">
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: 50,
                      }}
                      src={client[0]?.imageUrl}
                    />
                  </div>
                  <div className="profile-name">
                    <h3 className="h3heading"> {client[0]?.name}</h3>
                  </div>
                </div>
                {/* <div className="shift-info" >
                                        <ul>
                                            <li >
                                                <img className="addIcon" src={earningIcon} />
                                                <p>Earning</p>
                                            </li>
                                            <h4>${client[0]?.totalPayment}</h4>
                                        </ul>
                                        <ul>
                                            <li >
                                                <img className="addIcon" src={hourIcon} />
                                                <p>Current Hours</p>
                                            </li>
                                            <h4>{client[0]?.currentworkinghours}</h4>
                                        </ul>
                                        <ul>
                                            <li >
                                                <img className="addIcon" src={shiftIcon} />
                                                <p>Total Hours</p>

                                            </li>
                                            <h4> {client[0]?.allowedworkinghours}</h4>
                                        </ul>
                                    </div> */}
              </div>
              <div className="employeecontainer-2">
                <div>
                  <h1> Details</h1>
                  <br />
                  <ul>
                    <li>
                      <h5>Date of Birth: </h5>
                      <span className="detail-span">
                        {client[0]?.DOB ? formatDate(client[0].DOB) : "N/A"}
                      </span>
                    </li>
                    <li>
                      <h5>Email: </h5>
                      <span className="detail-span">{client[0]?.email}</span>
                    </li>
                    <li>
                      <h5>Contact: </h5>
                      <span className="detail-span">{client[0]?.phone}</span>
                    </li>
                    <li>
                      <h5>Address: </h5>
                      <span className="detail-span">{client[0]?.address}</span>
                    </li>
                    <li>
                      <h5>Driving License: </h5>
                      <br />
                      <span className="detail-span">
                        {client[0]?.driving_license}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="clientinfo-col-2">
              <h1 style={{ fontWeight: "900", fontSize: "30px" }}>Medical</h1>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  <h4>Dermatological(Degenerative)</h4>
                  <p>{client[0]?.Dermatological}</p>
                </li>
                <li>
                  <h4>Chronic Respiratory</h4>
                  <p>{client[0]?.ChronicRespiratory}</p>
                </li>
                <li>
                  <h4>Eye Coditions(Degenerative)</h4>
                  <p>{client[0]?.EyeConditions}</p>
                </li>
                <li>
                  <h4>Cancer</h4>
                  <p>{client[0]?.Cancer}</p>
                </li>
                <li>
                  <h4>Neurological</h4>
                  <p>{client[0]?.Neurological}</p>
                </li>
                <li>
                  <h4>Gastrointestinal Disease</h4>
                  <p>{client[0]?.GastrointestinalDisease}</p>
                </li>
                <li>
                  <h4>Blood Pressure</h4>
                  <p>{client[0]?.BloodPressure}</p>
                </li>
                <br />
                <li>
                  <h4> Thyroid(Endocrine/Degenerative)</h4>
                  <p>{client[0]?.Thyroid}</p>
                </li>
                <li>
                  <h4>Diabetes</h4>
                  <p>{client[0]?.Diabetes}</p>
                </li>
                <li>
                  <h4>Dementia</h4>
                  <p>{client[0]?.Dementia}</p>
                </li>
              </ul>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default ClientRightBar;
