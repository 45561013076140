export const REQUESTED_AVAILABILITY_REQUEST = "REQUESTED_AVAILABILITY_REQUEST"
export const REQUESTED_AVAILABILITY_SUCCESS = "REQUESTED_AVAILABILITY_SUCCESS"
export const REQUESTED_AVAILABILITY_FAIL = "REQUESTED_AVAILABILITY_FAIL"

export const APPROVE_AVAILABILITY_REQUEST_SUCCESS = 'APPROVE_AVAILABILITY_REQUEST_SUCCESS';
export const REJECT_AVAILABILITY_REQUEST_SUCCESS = 'REJECT_AVAILABILITY_REQUEST_SUCCESS';
export const GET_REQUESTED_AVAILABILITIES_SUCCESS = 'GET_REQUESTED_AVAILABILITIES_SUCCESS';

export const DELETE_LEAVE_REQUEST = "DELETE_LEAVE_REQUEST"
export const DELETE_LEAVE_SUCCESS = "DELETE_LEAVE_SUCCESS"
export const DELETE_LEAVE_FAIL = "DELETE_LEAVE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS";