export const JOB_REQUEST = "JOB_REQUEST";
export const JOB_SUCCESS = "JOB_SUCCESS";
export const JOB_FAIL = "JOB_FAIL";

export const CREATE_JOB_REQUEST = "CREATE_JOB_REQUEST";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAILURE = "CREATE_JOB_FAILURE";

export const APPROVE_JOB_REQUEST_SUCCESS = "APPROVE_JOB_REQUEST_SUCCESS";
export const REJECT_JOB_REQUEST_SUCCESS = "REJECT_JOB_REQUEST_SUCCESS";
export const GET_REQUESTED_JOB_SUCCESS = "GET_REQUESTED_JOB_SUCCESS";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_CANCEL = "GET_JOBS_CANCEL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
