export const EMPLOYEE_REQUEST = "EMPLOYEE_REQUEST";
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";
export const EMPLOYEE_FAIL = "EMPLOYEE_FAIL";

// Action types for creating an employee
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';

export const POST_EMPLOYEE_REQUEST = 'POST_EMPLOYEE_REQUEST';
export const POST_EMPLOYEE_SUCCESS = 'POST_EMPLOYEE_SUCCESS';
export const POST_EMPLOYEE_FAILURE = 'POST_EMPLOYEE_FAILURE';
export const POST_EMPLOYEE_RESET = 'POST_EMPLOYEE_RESET';

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST"
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS"
export const DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS";