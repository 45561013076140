import axios from "axios";
import { BaseURL } from "../constants/BaseURL";

import { toast } from "react-toastify";
import {
  CLEAR_CLIENT_ERRORS,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
} from "../constants/clientConstants";

// export const deleteClientAction = (clientId, navigate) => async (dispatch) => {
//   console.log("🚀 ~ deleteClientAction ~ clientId:", clientId);
//   try {
//     dispatch({ type: DELETE_CLIENT_REQUEST });
//     const token = localStorage.getItem("token");
//     const response = await axios.put(
//       `${BaseURL}/client/update/${clientId}/`,
//       {
//         isDelete: true,
//       },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-auth-token": token,
//         },
//       }
//     );
//     if (response.status === 200) {
//       dispatch({
//         type: DELETE_CLIENT_SUCCESS,
//         payload: response.data,
//       });
//       toast.success("Client deleted successfully!", { theme: "colored" });
//       setTimeout(() => {
//         navigate("/client"); // Use navigate to redirect after deletion
//       }, 2000);
//     }
//   } catch (error) {
//     dispatch({
//       type: DELETE_CLIENT_FAIL,
//       payload: error.response.data,
//     });
//     toast.error("An error occurred while deleting the Client.", {
//       theme: "colored",
//     });
//   }
// };

export const deleteClientAction = (clientId) => async (dispatch) => {
  console.log("🚀 ~ deleteClientAction ~ clientId:", clientId);
  try {
    dispatch({ type: DELETE_CLIENT_REQUEST });
    const token = localStorage.getItem("token");
    const response = await axios.put(
      `${BaseURL}/client/update/${clientId}/`,
      {
        isDelete: true,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    if (response.status === 200) {
      dispatch({
        type: DELETE_CLIENT_SUCCESS,
        payload: clientId, // Send clientId as payload for immediate deletion
      });
      toast.success("Client deleted successfully!", { theme: "colored" });
      return Promise.resolve(); // Resolve promise for successful deletion
    }
  } catch (error) {
    dispatch({
      type: DELETE_CLIENT_FAIL,
      payload: error.response.data,
    });
    toast.error("An error occurred while deleting the Client.", {
      theme: "colored",
    });
    return Promise.reject(error); // Reject promise on error
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CLIENT_ERRORS });
};
